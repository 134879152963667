import { useMutation } from '@tanstack/react-query';
import { axiosProspectInstance } from 'settings/AxiosSetting';

export const useGetInsights = () =>
  useMutation((data: { prospect_id?: string; api_for: string }) => {
    return axiosProspectInstance.post('prospect-insight', data);
  });

export const useGetCompanyInsights = () =>
  useMutation((data: { prospect_id?: string; api_for: string }) => {
    return axiosProspectInstance.post('organization-insight', data);
  });
export const useGetKeyChallengesInfo = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post('generate-keychallenges', data);
  });
export const useGetGenericDisc = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post('generate-disc', data);
  });

export const useGetNewsData = () =>
  useMutation((data: { prospect_id?: string; refetch?: boolean }) => {
    return axiosProspectInstance.post('get-news', data);
  });
export const useGetYoutubeData = () =>
  useMutation((data: { prospect_id?: string; refetch?: boolean }) => {
    return axiosProspectInstance.post('get-youtube-data', data);
  });
