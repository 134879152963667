import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

const Grid = ({
  cols,
  rows,
  gap,
  width = 'w-full',
  margin = 'm-0',
  padding = 'p-0',
  className,
  children,
}: {
  cols: string;
  rows?: string;
  gap?: string;
  width?: string;
  margin?: string;
  padding?: string;
  className?: string;
  children: ReactNode;
}) => {
  const baseClass = 'grid';

  const customClass = classNames(
    baseClass,
    cols,
    rows,
    gap,
    `${width}`,
    `${margin}`,
    padding,
    className,
  );

  return <div className={customClass}>{children}</div>;
};

export default React.memo(Grid);
