import { ForgotPasswordProvider } from './forgotPassword.context';
import ForgotPasswordView from './forgotPassword.view';

const ForgotPasswordApp = () => {
  return (
    <ForgotPasswordProvider>
      <ForgotPasswordView />
    </ForgotPasswordProvider>
  );
};

export default ForgotPasswordApp;
