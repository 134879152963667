import AppSetting from 'settings';
import Routes from 'routes';
import { useEffect, useState } from 'react';
import NavContext from 'context/NavContext';
import { AuthType } from 'types/custom';
import 'typeface-inter';

const pattern =
  // /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  // /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+$/
  // eslint-disable-next-line unicorn/better-regex, no-useless-escape
  /linkedin.com\/in\/(.*?)(\?|\/|$)/;
// /^https?:\/\/(?:www\.)?linkedin\.com\/in\/[^\/]+\/?$/;
// /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\p{L}\p{N}_-]+$/;
const salesNavigatorPattern = /\/lead\/([\w-]+)/;

const App = () => {
  const [userInfo, setUserInfo] = useState<AuthType>({} as AuthType);
  const [isProfile, setIsProfile] = useState(false);
  const [currentURL, setCurrentURL] = useState('');
  const [isExtensionOpen, setIsExtensionOpen] = useState(false);

  const [isGmailPage, setIsGmailPage] = useState(false);
  const [prospectEmail, setProspectEmail] = useState('');
  const [emailConversation, setEmailConversation] = useState('');
  const [messageList, setMessageList] = useState([]);

  const isLinkedInProfileUrl = (url) => {
    // Regular expression pattern to match LinkedIn profile URLs

    // Check if the provided URL matches the LinkedIn profile URL pattern
    return pattern.test(url);
  };
  const isSalesNavigatorURL = (url) => {
    return salesNavigatorPattern.test(url);
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event && event.data) {
        if (event.data.url) {
          const receivedLinkedinUrl = event.data.url;
          const isSalesURL = isSalesNavigatorURL(receivedLinkedinUrl);
          const isProfileURL =
            isLinkedInProfileUrl(receivedLinkedinUrl) || isSalesURL;
          let receivedUrl = pattern.exec(receivedLinkedinUrl);
          if (isSalesURL) {
            receivedUrl = salesNavigatorPattern.exec(receivedLinkedinUrl);
          }
          if (receivedUrl)
            if (isSalesURL) {
              setCurrentURL(`linkedin.com/in/${receivedUrl[1]}/`);
            } else if (isProfileURL) {
              setCurrentURL(receivedUrl[0]);
            } else {
              setCurrentURL(receivedUrl[0]);
            }

          setIsProfile(isProfileURL);
        }
        if (event.data.extensionOpen === 'true') {
          setIsExtensionOpen(true);
        } else if (event.data.extensionOpen === 'false') {
          setIsExtensionOpen(false);
        }

        // Message from gmail
        if (event.data.type === 'email') {
          // console.log("Prospect Email",event.data.prospectEmail);
          // console.log("Email Conversation",event.data.emailConversation);
          setIsGmailPage(true);
          setProspectEmail(event.data.prospectEmail || prospectEmail);
          setEmailConversation(
            event.data.emailConversation || emailConversation,
          );
          setMessageList(event.data.messageList || messageList);
        }
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, [prospectEmail, emailConversation, messageList]);

  return (
    <AppSetting>
      <NavContext.Provider
        value={{
          userInfo,
          setUserInfo,
          currentURL,
          isProfile,
          isExtensionOpen,
          setIsExtensionOpen,
          setCurrentURL,
          setIsProfile,

          isGmailPage,
          emailConversation,
          prospectEmail,
          messageList,
        }}
      >
        <Routes />
      </NavContext.Provider>
    </AppSetting>
  );
};

export default App;
