/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavContext from 'context/NavContext';
import { Skeleton } from 'antd';
import {
  useGetCreditPoints,
  useGetUserVerifyInfo,
  useRefetchProspectData,
} from 'pages/contact/contact.query';
import CreditPointsContext from 'context/CreditContext';
import { StopOutlined } from '@ant-design/icons';
import { ContactLabels } from 'consts/label';
import { UpgradeLabels } from 'consts/label/en-US/pages/contact';
import { Tour, TourProps } from 'components/Ant';
import Flex from 'components/Flex';
import UpgradeView from 'components/Upgrade';
import Text from 'components/Text';
import { Icon } from 'components/Icon';

const AuthNav = ({ children }: { children?: ReactNode }) => {
  const customerNav = useContext(NavContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowRefresh, setIsShowRefresh] = useState<boolean>(false);
  const [prospectRefreshData, setProspectRefreshData] = useState({});
  const searchParam = new URLSearchParams(window.location.search);
  const isWebApp = searchParam.get('isWebApp');
  const [isOpen, setIsOpen] = useState(false);
  const [currentTour, setCurrentTour] = useState<number>();
  const [isGuide, setIsGuide] = useState<boolean>(false);

  useEffect(() => {
    const isTourEnabled = Boolean(localStorage.getItem('isTourEnabled'));
    if (!isTourEnabled && location?.pathname === '/contact') {
      setIsGuide(!isTourEnabled);
    }
  }, [location]);

  const steps: TourProps['steps'] = [
    {
      title: <Text> {UpgradeLabels.TOUR_STEP_1_TITLE}</Text>,
      description: (
        <Text
          variant="psm"
          color="text-white1"
          weight={400}
          className=" leading-5 "
        >
          {UpgradeLabels.TOUR_STEP_1_DESCRIPTION}
        </Text>
      ),
      nextButtonProps: {
        className: 'px-1.5 bg-[#1F2937] border-[#1F2937]',
      },
      target: document.getElementById('ContactTabAction'),
    },
    {
      title: <Text> {UpgradeLabels.TOUR_STEP_2_TITLE}</Text>,
      description: (
        <Text
          variant="psm"
          color="text-white1"
          weight={400}
          className=" leading-5 "
        >
          {UpgradeLabels.TOUR_STEP_2_DESCRIPTION}
        </Text>
      ),
      nextButtonProps: {
        className: 'px-1.5 bg-[#1F2937] border-[#1F2937]',
      },
      target: document.getElementById('ContactTabInsights'),
    },
    {
      title: <Text> {UpgradeLabels.TOUR_STEP_3_TITLE}</Text>,
      description: (
        <Text
          variant="psm"
          color="text-white1"
          weight={400}
          className=" leading-5 "
        >
          {UpgradeLabels.TOUR_STEP_3_DESCRIPTION}
        </Text>
      ),
      nextButtonProps: {
        className: 'px-1.5 bg-[#1F2937] border-[#1F2937]',
      },
      target: document.getElementById('AboutAIPitch'),
    },
    {
      title: <Text> {UpgradeLabels.TOUR_STEP_4_TITLE}</Text>,
      description: (
        <Text
          variant="psm"
          color="text-white1"
          weight={400}
          className=" leading-5 "
        >
          {UpgradeLabels.TOUR_STEP_4_DESCRIPTION}
        </Text>
      ),
      nextButtonProps: {
        className: 'px-1.5 bg-[#1F2937] border-[#1F2937]',
      },
      target: document.getElementById('UpgradeShowMoreIcon'),
    },
    {
      title: <Text> {UpgradeLabels.TOUR_STEP_5_TITLE}</Text>,
      description: (
        <Text
          variant="psm"
          color="text-white1"
          weight={400}
          className=" leading-5"
        >
          {UpgradeLabels.TOUR_STEP_5_DESCRIPTION}
        </Text>
      ),
      prevButtonProps: {
        className: 'hidden',
      },
      nextButtonProps: {
        className: 'px-1.5 bg-[#1F2937] border-[#1F2937]',
      },
      target: document.getElementById('UpgradeRefreshIcon'),
    },
  ];

  // eslint-disable-next-line
  const handleCloseExtension = () => {
    customerNav.setIsExtensionOpen(false);
    window.close();
    window.parent.postMessage({ type: 'closeExtension' }, '*');
  };
  useEffect(() => {
    if (customerNav?.userToken && localStorage.getItem('token')) {
      navigate('/contact');
    }
  }, [customerNav?.userToken]);
  const { mutate: onRefetchProspectData, isLoading: isLoadingRefetch } =
    useRefetchProspectData();
  const onRefreshProspect = () => {
    onRefetchProspectData(
      {
        linkedin_url: customerNav?.currentURL,
      },
      {
        onSuccess(data) {
          setProspectRefreshData(data?.data);
          setIsShowRefresh(false);
        },
        onError() {
          setIsShowRefresh(false);
        },
      },
    );
  };
  const onCloseTour = () => {
    setIsOpen(false);
    setIsGuide(false);
    localStorage.setItem('isTourEnabled', 'true');
  };
  const logout = () => {
    // localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    window.parent.postMessage({ type: 'logout' }, '*');
    if (localStorage.getItem('language')) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  };
  const {
    data: creditPointsData,
    refetch: onRefetchCreditPoints,
    isLoading: isLoadingCredits,
  } = useGetCreditPoints({
    onError: () => {
      logout();
    },
  });
  const {
    data: { data: { data: userInfo = {} } = {} } = {},
    isLoading: isAuthUserInfoLoading,
    refetch: refetchVerifyInfo,
  } = useGetUserVerifyInfo();
  const onboardingPercentage = useMemo(() => {
    const percentageData = {
      WEBSITE: 10,
      LINKEDIN_URL: 10,
      COMPANY_VALUE: 50,
      AI_LANG: 10,
      CUSTOMER: 10,
      COMPETITORS: 10,
    };
    let currentPercentage = 0;
    if (userInfo?.company_details?.company_website) {
      currentPercentage += percentageData.WEBSITE;
    }
    if (userInfo?.language) {
      currentPercentage += percentageData.AI_LANG;
    }
    if (userInfo?.customer_details?.top_customers) {
      currentPercentage += percentageData.CUSTOMER;
    }
    if (userInfo?.customer_details?.top_competitors) {
      currentPercentage += percentageData.COMPETITORS;
    }
    if (userInfo?.company_details?.about_company) {
      currentPercentage += percentageData.COMPANY_VALUE;
    }
    if (userInfo?.company_details?.company_sociallinks?.linkedin) {
      currentPercentage += percentageData.LINKEDIN_URL;
    }
    return currentPercentage;
  }, [userInfo]);
  useEffect(() => {
    refetchVerifyInfo();
  }, [customerNav]);

  return (
    <Flex direction="col">
      {onboardingPercentage !== 100 && !isWebApp && (
        <Flex
          padding=" px-5 py-2.5"
          backgroundColor=" bg-[#FEF3C7]"
          justify="between"
        >
          <Text color="text-[#92400E]" variant="psm" weight={500}>
            {ContactLabels.ONBOARDING_COMPLETION}
            <Text
              variant="span"
              color=" text-[#92400E]"
              margin="ml-1"
              weight={500}
            >
              {onboardingPercentage}%
            </Text>
          </Text>
          <Flex
            justify="center"
            alignItems="center"
            gap="gap-2"
            className="cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_APP_URL}settings/company/company-details`,
              );
              // navigate('/settings');
            }}
          >
            <Text color="text-[#92400E]" weight={500} variant="psm">
              {ContactLabels.IMPROVE}
            </Text>
            <Icon name="ChevronIcon" className="w-3 h-3 text-[#92400E]" />
          </Flex>
        </Flex>
      )}

      <Flex padding="p-5" direction="col">
        {!isWebApp && (
          <>
            {(isLoadingCredits || !creditPointsData?.data?.status) && (
              <Skeleton.Input size="small" className=" w-full" active />
            )}
            {!isLoadingCredits && creditPointsData?.data?.status && (
              <UpgradeView
                type={
                  (creditPointsData?.data?.data?.available_credits || 0) >= 1
                    ? 'success'
                    : (creditPointsData?.data?.data?.available_credits || 0) ===
                      0
                    ? 'error'
                    : 'warning'
                }
                creditCount={
                  creditPointsData?.data?.data?.available_credits || 0
                }
                isLoadingRefetch={isLoadingRefetch}
                onRefreshProspect={onRefreshProspect}
                isShowRefresh={isShowRefresh}
                setIsShowRefresh={setIsShowRefresh}
                subscription={userInfo?.subscription_plan}
                isGuide={isGuide}
                isOpen={isOpen}
                currentTour={currentTour}
              />
            )}
          </>
        )}
        <Flex margin={`${isWebApp ? 'mt-0' : 'mt-5'}`}>
          <CreditPointsContext.Provider
            value={{
              isAuthUserInfoLoading,
              creditPointsData,
              onRefetchCreditPoints,
              isLoadingCredits,
              userInfo,
              prospectRefreshData,
              setProspectRefreshData,
              setIsShowRefresh,
              isLoadingRetryProspectRefetch: isLoadingRefetch,
              onOpenGuide: (val) => {
                const isTourEnabled = Boolean(
                  localStorage.getItem('isTourEnabled'),
                );
                if (!isTourEnabled && location?.pathname === '/contact') {
                  setIsOpen(val);
                }
              },
            }}
          >
            {children}
            <Outlet />
          </CreditPointsContext.Provider>
        </Flex>
      </Flex>

      {!isWebApp && (
        <Tour
          rootClassName="refreshTour"
          open={isOpen}
          onClose={() => {
            onCloseTour();
          }}
          steps={steps}
          indicatorsRender={(current, total) => {
            setCurrentTour(current + 1);

            if (current + 1 === total) {
              return <></>;
            }
            return (
              <Text variant="pxs" color=" text-tertinary">
                <Text
                  variant="span"
                  onClick={() => {
                    onCloseTour();
                  }}
                  className=" !text-xs cursor-pointer"
                  color=" text-tertinary"
                >
                  <StopOutlined className="mr-1" /> Skip Tour
                </Text>{' '}
                • {current + 1} of {total}
              </Text>
            );
          }}
        />
      )}
    </Flex>
  );
};

export default AuthNav;
