import { useContext } from 'react';
import { ContactLabels } from 'consts/label';
import BackContainer from 'components/BackContainer';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { Button, Input, Tag } from 'components/Ant';
import { Icon } from 'components/Icon';
import { OutReachContext } from './outReach.context';

const OutreachCard = ({
  data,
  isAssigned,
}: {
  data?: any;
  isAssigned: boolean;
}) => {
  return (
    <>
      {/* eslint-disable-next-line array-callback-return */}
      {(data || []).map((insight, outreachIndex) => {
        return (
          <Flex
            padding="p-4"
            backgroundColor="bg-white"
            rounded="rounded-md"
            width="w-full"
            direction="col"
            className="group sequenceCard shadow-card "
          >
            <Flex direction="col" key={`outreach-${outreachIndex}`} gap="gap-1">
              <Text
                color="text-gray-900"
                variant="psm"
                weight={500}
                className="group-hover:text-primary "
              >
                Employee reach out stretegy
              </Text>
              <Text
                color="text-gray-500"
                variant="psm"
                weight={400}
                className=" group-hover:text-primaryVariant"
              >
                Sequence description will be shown here
              </Text>
              <Flex direction="col" className="viewOnHover" gap="gap-2">
                <Text variant="psm" color="text-primaryVariant">
                  {' '}
                  6 steps • 12 days
                </Text>
                <Flex width="w-full" gap="gap-2">
                  <Button
                    size="small"
                    htmlType="submit"
                    className="text-xs w-1/2"
                  >
                    {ContactLabels.VIEW_DETAILS}
                  </Button>

                  {!isAssigned ? (
                    <Button
                      size="small"
                      type="primary"
                      className="text-xs w-1/2 text-indigo-700 bg-indigo-100"
                    >
                      {ContactLabels.ASSIGNED} • {ContactLabels.VIEW_STATUS}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      type="primary"
                      className="w-1/2 text-xs"
                    >
                      {ContactLabels.ASSIGN}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
};

const OutReachView = () => {
  const { onCancel, handleCloseTag } = useContext(OutReachContext);
  return (
    <Flex direction="col" width="w-full" padding="p-5" gap="gap-2">
      <BackContainer
        title={ContactLabels.ADD_TO_OUTREACH_SEQUENCE}
        onBack={() => {
          onCancel();
        }}
      />
      <Flex gap="gap-2" margin="mt-4" width="w-full" justify="between">
        <Input
          placeholder={ContactLabels.SEARCH_FOR_A_OUTREACH_SEQUENCE}
          className=" !bg-white w-73 h-9 "
          size="middle"
          prefix={<Icon name="SearchOutlinedIcon" />}
        />
        <Button size="large" className="pt-2 h-9">
          <Icon name="FilterIcon" />
        </Button>
      </Flex>
      <Flex>
        <Tag
          className=" text-purple-800 bg-purple-100 rounded-3xl px-2.5 py-0.5"
          bordered={false}
          closeIcon
          onClose={handleCloseTag}
        >
          Recent job change
        </Tag>
      </Flex>
      <OutreachCard data={[{}, {}, {}]} isAssigned />
    </Flex>
  );
};

export default OutReachView;
