import { CreateAccountProvider } from './createAccount.context';
import CreateAccountView from './createAccount.view';

const CreateAccountApp = () => {
  return (
    <CreateAccountProvider>
      <CreateAccountView />
    </CreateAccountProvider>
  );
};

export default CreateAccountApp;
