import { captilizeFirst, formatUserCount } from 'utils';
import moment from 'moment';
// import { InsightsLabels } from 'consts/label';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Skeleton, Tag, Typography } from 'components/Ant';
import Flex from 'components/Flex';
// import { Icon } from 'components/Icon';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { Icon } from 'components/Icon';

export const NewsLoading = ({ title }) => {
  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      className=" shadow-card"
      justify="between"
      width="w-full"
      alignItems="center"
    >
      <Text color=" text-secondary" variant="psm" weight={500}>
        {title}
      </Text>
      <Loading />
    </Flex>
  );
};

export const NewsDataLoading = () => (
  <Flex gap="gap-2.5" direction="col">
    <Flex>
      <Skeleton.Input
        size="small"
        className=" rounded-3xl max-h-4 bg-green-100"
        active
      />
    </Flex>
    <Text color=" text-gray-900" variant="psm" weight={500}>
      <Skeleton.Input className=" w-full max-h-4 rounded-3xl" active />
      <Skeleton.Input className="max-h-4 rounded-3xl mt-0.5" active />
    </Text>
  </Flex>
);

export const NewsData = ({ news, newsIndex }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        gap="gap-2.5"
        direction="col"
        key={`news-${news.title}-${newsIndex}`}
      >
        <Flex justify="between">
          <Tag
            className=" bg-green-100 font-medium rounded-3xl px-2.5 py-0.5 max-w-[50%]"
            bordered={false}
          >
            <Typography.Paragraph
              className="text-green-800  mb-0"
              ellipsis={{
                rows: 1,
                tooltip: captilizeFirst(
                  Array.isArray(news?.category)
                    ? news?.category?.[0]
                    : news.category,
                ),
              }}
            >
              {captilizeFirst(
                Array.isArray(news?.category)
                  ? news?.category?.[0]
                  : news.category,
              )}
            </Typography.Paragraph>
          </Tag>
          <Flex
            justify="center"
            alignItems="center"
            gap="gap-0.5"
            className=" text-secondary"
          >
            {news?.isInsights ? (
              <>
                <Icon
                  name="LinkedInFollowersIcon"
                  className="text-secondary flex-shrink-0 w-4"
                />
                <Text
                  variant="span"
                  weight={400}
                  color="text-secondary"
                  margin="mt-0.5"
                >
                  Linkedin
                </Text>
              </>
            ) : news?.isYouTube ? (
              <>
                {!isHovered && (
                  <>
                    <Icon
                      name="YoutubeIcon"
                      className="text-secondary flex-shrink-0"
                    />
                    <Text variant="span" weight={400} color="text-secondary">
                      YouTube
                    </Text>
                  </>
                )}
                {isHovered && (
                  <>
                    {news.published_date && (
                      <>
                        <Text
                          color="text-secondary"
                          weight={400}
                          variant="psm"
                          letterSpacing="wide"
                          className=" leading-5  whitespace-nowrap"
                        >
                          {`${formatUserCount(news.views)} • ${
                            news.published_date
                          }`}
                        </Text>
                      </>
                    )}
                    {!news?.isInsights && news.pubDate && (
                      <>
                        <Text
                          color="text-secondary"
                          weight={400}
                          variant="psm"
                          letterSpacing="wide"
                          className=" leading-5 whitespace-pre-line"
                        >
                          {moment(news.pubDate).format('MMM DD, YYYY')}
                        </Text>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!isHovered && (
                  <>
                    <Icon
                      name="newsIcon"
                      className="text-secondary flex-shrink-0"
                    />
                    <Text variant="span" weight={400} color="text-secondary">
                      News
                    </Text>
                  </>
                )}
                {isHovered && !news?.isInsights && news.pubDate && (
                  <>
                    <Text
                      color="text-secondary"
                      weight={400}
                      variant="psm"
                      letterSpacing="wide"
                      className=" leading-5 whitespace-pre-line"
                    >
                      {moment(news.pubDate).format('MMM DD, YYYY')}
                    </Text>
                  </>
                )}
              </>
            )}

            {/* {news.sentiment && (
            <Tooltip
              title={`${captilizeFirst(news.sentiment)} ${
                InsightsLabels.SENTIMENT
              }`}
            >
              <span className="flex cursor-pointer">
                {news.sentiment === 'neutral' && (
                  <Icon name="SmileNeutralIcon" />
                )}
                {news.sentiment === 'negative' && (
                  <Icon name="SmileSadIcon" className="text-red-500" />
                )}
                {news.sentiment === 'positive' && (
                  <Icon name="SmileIcon" className=" text-[#10B981]" />
                )}
              </span>
            </Tooltip>
          )}
          {news.link && (
            <>
              {news.sentiment && news.link ? '•' : ''}
              <Tooltip title={InsightsLabels.VIEW_LINK}>
                <span>
                  <Text
                    variant="span"
                    className="flex cursor-pointer"
                    onClick={() => {
                      window.open(news.link);
                    }}
                  >
                    <Icon
                      name="LinkIcon"
                      className=" text-secondary hover:text-primary"
                    />
                  </Text>
                </span>
              </Tooltip>
            </>
          )} */}
          </Flex>
        </Flex>
        <Text
          color={`text-textPrimary ${
            news.isInsights ? '' : 'hover:text-primary'
          }`}
          variant="psm"
          weight={500}
          letterSpacing="wide"
          className={`leading-5  ${news.isInsights ? '' : 'cursor-pointer'}`}
          onClick={() => {
            if (news.link) {
              window.open(news.link);
            }
          }}
        >
          {news.title || news.insight}
          {!news.isInsights && (
            <ArrowRightOutlined rotate={-45} className="ml-1" />
          )}
        </Text>
        {news.description && (
          <Text
            color=" text-textPrimary"
            variant="ellipsis"
            weight={400}
            letterSpacing="wide"
            className=" leading-5 whitespace-pre-line"
            ellipsis={{
              rows: news?.isYouTube ? 3 : 2,
              expandable: true,
              symbol: 'more',
            }}
          >
            {news.description}
          </Text>
        )}
        {/* {(news.ai_tag || [])?.length !== 0 && (
        <Text
          color="text-secondary"
          weight={400}
          variant="psm"
          letterSpacing="wide"
          className=" leading-5 whitespace-pre-line"
        >
          {(news.ai_tag || [])?.map((tag, tagIndex: number) => {
            return (
              <>
                <Text
                  variant="span"
                  weight={400}
                  key={`${news.title}-${newsIndex}-${tagIndex}`}
                >
                  {captilizeFirst(tag)}
                </Text>
                {tagIndex + 1 !== news.ai_tag?.length && ` · `}
              </>
            );
          })}
          Corporate news · Financial markets
        </Text>
      )} */}

        {/* <Flex justify="between" width="w-full" gap="gap-2"> */}
        {/* {!!insight.data_source?.length && (
        <Text color="text-primary" variant="psm" className=" break-all">
          {insight.data_source?.length > 1
            ? insight.data_source.join(' . ')
            : insight.data_source[0]}
        </Text>
      )} */}
        {/* <Text
          color="text-gray-500"
          variant="psm"
          weight={400}
          className=" whitespace-nowrap"
        >
          {insight.date ? moment(insight.date).format('MMM DD, YYYY') : ''}
        </Text> */}
        {/* </Flex> */}
      </Flex>
    </div>
  );
};

export const NewsComponent = ({
  title,
  data,
}: {
  title: string;
  data?: any;
}) => {
  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
      gap="gap-4"
    >
      {(data || []).map((news, newsIndex: number) => {
        return (
          <div
            key={`newsdata-${newsIndex}`}
            // className={`${
            //   newsIndex === 0 || isShowMore ? '' : 'hidden'
            // }`}
          >
            {/* {(newsIndex === 0 || isShowMore) && ( */}
            <NewsData
              news={news}
              newsIndex={newsIndex}
              key={`news-${title}-${newsIndex}`}
            />
            {data?.length !== newsIndex + 1 && (
              <Flex
                margin="mt-4"
                border="border-0 border-solid border-t"
                borderColor="border-gray-100"
              />
            )}
            {/* )} */}
          </div>
        );
      })}
    </Flex>
  );
};
