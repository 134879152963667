import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommonLabels, ForgotPasswordLabels } from 'consts/label';
import Flex from 'components/Flex';
import { Button, Form, Input } from 'components/Ant';
import Text from 'components/Text';
import BackContainer from 'components/BackContainer';
import { ForgotPasswordContext } from './forgotPassword.context';

const VerifyEmailView = () => {
  const { onCancel, onVerifyEmail, verifyEmailForm } = useContext(
    ForgotPasswordContext,
  );

  return (
    <Flex direction="col" width="w-full" padding="p-5">
      <BackContainer
        title={ForgotPasswordLabels.VERIFY_EMAIL}
        onBack={() => {
          onCancel();
        }}
      />
      <Text color="text-textPrimary" className=" text-base" padding="py-5">
        {ForgotPasswordLabels.VERIFY_EMAIL_DESCRIPTION}
      </Text>
      <Form
        name="verifyEmail"
        layout="vertical"
        className="w-full"
        form={verifyEmailForm}
        onFinish={onVerifyEmail}
        autoComplete="off"
      >
        <Form.Item
          name="verificationCode"
          label={ForgotPasswordLabels.VERIFICATION_CODE}
          rules={[{ required: true }]}
        >
          <Input placeholder={ForgotPasswordLabels.VERTIFICATION_PLACEHOLDER} />
        </Form.Item>
        <Link to="/change-password">
          <Button
            type="primary"
            // htmlType="submit"
            onClick={onVerifyEmail}
            size="large"
            className="w-full font-medium"
          >
            {ForgotPasswordLabels.VERIFY_TEXT}
          </Button>
        </Link>
        <Button
          type="link"
          size="large"
          className="w-full font-medium mt-1"
          onClick={onCancel}
        >
          {CommonLabels.CANCEL}
        </Button>
      </Form>
    </Flex>
  );
};

export default VerifyEmailView;
