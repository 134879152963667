import React from 'react';
import { ChildrenProps } from 'types/custom';
import InsightsService from './insights.service';

export const InsightsContext = React.createContext<any>('');

export const InsightsProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = InsightsService();

  return (
    <InsightsContext.Provider value={stateService}>
      {children}
    </InsightsContext.Provider>
  );
};
