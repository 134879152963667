export const OnboardingLabels = {
  NAVIGATE_TO_LINKEDIN: 'Navigate to a LinkedIn',
  PROFILE_PAGE: 'profile page',
  HAPPYSALES_HELPS: 'Happysales helps you to',
  HAPPYSALES_HELPS_DATA: [
    'Get insights into prospects & their company',
    'Understand their pain points and delivering a tailor-made pitch',
    'AI generated personalized emails in seconds',
  ],
};

export const Onboarding = {};
