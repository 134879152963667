export const AuthLabels = {
  CREATE_ACCOUNT: 'Create a new account',
  ALREADY_LOGGED_IN: 'Existing user? Log in',
  EMAIL_PLACEHOLDER: 'Enter your Work Email',
  PASSWORD_VALIDATION: 'Password should not be less than 8 characters',
  PASSWORD_SPL_CHARS_ERROR:
    'Password should contain atleast one special character',
  PASSWORD_NUMERIC_ERROR: 'Password should contain atleast one number',
  PASSWORD_ALPHABET_ERROR: 'Password should contain atleast one alphabet',
  EMAIL_VALIDATION_MESSAGE: 'Enter a valid email!',
  FIRST_NAME_PLACEHOLDER: 'Enter your First Name',
  LABEL_ERROR_MESSAGE: 'Please enter a valid name',
  LAST_NAME_PLACEHOLDER: 'Enter your Last Name',
  PASSWORD_PLACEHOLDER: 'Enter your Password',
  FORGOT_PASSWORD: 'Forgot password?',
  TELL_US_ABOUT_YOUR_COMPANY: 'Tell us a little bit about your company',
  COMPANY_NAME: 'Company Name',
  COMPANY_NAME_PLACEHOLDER: "Enter your Company's Name",
  COMPANY_WEBSITE: 'Company Website',
  COMPANY_NAME_ERROR_MESSAGE: 'Enter a valid company name',
  COMPANY_WEBSITE_PLACEHOLDER: "Enter your Company's Website",
  YOUR_COMPANY_DO: 'What does your company do?',
  YOUR_COMPANY_DO_PLACEHOLDER: 'Describe your company and value proposition',
  TELL_US_ABOUT_CUSTOMERS: 'Tell us about your customers and competitors',
  TELL_US_ABOUT_CUSTOMERS_DESCRIPTION:
    'Optional • You can fill these later in settings as well',
  SET_PREFERRED_LANGUAGE: 'Set your preferred language',
  SET_PREFERRED_LANGUAGE_DESCRIPTION:
    'It will be the language you read and write in',
  SELECT_LANGUAGE: 'Select language',
  SELECT_LANGUAGE_DESC: 'You can change it later in settings as well',
  LOGOUT: 'Logout',
  ENTER_SELECT_LANGUAGE: 'Please select the language',
  COMPANY_AI_LANG: 'AI language',
  ALREADY_HAVING_ACCOUNT: 'Have a account?',
  LOGIN: 'Login',
  MINIMUM_8: 'At least 8 characters in length',
  CONTAIN_ONE_NUMBER: 'Must contain at least 1 number',
  CONTAIN_SPL_CHARACTER: 'Must contain at least 1 special character',
  STREAM_AI_RESPONSE: 'Stream AI responses',
  STERAM_AI_TOOLTIP: 'Shows responses word by word (typing animation)',
  ADVANCED_SETTINGS: 'Advanced settings',
  GO_TO_WEB_APP: 'Go to web app',
};

export const ForgotPasswordLabels = {
  FORGOT_PASSWORD: 'Forgot Password',
  FORGOT_PASSWORD_DESCRIPTION:
    'Please enter your email address to receive a verification code.',
  EMAIL_PLACEHOLDER: 'Enter your Work Email',
  VERIFY_EMAIL: 'Verify your Email',
  VERIFY_EMAIL_DESCRIPTION: 'Please enter the 4 digit code sent to',
  VERIFICATION_CODE: 'Verification Code',
  VERTIFICATION_PLACEHOLDER: '1234',
  VERIFY_TEXT: 'Verify',
};

export const ChangePasswordLabels = {
  NEW_PASSWORD: 'New Password',
  NEW_PASSWORD_DESCRIPTION:
    'Your new password must be different from previous password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_MODIFIED: 'Password changed successful',
};

export const SettingsLabel = {
  SETTINGS: 'Settings',
  EXAMPLE_EMAIL: 'Example emails',
  EXAMPLE_EMAIL_PLACEHOLDER: 'Add your favourite sales sequences',
  TOP_CUSTOMERS: 'Top Customers',
  TOP_CUSTOMERS_PLACEHOLDER: 'Add names of your top customers',
  CUSTOMER_WINS: 'Customer wins',
  CUSTOMER_WINS_PLACEHOLDER:
    'Share delight stories of your existing customers and improvement metrics. You can also add case-study links here.',
  TOP_COMPETITORS: 'Top competitors',
  TOP_COMPETITORS_PLACEHOLDER: 'Add name of your competitors and why we win',
};
