import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import NavContext from 'context/NavContext';
import { refreshToken } from 'settings/AxiosSetting';
// eslint-disable-next-line import/no-extraneous-dependencies
import EmailValidation from 'emailvalid';
import { passTokenToWebapp } from 'utils';
import { Form, message } from 'components/Ant';
import { useSignUp, useLogin, useValidateEmail } from './createAccount.query';

const CreateAccountService = () => {
  const [activeKey, setActiveKey] = useState('createAccount');
  const [createAccountform] = Form.useForm();
  const [loginform] = Form.useForm();
  const [isLoginError, setIsLoginError] = useState(false);
  const [isLoginErrorMsg, setIsLoginErrorMsg] = useState();
  const newPassword = Form.useWatch('password', createAccountform);
  const [aboutCompanyform] = Form.useForm();
  const customerNav = useContext(NavContext);
  const [messageApi, contextHolder] = message.useMessage();
  // const { data: getEmailData } = useGetEmail();
  // const [listOfEmails, setListOfEmails] = useState();
  const EmailValid = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new EmailValidation({ allowDisposable: false, allowFreemail: true });
  }, []);

  const navigate = useNavigate();
  const { mutate: onSignUp, isLoading: isSignUpLoading } = useSignUp();
  const { mutate: onLogin, isLoading: isLoginLoading } = useLogin();
  const { mutate: validateEmail, isLoading: isValidatingEmail } =
    useValidateEmail();

  const handleTabChange = useCallback((value: string) => {
    setActiveKey(value);
  }, []);

  const handleCloseExtension = () => {
    window.close();
  };

  // const getEmailDataFn = () => {
  //   if (getEmailData) setListOfEmails(getEmailData?.data?.data);
  // };

  const processSignUp = (values) => {
    onSignUp(
      {
        email: (values.email || '').toLowerCase(),
        name: `${values.firstName} ${values.lastName}`,
        password: values.password,
        language: localStorage.getItem('language') || '',
        company_name: values.companyName,
        company_website: values.companyWebsite,
      },
      {
        onSuccess: (data) => {
          localStorage.setItem('token', data.data.data.token);
          localStorage.setItem('userid', data.data.data.user_id);
          refreshToken();
          if (customerNav?.isGmailPage) {
            navigate('/email');
          } else if (customerNav?.isProfile) {
            navigate('/contact');
          } else {
            navigate('/onboarding');
          }
        },
        onError: (error) => {
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            const obj: any = axiosError.response.data;
            const form =
              activeKey === 'createAccount' ? createAccountform : loginform;
            if (obj.message.email) {
              form.setFields([
                {
                  errors: [obj.message?.email],
                  name: 'email',
                },
              ]);
              messageApi.open({
                type: 'error',
                content: obj.message?.email,
              });
            }
            if (obj.message.password) {
              form.setFields([
                {
                  errors: [obj.message?.password],
                  name: 'password',
                },
              ]);
              messageApi.open({
                type: 'error',
                content: obj.message?.password,
              });
            }
          }
        },
      },
    );
  };

  const onCreateAccount = (values) => {
    validateEmail(
      {
        email: (values.email || '').toLowerCase(),
      },
      {
        onSuccess: (data) => {
          if (data?.data?.data?.valid) {
            processSignUp(values);
          } else {
            let msg = data?.data?.message;
            if (data?.data?.data?.errors?.[0] === 'freemail') {
              msg = 'Invalid mail';
            }
            messageApi.open({
              type: 'error',
              content: msg,
            });
            createAccountform.setFields([
              {
                errors: [msg],
                name: 'email',
              },
            ]);
          }
        },
      },
    );
  };
  const onCreateAccountStep2 = () => {
    navigate('/create-account', { replace: true });
  };
  const handleGoogleLogin = useCallback(() => {
    // window.location.href = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
    window.open(
      `${
        process.env.REACT_APP_WEB_APP_URL as string
      }login?extensionLogin=googlesignin`,
    );
  }, []);

  const onLoginAccount = (values) => {
    onLogin(
      {
        email: (values.email || '').toLowerCase(),
        password: values.password,
      },
      {
        onSuccess: (data) => {
          localStorage.setItem('token', data?.data?.data?.token);
          localStorage.setItem('userid', data?.data?.data?.user_id);
          refreshToken();
          passTokenToWebapp(data?.data?.data?.token);
          if (customerNav?.isGmailPage) {
            navigate('/email');
          } else if (customerNav?.isProfile) {
            navigate('/contact');
          } else {
            navigate('/onboarding');
          }
        },
        onError: (error) => {
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            const obj: any = axiosError.response.data;
            const form = loginform;
            if (obj.message.email) {
              messageApi.open({
                type: 'error',
                content: obj.message?.email,
              });
              form.setFields([
                {
                  errors: [obj.message?.email],
                  name: 'email',
                },
              ]);
            }
            if (obj.message.password) {
              messageApi.open({
                type: 'error',
                content: obj.message?.password,
              });
              form.setFields([
                {
                  errors: [obj.message?.password],
                  name: 'password',
                },
              ]);
            }
            setIsLoginErrorMsg(obj.message);
            setIsLoginError(true);
          }
        },
      },
    );
  };

  const onSettingsBack = () => {
    navigate(-1);
  };
  return {
    activeKey,
    // getEmailData,
    isLoginError,
    isLoginErrorMsg,
    handleTabChange,
    createAccountform,
    handleCloseExtension,
    onCreateAccount,
    handleGoogleLogin,
    loginform,
    aboutCompanyform,
    onLoginAccount,
    isSignUpLoading,
    isLoginLoading,
    onSettingsBack,
    // getEmailDataFn,
    onCreateAccountStep2,
    // listOfEmails,
    EmailValid,
    isValidatingEmail,
    newPassword,
    contextHolder,
  };
};
export default CreateAccountService;
