import { ChildrenProps } from 'types/custom';
import RouterSetting from './RouterSetting';
import AntSetting from './AntSetting';
import ReactQueryClientProvider from './AxiosSetting';

const AppSetting = ({ children }: ChildrenProps) => {
  return (
    <ReactQueryClientProvider>
      <AntSetting>
        <RouterSetting>{children}</RouterSetting>
      </AntSetting>
    </ReactQueryClientProvider>
  );
};

export default AppSetting;
