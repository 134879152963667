import React from 'react';
import InsightsView from './insights.view';
import { InsightsProvider } from './insights.context';

const Insights = () => {
  return (
    <InsightsProvider>
      <InsightsView />
    </InsightsProvider>
  );
};
export default Insights;
