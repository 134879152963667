import { Skeleton, Tag } from 'components/Ant';
import Flex from 'components/Flex';
import Loading from 'components/Loading';
import Text from 'components/Text';

export const InsightLoading = ({ title }) => {
  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      className=" shadow-card"
      justify="between"
      width="w-full"
      alignItems="center"
    >
      <Text color=" text-secondary" variant="psm" weight={500}>
        {title}
      </Text>
      <Loading />
    </Flex>
  );
};

export const InsightsDataLoading = () => (
  <Flex gap="gap-2.5" direction="col">
    <Flex>
      <Skeleton.Input
        size="small"
        className=" rounded-3xl max-h-4 bg-green-100"
        active
      />
    </Flex>
    <Text color=" text-gray-900" variant="psm" weight={500}>
      <Skeleton.Input className=" w-full max-h-4 rounded-3xl" active />
      <Skeleton.Input className="max-h-4 rounded-3xl mt-0.5" active />
    </Text>
  </Flex>
);

export const InsightData = ({ insight, insightIndex }) => {
  return (
    <Flex gap="gap-2.5" direction="col" key={`insight-${insightIndex}`}>
      <Flex>
        <Tag
          className=" text-green-800 bg-green-100 font-medium rounded-3xl px-2.5 py-0.5"
          bordered={false}
        >
          {insight.category}
        </Tag>
      </Flex>
      <Text
        color=" text-gray-900"
        variant="psm"
        weight={400}
        letterSpacing="wide"
        className=" leading-5"
      >
        {insight.title || insight.insight}
      </Text>
      {/* <Flex justify="between" width="w-full" gap="gap-2"> */}
      {/* {!!insight.data_source?.length && (
        <Text color="text-primary" variant="psm" className=" break-all">
          {insight.data_source?.length > 1
            ? insight.data_source.join(' . ')
            : insight.data_source[0]}
        </Text>
      )} */}
      {/* <Text
          color="text-gray-500"
          variant="psm"
          weight={400}
          className=" whitespace-nowrap"
        >
          {insight.date ? moment(insight.date).format('MMM DD, YYYY') : ''}
        </Text> */}
      {/* </Flex> */}
    </Flex>
  );
};

export const InsightComponent = ({
  title,
  data,
}: {
  title: string;
  data?: any;
}) => {
  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
      gap="gap-4"
    >
      {(data || []).map((insight, insightIndex: number) => {
        return (
          <div
            key={`insightdata-${insightIndex}`}
            // className={`${
            //   insightIndex === 0 || isShowMore ? '' : 'hidden'
            // }`}
          >
            {/* {(insightIndex === 0 || isShowMore) && ( */}
            <InsightData
              insight={insight}
              insightIndex={insightIndex}
              key={`insight-${title}-${insightIndex}`}
            />
            {data?.length !== insightIndex + 1 && (
              <Flex
                margin="mt-4"
                border="border-0 border-solid border-t"
                borderColor="border-gray-100"
              />
            )}
            {/* )} */}
          </div>
        );
      })}
    </Flex>
  );
};
