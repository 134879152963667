import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { refreshToken } from 'settings/AxiosSetting';
import Flex from 'components/Flex';
import Loading from 'components/Loading';

const VerificationPage = () => {
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    localStorage.setItem('token', JSON.parse(JSON.stringify(token)));
    refreshToken();

    if (new URLSearchParams(search).get('companyDetails')) {
      navigate('/contact');
    } else {
      navigate('/get-started');
    }
  }, []);

  return (
    <Flex justify="center" margin="mt-12">
      <Loading />
    </Flex>
  );
};

export default VerificationPage;
