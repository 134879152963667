import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'components/Ant';

const ForgotPasswordService = () => {
  const [activeKey, setActiveKey] = useState('createAccount');
  const [forgotPasswordForm] = Form.useForm();
  const [verifyEmailForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  const navigate = useNavigate();

  const handleTabChange = useCallback((value: string) => {
    setActiveKey(value);
  }, []);
  const onForgotPassword = useCallback(() => {
    navigate('/verify-email');
  }, [navigate]);
  const onCancel = () => {
    navigate('/');
  };
  const onVerifyEmail = useCallback(() => {
    navigate('/change-password');
  }, [navigate]);

  return {
    activeKey,
    handleTabChange,
    forgotPasswordForm,
    onForgotPassword,
    onCancel,
    verifyEmailForm,
    onVerifyEmail,
    changePasswordForm,
  };
};
export default ForgotPasswordService;
