import Flex from 'components/Flex';
import { Icon, IconNameType } from 'components/Icon';
import Text from 'components/Text';

const TabLabel = ({
  icon,
  title,
  small = false,
  iconClassName = '',
}: {
  icon: IconNameType;
  title: string;
  small?: boolean;
  iconClassName?: string;
}) => {
  return (
    <Text
      variant="span"
      weight={500}
      className={`group ${small ? '!text-xs' : ''}`}
    >
      <Flex justify="center" alignItems="center" className=" font-medium">
        {icon && (
          <Icon
            name={icon}
            className={`mr-2 flex-shrink-0 ${iconClassName} `}
          />
        )}
        {title}
      </Flex>
    </Text>
  );
};
export default TabLabel;
