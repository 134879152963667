import { useContext, useEffect, useState } from 'react';
import NavContext from 'context/NavContext';
import useGenerateEmailConversation from './email.query';

const EmailService = () => {
  const {
    mutate: onGenerateEmailContext,
    isLoading: isGenerateEmailAPILoading,
  } = useGenerateEmailConversation();
  const customerNav = useContext(NavContext);
  const [isEmailConversationApiLoading, setisEmailConversationApiLoading] =
    useState(false);
  const [emailResponse, setEmailResponse] = useState('');
  const [isPermissionGiven, setIsPermissionGiven] = useState(true);
  const [isCreditMessageSkipped, setcreditMessageSkipped] = useState(true);

  const GenerateEmailConversation = () => {
    setisEmailConversationApiLoading(true);

    const payload = {
      token: localStorage.getItem('token'),
      email_content: customerNav.emailConversation,
      prospect_email: customerNav.prospectEmail,
      message_list: customerNav.messageList,
    };

    if (payload.token != null) {
      onGenerateEmailContext(payload, {
        onSuccess: (data: any) => {
          setisEmailConversationApiLoading(false);
          if (data.data?.status === true) {
            setEmailResponse(data.data?.email);
          }
        },
        onError() {
          setisEmailConversationApiLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    setIsPermissionGiven(false);
    const isMessageSkipped = localStorage.getItem('creditMessageSkipped');

    GenerateEmailConversation();

    if (isMessageSkipped && isMessageSkipped === 'true') {
      // setcreditMessageSkipped(true);
    }
  }, [customerNav.emailConversation, customerNav.prospectEmail]);

  return {
    GenerateEmailConversation,
    isGenerateEmailAPILoading,
    isEmailConversationApiLoading,
    setisEmailConversationApiLoading,
    emailResponse,
    setEmailResponse,
    isPermissionGiven,
    setIsPermissionGiven,
    isCreditMessageSkipped,
    setcreditMessageSkipped,
  };
};

export default EmailService;
