export const OnboardingLabels = {
  NAVIGATE_TO_LINKEDIN: 'Navigate to a LinkedIn',
  NAVIGATE_TO_MAILBOX: 'Navigate to mail box',
  PROFILE_NOT_FOUND: 'Profile not found',
  PROFILE_PAGE: 'profile page',
  HAPPYSALES_HELPS: 'Happysales helps you to',
  HAPPYSALES_HELPS_DATA: [
    'Get insights into prospects & their company',
    'Understand their pain points and delivering a tailor-made pitch',
    'AI generated personalized emails in seconds',
  ],
  EMAIL_CONVERSATION_PERMISSION_LABEL:
    'Seeking your permission to read your email and respond',
};

export const Onboarding = {};
