import { useMutation } from '@tanstack/react-query';
import { axiosAiApiInstance } from 'settings/AxiosSetting';

const useGenerateEmailConversation = () =>
  useMutation(
    (data: {
      token?: string | null;
      email_content?: string | any;
      prospect_email?: string | any;
      message_list?: any;
    }) => {
      return axiosAiApiInstance.post('api/v1/gmail_conversation', data);
    },
  );

export default useGenerateEmailConversation;
