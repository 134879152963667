import Flex from 'components/Flex/Flex';

const Loading = () => {
  return (
    <Flex width=" min-w-[20px]">
      <div className="dot-pulse" />
    </Flex>
  );
};

export default Loading;
