import { useContext, useMemo } from 'react';
import { AuthLabels } from 'consts/label';
import { Tabs } from 'components/Ant';
import Flex from 'components/Flex';
import { CreateAccountContext } from './createAccount.context';
import SignupView from './signup.view';
import LoginView from './login.view';

const AccountDetails = () => {
  const { activeKey, handleTabChange } = useContext(CreateAccountContext);
  const tabList = useMemo(() => {
    return [
      {
        key: 'createAccount',
        label: `${AuthLabels.CREATE_ACCOUNT}`,
        children: <SignupView />,
      },
      {
        key: 'alreadyLogin',
        label: `${AuthLabels.ALREADY_LOGGED_IN}`,
        children: <LoginView />,
      },
    ];
  }, []);
  return (
    <Flex direction="col" width="w-full">
      <Flex margin=" -mt-3" />
      <Tabs
        defaultActiveKey="createAccount"
        items={tabList}
        centered
        activeKey={activeKey}
        onChange={handleTabChange}
        className=" pt-0"
      />
    </Flex>
  );
};

export default AccountDetails;
