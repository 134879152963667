import Flex from 'components/Flex';
import Text from 'components/Text';
import { Icon } from '.';

const Copy = ({ iconClassName = '', label = 'Copy', isShowLabel = true }) => {
  return (
    <Flex gap="gap-1" className="group cursor-pointer" justify="between">
      <Icon
        name="CopyIcon"
        className={` text-secondary group-hover:text-primary ${iconClassName}`}
      />
      {isShowLabel && (
        <Text variant="psm" color="text-secondary group-hover:text-primary">
          {label}
        </Text>
      )}
    </Flex>
  );
};
export default Copy;
