import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthLabels, ChangePasswordLabels, CommonLabels } from 'consts/label';
import Flex from 'components/Flex';
import { Button, Form, InputPassword } from 'components/Ant';
import Text from 'components/Text';
import BackContainer from 'components/BackContainer';
import { ForgotPasswordContext } from './forgotPassword.context';

const ChangePasswordView = () => {
  const { onCancel, onVerifyEmail, changePasswordForm } = useContext(
    ForgotPasswordContext,
  );

  return (
    <Flex direction="col" width="w-full" padding="p-5">
      <BackContainer
        title={ChangePasswordLabels.NEW_PASSWORD}
        onBack={() => {
          onCancel();
        }}
      />
      <Text color="text-textPrimary" className=" text-base" padding="py-5">
        {ChangePasswordLabels.NEW_PASSWORD_DESCRIPTION}
      </Text>
      <Form
        name="verifyEmail"
        layout="vertical"
        className="w-full"
        form={changePasswordForm}
        onFinish={onVerifyEmail}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label={ChangePasswordLabels.NEW_PASSWORD}
          rules={[{ message: AuthLabels.PASSWORD_PLACEHOLDER, required: true }]}
        >
          <InputPassword placeholder={AuthLabels.PASSWORD_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="password"
          label={ChangePasswordLabels.CONFIRM_PASSWORD}
          rules={[{ message: AuthLabels.PASSWORD_PLACEHOLDER, required: true }]}
        >
          <InputPassword placeholder={AuthLabels.PASSWORD_PLACEHOLDER} />
        </Form.Item>
        <Link to="/auth">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-full font-medium"
          >
            {CommonLabels.SAVE}
          </Button>
        </Link>
        <Button
          type="link"
          size="large"
          className="w-full font-medium mt-1"
          onClick={onCancel}
        >
          {CommonLabels.CANCEL}
        </Button>
      </Form>
    </Flex>
  );
};

export default ChangePasswordView;
