import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';

const BackContainer = ({
  title,
  onBack,
}: {
  title: string;
  onBack?: () => void;
}) => {
  return (
    <Flex
      gap="gap-2"
      onClick={() => {
        if (onBack) {
          onBack();
        }
      }}
    >
      <Icon name="BackIcon" className="cursor-pointer" />
      <Text variant="plg" color="text-black">
        {title}
      </Text>
    </Flex>
  );
};
export default BackContainer;
