import { useMutation, useQuery } from '@tanstack/react-query';
import { apiInstance, axiosProspectInstance } from 'settings/AxiosSetting';

export const useGenerateEmails = () =>
  useMutation(
    (data: {
      prospect_id?: string;
      linkedin_url?: string;
      email_address?: string;
      insights?: any;
      email_length?: string | number;
    }) => {
      return axiosProspectInstance.post('generate-emails', data);
    },
  );

export const useGetInsights = () =>
  useMutation((data: { prospect_id?: string; api_for?: string }) => {
    return axiosProspectInstance.post('prospect-insight', data);
  });

export const useIsGetEmailWritingStyles = () => {
  return useQuery({
    queryKey: ['isGetEmailWritingStyles'],
    queryFn: () => {
      return apiInstance.get('user/writingStyleTemplates?templateType=3');
    },
  });
};
