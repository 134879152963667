import React from 'react';
import Flex from 'components/Flex';
import { Icon, IconNameType } from 'components/Icon';
import { Button, Checkbox, Typography } from 'components/Ant';
import Text from 'components/Text';

const MessageCard = ({
  icon,
  messageTitle,
  messageDescription,
  confirmationMessage,
  buttonTitle,
  onCheckBoxChange,
  onButtonClick,
}: {
  icon: IconNameType;
  messageTitle;
  messageDescription;
  confirmationMessage;
  buttonTitle;
  onCheckBoxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
}) => {
  return (
    <Flex
      backgroundColor="bg-white"
      padding="py-5 px-6"
      rounded="rounded-md"
      className="shadow-card"
      gap="gap-4"
      direction="col"
      alignItems="start"
    >
      <Flex direction="row" gap="gap-4" alignItems="start">
        <Flex
          padding="p-3"
          rounded=" rounded-full"
          backgroundColor="bg-emerald-100"
          alignItems="start"
        >
          <Icon name={icon} className=" text-emerald-800 w-6 h-6" />
        </Flex>

        <Flex backgroundColor="#fff" direction="col" alignItems="start">
          <Text
            variant="title"
            color="text-gray-900"
            position="center"
            className=" leading-5 text-base font-medium"
          >
            {messageTitle}
          </Text>

          <Text
            variant="title"
            color="text-gray-500"
            position="center"
            className=" leading-4 text-sm font-normal mt-2"
          >
            {messageDescription}
          </Text>

          <Flex
            backgroundColor="#fff"
            direction="row"
            gap="gap-2"
            alignItems="center"
            className="mt-3"
          >
            <Checkbox
              onChange={(event: any) => {
                onCheckBoxChange?.(event); // Using optional chaining
              }}
            />

            <Text
              variant="title"
              color="text-gray-500"
              position="center"
              className=" leading-4 text-sm font-normal"
            >
              {confirmationMessage}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Button
        type="primary"
        size="large"
        block
        onClick={onButtonClick}
        className="mt-2"
      >
        <Typography.Paragraph ellipsis className="text-white mb-0">
          {buttonTitle}
        </Typography.Paragraph>
      </Button>
    </Flex>
  );
};
export default MessageCard;
