import { useContext } from 'react';
import { AuthLabels, CommonLabels, ForgotPasswordLabels } from 'consts/label';
import { Button, Form, Input } from 'components/Ant';
import Flex from 'components/Flex';
import Text from 'components/Text';
import BackContainer from 'components/BackContainer';
import { ForgotPasswordContext } from './forgotPassword.context';

const AccountDetails = () => {
  const { onCancel, forgotPasswordForm, onForgotPassword } = useContext(
    ForgotPasswordContext,
  );

  return (
    <Flex direction="col" width="w-full" padding="p-5">
      <BackContainer
        title={ForgotPasswordLabels.FORGOT_PASSWORD}
        onBack={() => {
          onCancel();
        }}
      />
      <Text color="text-textPrimary" className=" text-base" padding="py-5">
        {ForgotPasswordLabels.FORGOT_PASSWORD_DESCRIPTION}
      </Text>
      <Form
        name="createAccount"
        layout="vertical"
        className="w-full"
        form={forgotPasswordForm}
        onFinish={onForgotPassword}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { message: AuthLabels.EMAIL_PLACEHOLDER, required: true },
            {
              type: 'email',
              message: AuthLabels.EMAIL_VALIDATION_MESSAGE,
            },
          ]}
        >
          <Input placeholder={AuthLabels.EMAIL_PLACEHOLDER} />
        </Form.Item>
        <Button
          type="primary"
          // htmlType="submit"
          onClick={onForgotPassword}
          size="large"
          className="w-full font-medium"
        >
          {CommonLabels.SEND}
        </Button>
        <Button
          type="link"
          size="large"
          className="w-full font-medium mt-1"
          onClick={onCancel}
        >
          {CommonLabels.CANCEL}
        </Button>
      </Form>
    </Flex>
  );
};

export default AccountDetails;
