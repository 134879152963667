import React from 'react';
import { ChildrenProps } from 'types/custom';
import ForgotPasswordService from './forgotPassword.service';

export const ForgotPasswordContext = React.createContext<any>('');

export const ForgotPasswordProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const stateService = ForgotPasswordService();

  return (
    <ForgotPasswordContext.Provider value={stateService}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};
