import ContactApp from 'pages/contact';
import { Route } from 'react-router-dom';
// import OutReachApp from 'pages/contact/outReach';

const ContactRoutes = () => {
  return (
    <>
      <Route index element={<ContactApp />} />
      {/* <Route index element={<OutReachApp />} /> */}
    </>
  );
};
export default ContactRoutes;
