import React from 'react';
import { ChildrenProps } from 'types/custom';
import AboutViewService from './about.service';

export const AboutViewContext = React.createContext<any>('');

export const AboutViewProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = AboutViewService();

  return (
    <AboutViewContext.Provider value={stateService}>
      {children}
    </AboutViewContext.Provider>
  );
};
