import { AuthLabels } from 'consts/label';
import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';

const stringLength = /^.{8,}$/;
const containNumber = /^(?=.*\d).*$/;
const containSpecialCharacter = /^(?=.*[!"#$%&'()*+,./:;<=>?@[\\\]^_{|}-]).*$/;

const PasswordCriteriaComponent = ({
  newpassword,
}: {
  newpassword: string;
}) => {
  return (
    <>
      <Flex>
        {newpassword ? (
          <Icon
            name={
              stringLength.test(newpassword)
                ? 'PasswordTickIcon'
                : 'CloseIconRed'
            }
            className={`mr-1 ${
              stringLength.test(newpassword)
                ? 'text-green-500 mt-1  w-2.5 h-2.5'
                : 'text-red mt-1.5 '
            }`}
          />
        ) : (
          <Flex
            width="w-1"
            height="h-1"
            backgroundColor="bg-secondary"
            margin="mx-2 mt-2"
            className="opacity-90"
            rounded="rounded-md"
          />
        )}
        <Text
          variant="psm"
          weight={500}
          color={`${
            newpassword
              ? stringLength.test(newpassword)
                ? 'text-green-500'
                : 'text-red'
              : 'text-slate-500'
          }`}
        >
          {AuthLabels.MINIMUM_8}
        </Text>
      </Flex>
      <Flex>
        {newpassword ? (
          <Icon
            name={
              containNumber.test(newpassword)
                ? 'PasswordTickIcon'
                : 'CloseIconRed'
            }
            className={`mr-1 ${
              containNumber.test(newpassword)
                ? 'text-green-500 mt-1  w-2.5 h-2.5'
                : 'text-red mt-1.5 '
            }`}
          />
        ) : (
          <Flex
            width="w-1"
            height="h-1"
            backgroundColor="bg-secondary"
            margin="mx-2 mt-2"
            className="opacity-90"
            rounded="rounded-md"
          />
        )}
        <Text
          variant="psm"
          weight={500}
          color={`${
            newpassword
              ? containNumber.test(newpassword)
                ? 'text-green-500'
                : 'text-red'
              : 'text-slate-500'
          }`}
        >
          {AuthLabels.CONTAIN_ONE_NUMBER}
        </Text>
      </Flex>
      <Flex>
        {newpassword ? (
          <Icon
            name={
              containSpecialCharacter.test(newpassword)
                ? 'PasswordTickIcon'
                : 'CloseIconRed'
            }
            className={`mr-1 ${
              containSpecialCharacter.test(newpassword)
                ? 'text-green-500 mt-1  w-2.5 h-2.5'
                : 'text-red mt-1.5 '
            }`}
          />
        ) : (
          <Flex
            width="w-1"
            height="h-1"
            backgroundColor="bg-secondary"
            margin="mx-2 mt-2"
            className="opacity-90"
            rounded="rounded-md"
          />
        )}
        <Text
          variant="psm"
          weight={500}
          color={`${
            newpassword
              ? containSpecialCharacter.test(newpassword)
                ? 'text-green-500'
                : 'text-red'
              : 'text-slate-500'
          }`}
        >
          {AuthLabels.CONTAIN_SPL_CHARACTER}
        </Text>
      </Flex>
    </>
  );
};

export default PasswordCriteriaComponent;
