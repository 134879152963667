import { useContext, useEffect, useState } from 'react';
import NavContext from 'context/NavContext';
// eslint-disable-next-line import/no-relative-parent-imports
import { ContactContext } from '../contact.context';
// eslint-disable-next-line import/no-relative-parent-imports
import { useGetCompanyInsights } from '../insights/insights.query';
import {
  useGenerateEmails,
  useGetInsights,
  useIsGetEmailWritingStyles,
} from './actions.query';

export default function ActionsService() {
  const {
    userInfoData,
    fetchUserData,
    handleUnlockCredit,
    isGetUserInfoLoading,
  } = useContext(ContactContext);
  const customerNav = useContext(NavContext);
  const [isShowContext, setIsShowContext] = useState(false);
  const [isGenerateEmailLoading, setIsGenerateEmailLoading] = useState(false);
  const [accordionList, setAccordionList] = useState<any>([]);
  const [isShowSuggestions, setIsShowSuggestions] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [isGenerateEmailContextLoading, setIsGenerateEmailContextLoading] =
    useState(false);
  const [isInsightsLoading, setIsInsightsLoading] = useState(false);
  const [isCompanyInsightsLoading, setIsCompanyInsightsLoading] =
    useState(false);

  const onResponseError = (error: any) => {
    if (error?.response?.status === 404) {
      fetchUserData();
    }
  };

  const {
    mutate: onGenerateEmailContext,
    isLoading: isGenerateEmailAPILoading,
  } = useGenerateEmails();

  const {
    mutate: onGetInsights,
    isLoading: isInsightsAPILoading,
    // data: { data: insightsData } = {},
  } = useGetInsights();
  const {
    mutate: onGetCompanyInsights,
    isLoading: isCompanyInsightsAPILoading,
    // data: { data: insightsData } = {},
  } = useGetCompanyInsights();

  const handleTrailUnlockCredit = (func) => {
    if (!userInfoData?.user_prospect?.credit) {
      handleUnlockCredit(func);
    } else {
      func();
    }
  };
  const { data: { data: { data: getAllEmailWritingStyles = [] } = {} } = {} } =
    useIsGetEmailWritingStyles();
  useEffect(() => {
    if (!isGetUserInfoLoading && !isGenerateEmailAPILoading) {
      setIsGenerateEmailContextLoading(false);
    }
    if (!isGetUserInfoLoading && !isInsightsAPILoading) {
      setIsInsightsLoading(false);
    }
    if (!isCompanyInsightsAPILoading && !isGetUserInfoLoading) {
      setIsCompanyInsightsLoading(false);
    }
  }, [
    isGetUserInfoLoading,
    isGenerateEmailAPILoading,
    isInsightsAPILoading,
    isCompanyInsightsAPILoading,
  ]);
  const fetchInsights = () => {
    onGetInsights(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        api_for: 'Icebreaker',
      },
      {
        onSuccess: () => {
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };
  const fetchPersonInsights = () => {
    setIsInsightsLoading(true);
    handleTrailUnlockCredit(fetchInsights);
  };

  const getFetchCompanyInsights = () => {
    onGetCompanyInsights(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        api_for: 'Icebreaker',
      },
      {
        onSuccess: () => {
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };

  const fetchCompanyInsights = () => {
    setIsCompanyInsightsLoading(true);
    handleTrailUnlockCredit(getFetchCompanyInsights);
  };

  const onEditEmailContext = (values) => {
    const personTitleList = (values.insights?.prospect_insight || []).map(
      (person) => person?.title as string,
    );
    const orgTitleList = (values.insights?.organization_insight || []).map(
      (person) => person?.title as string,
    );
    setIsShowContext(true);
    const personInsights = userInfoData?.prospect_insight
      .map((org, orgIndex) => {
        if (personTitleList.includes(org.title)) {
          return `${orgIndex}`;
        }
        return '';
      })
      .filter((org) => org !== '');
    const companyInsights =
      userInfoData?.orignization_data?.organization_insight
        .map((org, orgIndex) => {
          if (orgTitleList.includes(org.title)) {
            return `${orgIndex}`;
          }
          return '';
        })
        .filter((org) => org !== '');
    const formDataContext = {
      emailLength: values.email_length,
      addContext: !!values.additional_context,
      prospect_insight: personTitleList?.length ? personInsights : [],
      organization_insight: orgTitleList?.length ? companyInsights : [],
      insights: {
        prospect_insight: personInsights,
        organization_insight: companyInsights,
      },
      addMoreContext: values.additional_context,
      selectedEmail: values.selectedEmail,
    };
    setAccordionList([!!personTitleList?.length, !!orgTitleList?.length]);
    setInsightsData(formDataContext?.insights);
    values.addContextForm.setFieldsValue(formDataContext);
  };

  const onUnlockGenerateMessage = (values) => {
    setIsGenerateEmailLoading(true);
    const EmailWritingStyle = getAllEmailWritingStyles.find(
      (emailData: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return values.writing_style
          ? emailData.id === values.writing_style
          : emailData.is_default;
      },
    );
    let payload = {};
    payload = {
      linkedin_url: customerNav?.currentURL,
      // generated_by_user: true,
      // insights: {},
      // email_length: 'short',
      insight: values.insights,
      email_content: values.additional_context,
      email_length: values.email_length,
      prospect_id: userInfoData?.user_prospect?.prospect_id,
      writing_style: EmailWritingStyle,
      email_context: {
        email_type: 'cold_email',
        additional_context: '',
      },
    };
    if (values.type === 'email') {
      onGenerateEmailContext(payload, {
        onSuccess: () => {
          const targetElement = document.getElementById('tabContainer');
          targetElement?.scrollIntoView({ behavior: 'smooth' });
          fetchUserData();
          setIsShowContext(false);
          if (values.form) {
            values.form.resetFields();
          }
        },
        onError(error) {
          onResponseError(error);
        },
      });
    }
  };
  const onGenerateMessages = (values) => {
    setIsGenerateEmailContextLoading(true);
    handleTrailUnlockCredit(() => onUnlockGenerateMessage(values));
  };

  const onGenerateSequence = () => {
    window.open(
      `${process.env.REACT_APP_WEB_APP_URL}prospects?url=${customerNav?.currentURL}&user-prospect=${userInfoData?.user_prospect?.prospect_id}`,
    );
  };

  return {
    isLoadingInsights: isInsightsLoading,
    userInfoData,
    fetchCompanyInsights,
    fetchPersonInsights,
    isLoadingCompanyInsights: isCompanyInsightsLoading,
    isGenerateEmailLoading,
    onGenerateMessages,
    isShowContext,
    setIsShowContext,
    isGenerateEmailContextLoading,
    onEditEmailContext,
    setAccordionList,
    accordionList,
    userProspectData: {},
    isShowSuggestions,
    setIsShowSuggestions,
    insightsData,
    onGenerateSequence,
    getAllEmailWritingStyles,
  };
}
