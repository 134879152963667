import { ReactNode } from 'react';
import Flex from 'components/Flex';
import { Icon, IconNameType } from 'components/Icon';
import Text from 'components/Text';
import { Skeleton } from 'components/Ant';

const hasSpaceWithinLength = (str, length) => {
  if (typeof str !== 'string' || typeof length !== 'number' || length <= 0) {
    return false;
  }
  const substring = str.slice(0, length);
  return substring.includes(' ');
};

const UserInfoComponent = ({
  icon,
  name,
  rightChildren,
  isLoading,
  placeholder,
}: {
  icon: IconNameType;
  name: string | ReactNode;
  rightChildren?: ReactNode;
  isLoading?: boolean;
  placeholder?: string;
}) => {
  return (
    <Flex
      margin="mb-3"
      backgroundColor="bg-green"
      justify="between"
      width="w-full"
    >
      <Flex>
        <Icon name={icon} className="text-secondary flex-shrink-0" />
        {isLoading && !name && (
          <Flex margin="ml-3">
            <Skeleton.Input size="small" active className="mr-1" />
          </Flex>
        )}
        {!isLoading && !name && (
          <Text
            variant="psm"
            margin="mr-1 ml-3 m-0"
            color=" text-tertiary"
            className="whitespace-pre-line"
          >
            {placeholder}
          </Text>
        )}
        {name && (
          <>
            {typeof name === 'string' ? (
              <Text
                variant="psm"
                color="text-textPrimary"
                margin=" ml-3 mr-1 m-0"
                className={`whitespace-break-spaces ${
                  hasSpaceWithinLength(name, 24) ? 'break-words' : 'break-all'
                } `}
              >
                {name}
              </Text>
            ) : (
              <Flex
                margin="ml-3 mr-1 m-0"
                className="text-textPrimary  whitespace-break-spaces"
                flexWrap="wrap"
                gap="gap-y-1"
              >
                {name}
              </Flex>
            )}
          </>
        )}
      </Flex>
      {rightChildren && rightChildren}
    </Flex>
  );
};

export default UserInfoComponent;
