export const CommonLabels = {
  SAVE: 'Save',
  NEXT: 'Next',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CLOSE: 'Close',
  UPLOAD_CSV: 'Upload CSV',
  INVITE_USERS: 'Invite Users',
  CREATE_ACCOUNT: 'Create Account',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account? ',
  LOGIN: 'Log in',
  DONE: 'Done',
  CANCEL: 'Cancel',
  CHOOSE_YOUR_ROUTE: 'Choose your route',
  ADD_A_COMPANY: 'Add a company',
  GET_YOUR_COMPANY_ON_THE_FLYWHEEL: 'Get your company on the flywheel',
  ASK_THE_ADMIN_TTO_INVITE_YOU: 'Ask the admin to invite you',
  ADMIN_DESCRIPTION:
    'To join a existing company, please ask the Happysales admin to invite you',
  DIDNT_GET_YOUR_EMAIL: "Didn't you get the email?",
  ADD: 'Add',
  SEND: 'Send',
  ASSIGN: 'Assign',
  VIEW_ALL: 'View all',
  LAST_UPDATE_ON: 'Last updated on',
  ADDED_ON: 'Added on',
  FILTER: 'Filter',
  MANAGE_COLUMNS: 'Manage Columns',
  SEARCH: 'Search',
  GET_STARTED: 'Get Started',
  ACTIONS: 'Actions',
  SKIP: 'Skip',
  RETRY_TEXT:
    "Sorry! It looks like we're missing some information to share that data right now. Please try again later or reach out to our support team for assistance",
  RETRY_ERROR_TEXT:
    "Looks like we're experiencing an issue in fetching the information you're looking for. Please try again later or reach out to our support team for assistance. Thank you for your patience!",
  TRY_AGAIN: 'Try again',
  LEARN_MORE: 'Learn more',
};

export const emailLabels = {};
