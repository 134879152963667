export const avatarMap = (name: string, maxInitials: number) => {
  return name
    .split(/\s/)
    .map((part) => {
      return part.slice(0, 1).toUpperCase();
    })
    .filter((v) => {
      return !!v;
    })
    .slice(0, maxInitials)
    .join('');
};

export const dummyFunc = () => {};

interface QueryParams {
  token?: string;
  companyDetails?: string;
}

export const getQueryParams = (): QueryParams => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const queryParams: QueryParams = {};

  params.forEach((value, key) => {
    queryParams[key as keyof QueryParams] = value;
  });

  return queryParams;
};

export const passTokenToWebapp = (token) => {
  window.parent.postMessage({ type: 'extensionLogin', token }, '*');
};

export const captilizeFirst = (str: string) => {
  return (str || '').replace(/^\w/, (c) => c.toUpperCase());
};

export const formatUserCount = (userCount) => {
  if (userCount >= 1000000) {
    return `${(userCount / 1000000).toFixed(1)}M`;
  }
  if (userCount >= 1000) {
    return `${(userCount / 1000).toFixed(1)}K`;
  }
  return `${userCount || ''}`;
};
