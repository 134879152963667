import React from 'react';
import { ChildrenProps } from 'types/custom';
import ActionsService from './actions.service';

export const ActionsContext = React.createContext<any>('');

export const ActionsProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = ActionsService();

  return (
    <ActionsContext.Provider value={stateService}>
      {children}
    </ActionsContext.Provider>
  );
};
