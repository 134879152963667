import {
  AuthLabels,
  ForgotPasswordLabels,
  ChangePasswordLabels,
  SettingsLabel,
} from './pages/auth';
import {
  ContactLabels,
  ActionsLabels,
  InsightsLabels,
  DISCLabels,
  UpgradeLabels,
} from './pages/contact';
import { OnboardingLabels } from './pages/onboarding';
import { CommonLabels } from './common';
import { EmailConversationLabels } from './pages/email';

const en = {
  AuthLabels,
  ForgotPasswordLabels,
  ChangePasswordLabels,
  SettingsLabel,
  ContactLabels,
  ActionsLabels,
  InsightsLabels,
  OnboardingLabels,
  CommonLabels,
  DISCLabels,
  UpgradeLabels,
  EmailConversationLabels,
};

export default en;
