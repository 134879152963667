import { useMutation, useQuery } from '@tanstack/react-query';
import { apiInstance } from 'settings/AxiosSetting';

export const useCompanyDetails = () =>
  useMutation(
    (data: {
      company_name: string;
      about_company: string;
      example_email: string;
      company_website: string;
      language: string | null;
    }) => {
      return apiInstance.post('user/companyDetail', data);
    },
  );

export const useCustomerDetail = () =>
  useMutation(
    (data: {
      customer_wins: string;
      top_customers: string;
      top_competitors: string;
    }) => {
      return apiInstance.post('user/customerDetail', data);
    },
  );

export const useGetCompanyDetails = (userid: string) => {
  return useQuery({
    queryKey: ['getCompanyDetails', userid],
    queryFn: () => {
      return apiInstance.get('/user/userDetails');
    },
    refetchOnReconnect: 'always',
  });
};

export const useEditCompanyDetails = () =>
  useMutation(
    (data: {
      company_name: string;
      about_company: string;
      example_email: string;
      company_website: string;
      language: string | null;
      customer_wins: string;
      top_customers: string;
      top_competitors: string;
    }) => {
      return apiInstance.post('user/inputs', data);
    },
  );

export const useUpdateProfileInfo = () =>
  useMutation((data: { name: string; id: number }) => {
    return apiInstance.post('user/updatePersonalInfo', data);
  });
