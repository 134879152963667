/* eslint-disable @typescript-eslint/restrict-plus-operands */
// import { Popover } from 'components/Ant';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Lottie from 'lottie-react';
import groovyWalkAnimation from 'assests/json/refreshAnimation.json';
import { ContactLabels } from 'consts/label';
import { UpgradeLabels } from 'consts/label/en-US/pages/contact';
import { Button, Modal, Popover, Tooltip } from 'components/Ant';
import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';
import Loading from 'components/Loading';

type UpgradeType = {
  type: 'success' | 'error' | 'warning';
  creditCount?: string | number;
  isLoadingRefetch?: boolean;
  onRefreshProspect?: () => void;
  setIsShowRefresh: (val: boolean) => void;
  isShowRefresh: boolean;
  subscription?: string;
  isGuide?: boolean;
  currentTour?: number;
  isOpen?: boolean;
};

const TabLabel = ({ icon, iconClassName, title }) => {
  return (
    <Text
      variant="span"
      weight={500}
      padding="px-4 py-2"
      className="group text-secondary hover:text-primary min-w-[200px] cursor-pointer"
    >
      <Flex justify="start" className=" font-medium" alignItems="center">
        {icon && (
          <Icon
            name={icon}
            className={`mr-2 flex-shrink-0 ${iconClassName} `}
          />
        )}
        {title}
      </Flex>
    </Text>
  );
};

const UpgradeView = (props: UpgradeType) => {
  const {
    type,
    creditCount,
    isLoadingRefetch,
    onRefreshProspect,
    setIsShowRefresh,
    isShowRefresh,
    subscription,
    isGuide,
    isOpen,
    currentTour,
  } = props;

  const [isLoadingMoreTime, setIsLoadingMoreTime] = useState(false);
  useEffect(() => {
    if (isLoadingRefetch)
      setTimeout(() => {
        setIsLoadingMoreTime(true);
      }, 8000);
    else {
      setIsLoadingMoreTime(false);
    }
  }, [isLoadingRefetch]);

  const location = useLocation();

  const items = (
    <Flex direction="col" className=" min-w-[200px]">
      {location?.pathname === '/contact' && (
        <div id="UpgradeRefreshIcon">
          <Flex
            onClick={() => {
              setIsShowRefresh(true);
            }}
          >
            <TabLabel icon="HelpIcon" title="Refresh" iconClassName="mr-2" />
          </Flex>
        </div>
      )}
      <div className=" border-0 border-solid border-b border-gray-100 " />
      <Link to="/settings" className=" flex">
        <TabLabel icon="SettingsIcon" title="Settings" iconClassName="" />
      </Link>
    </Flex>
  );

  return (
    <Flex
      padding="px-5 py-2.5"
      rounded="rounded-md"
      backgroundColor={`${type === 'success' ? 'bg-purple-100' : ''} ${
        type === 'warning' ? 'bg-yellow-100' : ''
      } ${type === 'error' ? 'bg-red-500' : ''} `}
      width="w-full"
      justify="between"
      alignItems="center"
    >
      <Tooltip
        placement="top"
        title={
          subscription === 'Trial'
            ? 'You are on trial plan'
            : ContactLabels.CREDIT_TOOLTIP
        }
      >
        <span>
          <Flex className=" gap-1.5 cursor-pointer" alignItems="center">
            <Icon
              name="CreditIcon"
              className={`${type === 'success' ? 'text-primary' : ''} ${
                type === 'warning' ? 'text-yellow-800' : ''
              } ${type === 'error' ? 'text-white' : ''}`}
            />
            <Text
              variant="psm"
              weight={500}
              color={`${type === 'success' ? 'text-primary' : ''} ${
                type === 'warning' ? 'text-yellow-800' : ''
              } ${type === 'error' ? 'text-white' : ''}`}
            >
              {(creditCount || 0) === 0 ? 'No' : creditCount}{' '}
              {`credit${Number(creditCount) <= 1 ? '' : 's'} remaining ${
                subscription === 'Trial' ? '' : 'for the week'
              }`}
            </Text>
          </Flex>
        </span>
      </Tooltip>

      <Popover
        content={items}
        placement="bottomRight"
        arrow={false}
        overlayInnerStyle={{ padding: 0 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isGuide && currentTour && currentTour > 3 && { open: isOpen })}
        overlayClassName={`${isOpen ? 'tour-dropdown' : ''}`}
      >
        <div
          id="UpgradeShowMoreIcon"
          className=" flex items-center justify-center -mr-2 cursor-pointer"
        >
          <Icon name="MoreIcon" className=" text-primary w-5 h-5" />
        </div>
      </Popover>
      <Modal
        width="90%"
        open={isShowRefresh}
        closeIcon={<></>}
        onCancel={() => {
          setIsShowRefresh(false);
        }}
        footer={null}
        className=" z-[1040]"
      >
        <Flex direction="col" alignItems="center">
          {isLoadingRefetch && (
            <>
              <Lottie animationData={groovyWalkAnimation} />
              <Text
                variant="psm"
                color=" text-secondary"
                className=" leading-5"
                position="center"
              >
                {isLoadingMoreTime
                  ? UpgradeLabels.REFRESH_TITLE_SOON
                  : UpgradeLabels.REFRESH_TITLE_LOADING}
              </Text>
            </>
          )}
          {!isLoadingRefetch && (
            <>
              <Flex
                padding="p-3"
                rounded=" rounded-full"
                backgroundColor="bg-purple-100"
              >
                <Icon name="RefreshIcon" className=" text-purple-500 w-6 h-6" />
              </Flex>
              <Text
                variant="plg"
                color=" text-textPrimary"
                weight={500}
                className=" leading-6"
                margin="mt-3 mb-2"
                position="center"
              >
                {UpgradeLabels.REFRESH_TITLE}
              </Text>
              <Text
                variant="psm"
                color=" text-secondary"
                className=" leading-5"
                position="center"
              >
                {UpgradeLabels.REFRESH_DESCRIPTION}
              </Text>
              <Button
                type="primary"
                block
                size="large"
                className={`w-full flex justify-center items-center gap-3 mt-4 mb-3 ${
                  isLoadingRefetch ? 'opacity-80' : ''
                }`}
                onClick={() => {
                  if (onRefreshProspect) onRefreshProspect();
                }}
              >
                {isLoadingRefetch && <Loading />}

                {UpgradeLabels.REFRESH_BUTTON}
              </Button>
              <Button
                block
                size="large"
                onClick={() => {
                  setIsShowRefresh(false);
                }}
              >
                {UpgradeLabels.DISMISS_BUTTON}
              </Button>
            </>
          )}
        </Flex>
      </Modal>
    </Flex>
  );
};

export default UpgradeView;
