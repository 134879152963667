import React, { useContext, useEffect, useRef, useState } from 'react';
import CreditPointsContext from 'context/CreditContext';
import { InsightsLabels } from 'consts/label';
import Accordion from 'components/Accordion';
import FailsEmptyState from 'components/FailsEmptyState';
import Flex from 'components/Flex';
import { InsightLoading } from 'components/Page/Insights';
import SummarizeCard from 'components/SummarizeCard';
import { Icon } from 'components/Icon';
import { Tag } from 'components/Ant';
import CompanyEmptyState from 'components/CompanyEmptyState';
import Text from 'components/Text';
import { InsightsContext } from './insights.context';

const KeyChallenges = ({
  title,
  data,
}: {
  title: string;
  data: {
    key_challenge: string;
    data_source: string[];
    date: string;
    title?: string;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
    >
      <Accordion title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && (
        <>
          <div className=" pl-0 mb-0 list-none mt-5 flex flex-col gap-4">
            {data.map((points, pointIndex) => {
              return (
                <div
                  key={`keychallenges-${pointIndex}`}
                  className={`${
                    data?.length === pointIndex + 1
                      ? ''
                      : 'border-b border-solid border-0 border-gray-100 pb-4'
                  }`}
                >
                  <Tag
                    className=" text-yellow-800 bg-yellow-100 font-medium rounded-3xl px-2.5 py-0.5 mb-4 whitespace-pre-line"
                    bordered={false}
                  >
                    {points.title || `Key Challenges  • ${pointIndex + 1}`}
                  </Tag>
                  <div
                    className=" text-sm text-gray-900 font-normal bg-white"
                    key={`points-${pointIndex}`}
                  >
                    {points.key_challenge || ''}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </Flex>
  );
};

const TalkingPoints = ({
  title,
  data,
}: {
  title: string;
  data: {
    talking_point: string;
    data_source: string[];
    date: string;
    title?: string;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const searchParam = new URLSearchParams(window.location.search);

  const isWebApp = searchParam.get('isWebApp');

  return (
    <Flex
      padding="p-4"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
      backgroundColor="bg-white"
    >
      <Accordion title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && (
        <>
          <div className=" pl-0 mb-0 list-none mt-5 flex flex-col gap-4">
            {data.map((points, pointIndex) => {
              if (points?.title)
                return (
                  <div
                    key={`pitch-${pointIndex}`}
                    className={`${
                      isWebApp && data?.length === pointIndex + 1
                        ? ''
                        : 'border-b border-solid border-0 border-gray-100 pb-4'
                    } `}
                  >
                    <Tag
                      className=" text-green-800 bg-green-100 font-medium rounded-3xl px-2.5 py-0.5 mb-4 whitespace-pre-line"
                      bordered={false}
                    >
                      {points.title || `Key Challenges  • ${pointIndex + 1}`}
                    </Tag>
                    <div
                      className=" text-sm text-gray-900 font-normal rounded-md "
                      key={`points-${pointIndex}`}
                    >
                      {points.talking_point}{' '}
                    </div>
                  </div>
                );
              return <React.Fragment key={`pitch-${pointIndex}`} />;
            })}
          </div>
        </>
      )}
    </Flex>
  );
};

const KeyChallengesBlock = () => {
  const { userInfo } = useContext(CreditPointsContext);
  const { userInfoData, isLoadingKeyChallenges, fetchKeyChallenges } =
    useContext(InsightsContext);
  return (
    <div>
      {!userInfo?.company_details?.about_company && <CompanyEmptyState />}
      {userInfo?.company_details?.about_company && (
        <>
          {!userInfoData?.user_prospect?.used_key_challenges &&
            !isLoadingKeyChallenges && (
              <SummarizeCard
                icon="PitchIcon"
                cardType="tag"
                cardData={[]}
                description={
                  <>
                    AI-driven{' '}
                    <Text variant="span" color="text-textPrimary" weight={400}>
                      talking points
                    </Text>{' '}
                    for clear, concise, and compelling conversations{' '}
                  </>
                }
                buttonText="Generate Pitch"
                onButtonClick={() => {
                  fetchKeyChallenges();
                }}
                loading={isLoadingKeyChallenges}
              />
            )}
          {isLoadingKeyChallenges && (
            <>
              <InsightLoading title={InsightsLabels.LOADING_KEY_CHALLENGES} />
              <Flex margin="mt-5">
                <InsightLoading title={InsightsLabels.ANALYSE_TALKING_POINTS} />
              </Flex>
            </>
          )}
          {!!userInfoData?.user_prospect?.key_challenges?.length &&
            userInfoData?.user_prospect?.used_key_challenges && (
              <KeyChallenges
                title={`${InsightsLabels.KEYCHALLENGES_FOR_ROLE}`}
                data={userInfoData?.user_prospect?.key_challenges || []}
              />
            )}
        </>
      )}
    </div>
  );
};

const TalkingPointsBlock = () => {
  const { userInfoData, isLoadingKeyChallenges } = useContext(InsightsContext);
  return (
    <>
      {!!userInfoData?.user_prospect?.talking_points.filter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (data: any) => data.title,
      )?.length && (
        <>
          <TalkingPoints
            title={`${InsightsLabels.TALKING_POINTS_FOR} ${
              userInfoData?.first_name || ''
            }`}
            data={userInfoData?.user_prospect?.talking_points || []}
          />
        </>
      )}

      {userInfoData?.user_prospect?.key_challenges?.length === 0 &&
        userInfoData?.user_prospect?.talking_points.filter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (data: any) => data.title,
        )?.length === 0 &&
        userInfoData?.user_prospect?.used_key_challenges &&
        !isLoadingKeyChallenges && (
          <>
            <FailsEmptyState />
          </>
        )}
    </>
  );
};

const PitchView = () => {
  const { userInfoData } = useContext(InsightsContext);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // e.target.classList.toggle('hidden', e.intersectionRatio < 1)
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.boundingClientRect?.top > 0) {
          setIsVisible(e.isIntersecting);
        }
      },
      { threshold: [1] },
    );
    if (containerRef && containerRef.current)
      observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef]);

  return (
    <>
      <div>
        <KeyChallengesBlock />
        {/* Talking points or Pitch */}
        {!!userInfoData?.user_prospect?.talking_points.filter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (data: any) => data.title,
        )?.length &&
          !!userInfoData?.user_prospect?.key_challenges?.length &&
          userInfoData?.user_prospect?.used_key_challenges && (
            <div className=" fixed bottom-3 z-10 left-0 inline-flex items-center justify-center w-full">
              <div
                className={` bg-green-500 rounded-full px-4 py-2 text-white font-inter font-medium text-base flex items-center shadow-card cursor-pointer ${
                  isVisible ? 'opacity-0 pointer-events-none' : 'opacity-100'
                } duration-300`}
                onClick={() => {
                  if (document && document.getElementById('pitch')) {
                    document.getElementById('pitch')?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }}
                role="presentation"
              >
                <div>Pitch</div>
                <Icon
                  name="PitchArrowIcon"
                  className=" flex-shrink-0 w-6 h-6"
                />
              </div>
            </div>
          )}
        <div ref={containerRef} id="pitch" className="mt-5" />
        {userInfoData?.user_prospect?.used_key_challenges && (
          <TalkingPointsBlock />
        )}
      </div>
    </>
  );
};
export default PitchView;
