import { useContext, useEffect } from 'react';
import NavContext from 'context/NavContext';
import { useNavigate } from 'react-router-dom';
import { OnboardingLabels } from 'consts/label';
import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';

const Onboarding = () => {
  const customerNav = useContext(NavContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (customerNav?.isGmailPage) {
      navigate('/email');
    } else if (customerNav?.isProfile) {
      navigate('/contact');
    }
  }, [
    customerNav?.currentURL,
    customerNav?.isProfile,
    customerNav?.isGmailPage,
  ]);

  return (
    <Flex gap="gap-1" direction="col">
      <Icon name="OnboardingIllustration" className=" w-3/4 mx-auto" />
      <Flex
        backgroundColor="bg-white"
        rounded=" rounded-md"
        padding=" pt-6 p-5"
        className=" shadow-card"
        gap="gap-4"
        direction="col"
      >
        <Text variant="h1" weight={500} color="text-textPrimary">
          {OnboardingLabels.NAVIGATE_TO_LINKEDIN}
          <br />
          {OnboardingLabels.PROFILE_PAGE}
        </Text>

        <Flex direction="col">
          <Text variant="psm" color="text-textPrimary">
            {OnboardingLabels.HAPPYSALES_HELPS}
          </Text>
          <ul className="m-0 pl-4">
            {OnboardingLabels.HAPPYSALES_HELPS_DATA.map((data, dataIndex) => {
              return (
                <li key={`Onboarding-${dataIndex}`}>
                  <Text variant="psm" color="text-textPrimary">
                    {data}
                  </Text>
                </li>
              );
            })}
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Onboarding;
