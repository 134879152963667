import { useContext } from 'react';
import { CommonLabels } from 'consts/label';
import { Tabs } from 'components/Ant';
import Flex from 'components/Flex';
import TabLabel from 'components/TabLabel';
import SummarizeCard from 'components/SummarizeCard';
import FailsEmptyState from 'components/FailsEmptyState';
import { ContactContext } from './contact.context';
import Insights from './insights';
import Actions from './actions';
import AboutView from './about';

const ContactView = () => {
  const {
    selectedTab,
    setSelectedTab,
    userInfoData,
    isLoadingUserInfo,
    handleUnlockCredit,
    isCreditPointsLoading,
    creditPoints,
    isUserError,
    setIsShowRefresh,
    isLoadingRetryProspectRefetch,
    loggedUserInfo,
    isAuthUserInfoLoading,
  } = useContext(ContactContext);

  const tabItems = [
    {
      label: (
        <div id="ContactTabAction">
          {' '}
          <TabLabel icon="ActionsIcon" title="Actions" />
        </div>
      ),
      key: 'leads',
      children: <Actions />,
    },
    {
      label: (
        <div id="ContactTabInsights" className="px-1 pr-2">
          <TabLabel
            icon="IceBreakers"
            title="Insights"
            iconClassName="w-5 h-5"
          />
        </div>
      ),
      key: 'people',
      children: <Insights />,
    },
  ];

  return (
    <Flex gap="gap-5" width="w-full" direction="col">
      {isUserError && (
        <FailsEmptyState
          title={CommonLabels.RETRY_ERROR_TEXT}
          name="WarningImage"
          onRetry={() => {
            setIsShowRefresh(true);
          }}
          isLoading={isLoadingRetryProspectRefetch}
        />
      )}
      {!isUserError && (
        <>
          <AboutView />

          {loggedUserInfo?.subscription_plan === 'Trial' &&
            (isLoadingUserInfo || isAuthUserInfoLoading) && (
              <>
                <SummarizeCard
                  icon="LockIcon"
                  cardType="tag"
                  cardData={[
                    'Profile Summary',
                    'Icebreakers',
                    'Key Challenges',
                    'Pitch Points',
                    'Personalized Emails',
                  ]}
                  description="Explore the Insights and Actions tab and turn complex data into straightforward, actionable steps for your business."
                  buttonText="Unlock for 1 credit"
                  onButtonClick={() => {
                    handleUnlockCredit();
                  }}
                  cardLoading
                  loading
                  disabled={creditPoints?.available_credits <= 0}
                />
              </>
            )}

          {((!userInfoData?.user_prospect?.credit &&
            loggedUserInfo?.subscription_plan !== 'Trial') ||
            isAuthUserInfoLoading) && (
            <SummarizeCard
              icon="LockIcon"
              cardType="tag"
              cardData={[
                'Profile Summary',
                'Icebreakers',
                'Key Challenges',
                'Pitch Points',
                'Personalized Emails',
              ]}
              description="Explore the Insights and Actions tab and turn complex data into straightforward, actionable steps for your business."
              buttonText="Unlock for 1 credit"
              onButtonClick={() => {
                handleUnlockCredit();
              }}
              loading={
                isLoadingUserInfo ||
                isCreditPointsLoading ||
                isAuthUserInfoLoading
              }
              disabled={creditPoints?.available_credits <= 0}
            />
          )}

          {userInfoData?.first_name &&
            (userInfoData?.user_prospect?.credit ||
              loggedUserInfo?.subscription_plan === 'Trial') &&
            !isAuthUserInfoLoading && (
              <div id="tabContainer">
                <Flex className="w-full sticky top-0" direction="col">
                  <Tabs
                    items={tabItems}
                    activeKey={selectedTab}
                    onChange={(activeKey) => {
                      setSelectedTab(activeKey);
                    }}
                    className="w-full insightsTab"
                    centered
                  />
                </Flex>
              </div>
            )}
        </>
      )}
    </Flex>
  );
};

export default ContactView;
