import { CommonLabels } from 'consts/label';
import Text from 'components/Text';
import Flex from 'components/Flex';
import { Icon, IconNameType } from 'components/Icon';
import { Button } from 'components/Ant';
import Loading from 'components/Loading';

const FailsEmptyState = ({
  title = '',
  name = 'BlockErrorImage',
  onRetry,
  isLoading,
}: {
  title?: string;
  name?: IconNameType;
  onRetry?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Flex
      direction="col"
      justify="center"
      alignItems="center"
      gap="gap-2"
      padding="p-4"
      rounded="rounded-md"
      width="w-full"
      className=" shadow-card"
      backgroundColor="bg-white"
    >
      <div className="mt-4">
        <Icon name={name} />
      </div>
      <Text variant="psm" position="center" className="mt-2">
        {title || CommonLabels.RETRY_TEXT}
      </Text>
      {onRetry && (
        <Button type="link" onClick={onRetry}>
          <Flex justify="center" alignItems="center" gap="gap-2">
            {isLoading && <Loading />}
            Retry
          </Flex>
        </Button>
      )}
    </Flex>
  );
};
export default FailsEmptyState;
