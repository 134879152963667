import en from './en-US';
import fr from './fr-FR';

const locales = {
  'en-US': en,
  'fr-FR': fr,
};
const currentLocale = localStorage.locale || 'en-US';
export const AuthLabels = locales[currentLocale].AuthLabels;
export const ForgotPasswordLabels = locales[currentLocale].ForgotPasswordLabels;
export const ChangePasswordLabels = locales[currentLocale].ChangePasswordLabels;
export const SettingsLabel = locales[currentLocale].SettingsLabel;
export const ContactLabels = locales[currentLocale].ContactLabels;
export const ActionsLabels = locales[currentLocale].ActionsLabels;
export const InsightsLabels = locales[currentLocale].InsightsLabels;
export const OnboardingLabels = locales[currentLocale].OnboardingLabels;
export const CommonLabels = locales[currentLocale].CommonLabels;
export const EmailConversationLabels =
  locales[currentLocale].EmailConversationLabels;
