import React from 'react';
import ActionsView from './actions.view';
import { ActionsProvider } from './actions.context';

const Actions = () => {
  return (
    <ActionsProvider>
      <ActionsView />
    </ActionsProvider>
  );
};
export default Actions;
