import React, { CSSProperties, LegacyRef } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import {
  FlexJustifyType,
  FlexPositionType,
  FlexAlignItemsType,
  FlexWrapType,
  FlexDirectionType,
} from 'types/application';
import {
  JUSTIFY_VARIANTS,
  DIRECTION_VARIANTS,
  ALIGN_ITEMS_VARIANTS,
  FLEX_WRAP_VARIANTS,
} from './variants';

const Flex = ({
  direction = 'row',
  justify = 'start',
  position = 'relative',
  width = 'w-auto',
  margin = 'm-0',
  padding = 'p-0',
  space,
  border = 'border-0',
  borderColor = 'border-gray-100',
  backgroundColor = 'bg-transparent',
  maxWidth,
  minHeight,
  maxHeight,
  height = 'h-auto',
  className,
  alignItems = null,
  rounded,
  flexWrap = null,
  children,
  customStyles,
  onClick,
  gap = '',
  ref,
}: {
  ref?: LegacyRef<any>;
  direction?: FlexDirectionType;
  justify?: FlexJustifyType;
  position?: FlexPositionType;
  alignItems?: FlexAlignItemsType;
  flexWrap?: FlexWrapType;
  backgroundColor?: string;
  width?: string;
  margin?: string;
  padding?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  className?: string;
  rounded?: string;
  space?: string | null;
  border?: string;
  borderColor?: string;
  maxHeight?: string;
  children?: ReactNode;
  customStyles?: CSSProperties;
  onClick?: () => void;
  gap?: string;
}) => {
  const baseClass = 'flex';
  const FLEX_DIRECTION = DIRECTION_VARIANTS[
    direction
  ] as keyof FlexDirectionType;
  const FLEX_JUSTIFY = JUSTIFY_VARIANTS[justify];
  const ALIGN_ITEMS = alignItems ? ALIGN_ITEMS_VARIANTS[alignItems] : null;
  const FLEX_WRAP = flexWrap ? FLEX_WRAP_VARIANTS[flexWrap] : null;

  const customClass = classNames(
    baseClass,
    FLEX_DIRECTION,
    FLEX_JUSTIFY,
    backgroundColor,
    position,
    `${width}`,
    `${height}`,
    `${margin}`,
    padding,
    space,
    border,
    borderColor,
    rounded,
    ALIGN_ITEMS,
    FLEX_WRAP,
    maxWidth ? `${maxWidth}` : null,
    minHeight ? `${minHeight}` : null,
    maxHeight ? `${maxHeight}` : null,
    gap,
    className,
  );

  return (
    // eslint-disable-next-line react/forbid-dom-props, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={customClass}
      // eslint-disable-next-line react/forbid-dom-props
      style={customStyles}
      onClick={() => {
        if (onClick) onClick();
      }}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default React.memo(Flex);
