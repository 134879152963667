/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useContext, useMemo, useState } from 'react';
import { ActionsLabels } from 'consts/label';
import Flex from 'components/Flex';
import Text from 'components/Text';
import TabLabel from 'components/TabLabel';
import SummarizeCard from 'components/SummarizeCard';
import IcebreakersView from './icebreakers.view';
import EmailsView from './emails.view';
import { ActionsContext } from './actions.context';

const ActionsView = () => {
  const [activeKey, setActiveKey] = useState('emails');
  const { onGenerateSequence } = useContext(ActionsContext);
  const tabList = useMemo(() => {
    return [
      {
        key: 'emails',
        label: (
          <TabLabel
            icon="EmailIcon"
            small
            title={ActionsLabels.WRITE_AN_EMAIL}
            iconClassName="w-5 h-5"
          />
        ),
      },
      {
        key: 'icebreakers',
        label: (
          <TabLabel
            icon="IcebreaksActionIcon"
            small
            title={ActionsLabels.ICE_BREAKERS}
            iconClassName="w-6 h-6"
          />
        ),
      },
      {
        key: 'sequence',
        label: (
          <TabLabel
            icon="SequenceIcon"
            small
            title={ActionsLabels.SEQUENCE}
            iconClassName="w-5 h-5"
          />
        ),
      },
    ];
  }, []);

  return (
    <Flex direction="col">
      <Flex
        alignItems="center"
        justify="between"
        width="w-full"
        className="sticky top-11 z-[2] bg-white2 insights-container"
        margin="mb-4"
      >
        {tabList.map((tab) => {
          return (
            <Flex
              key={tab.key}
              padding="py-2"
              onClick={() => {
                setActiveKey(tab.key);
              }}
              className={` cursor-pointer font-medium ${
                activeKey === tab.key ? 'text-primary' : ' text-secondary'
              }`}
            >
              {tab.label}
            </Flex>
          );
        })}
      </Flex>
      <Flex direction="col" margin="mx-1">
        {activeKey === 'icebreakers' && <IcebreakersView />}
        {activeKey === 'emails' && <EmailsView />}
        {activeKey === 'sequence' && (
          <>
            <SummarizeCard
              icon="SequenceIcon"
              cardData={[]}
              cardType="tag"
              description={
                <>
                  Engage your prospects with a
                  <Text
                    variant="span"
                    color="text-textPrimary"
                    weight={400}
                    margin="ml-1"
                  >
                    multi-touch point sequence
                  </Text>
                  {/* <Text
                    className="mt-4"
                    color="text-textPrimary"
                    position="center"
                  >
                    Coming soon
                  </Text> */}
                </>
              }
              buttonText="Generate Sequence"
              onButtonClick={() => {
                onGenerateSequence();
              }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
export default ActionsView;
