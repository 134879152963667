import CreateAccountApp from 'pages/auth/createAccount';
import AboutView from 'pages/auth/AboutView';
import ForgotPasswordApp from 'pages/auth/forgotPassword';
import ChangePasswordView from 'pages/auth/forgotPassword/changePassword.view';
import VerifyEmailView from 'pages/auth/forgotPassword/verifyEmail.view';
import Onboarding from 'pages/onboarding';
import { Route, Routes, useNavigate } from 'react-router-dom';
import OutReachApp from 'pages/contact/outReach';
// import PreferredLang from 'pages/auth/PreferredLanguage';
import useGoogleAnalytics from 'utils/useGoogleAnalytics';
import VerificationPage from 'pages/auth/createAccount/verificationPage.view';
import { useContext, useEffect } from 'react';
import { refreshToken } from 'settings/AxiosSetting';
import NavContext from 'context/NavContext';
import EmailApp from 'pages/email';
import AuthNav from 'components/Nav/AuthNav';
import Flex from 'components/Flex';
import ContactRoutes from './contact.routes';

const RouteWrapper = ({ children }) => {
  return (
    <Flex padding="p-5" direction="col">
      {children}
    </Flex>
  );
};

const AppRoutes = () => {
  useGoogleAnalytics();
  const navigate = useNavigate();
  const customerNav = useContext(NavContext);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.token) {
        // if (!localStorage.getItem('token')) {
        localStorage.setItem('token', event.data.token);
        // }
        refreshToken();
        if (event.data.token && localStorage.getItem('token')) {
          if (customerNav?.isGmailPage) {
            navigate('/email');
          } else if (customerNav?.isProfile) {
            navigate('/contact');
          } else {
            navigate('/onboarding');
          }
        }
        // setUserToken(event.data.token);
        // window.location.reload();
      }
      if (event && event.data && event.data.type === 'logout') {
        // localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('userid');
        refreshToken();
        navigate('/');

        // if (localStorage.getItem('language')) {
        //   navigate('/auth');
        // } else {
        //   navigate('/');
        // }
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, [customerNav.isProfile, customerNav.isGmailPage]);
  return (
    <Routes>
      {/* <Route
        path="/"
        element={
          <RouteWrapper>
            <PreferredLang />
          </RouteWrapper>
        }
      /> */}
      <Route
        path="/"
        element={
          <RouteWrapper>
            <CreateAccountApp />
          </RouteWrapper>
        }
      />
      <Route
        path="/auth"
        element={
          <RouteWrapper>
            <CreateAccountApp />
          </RouteWrapper>
        }
      />
      <Route
        path="/get-started"
        element={
          <RouteWrapper>
            <AboutView />
          </RouteWrapper>
        }
      />
      <Route
        path="/create-account"
        element={
          <RouteWrapper>
            <AboutView showCompetitors />
          </RouteWrapper>
        }
      />
      <Route path="/verification-page" element={<VerificationPage />} />
      <Route path="/outreach-sequence" element={<OutReachApp />} />
      <Route path="/forgot-password" element={<ForgotPasswordApp />} />
      <Route path="/verify-email" element={<VerifyEmailView />} />
      <Route path="/change-password" element={<ChangePasswordView />} />
      <Route
        path="/onboarding"
        element={
          <AuthNav>
            <Onboarding />
          </AuthNav>
        }
      />
      <Route path="/email" element={<EmailApp />} />
      <Route path="/settings" element={<AboutView isSettingsPage />} />
      <Route path="/settings" element={<AboutView isSettingsPage />} />
      <Route path="/contact" element={<AuthNav />}>
        {ContactRoutes()}
      </Route>
    </Routes>
  );
};
export default AppRoutes;
