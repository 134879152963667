import { useContext } from 'react';
import { CommonLabels, AuthLabels } from 'consts/label';
import { Input, Form, Button, Divider, InputPassword } from 'components/Ant';
import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';
import { CreateAccountContext } from './createAccount.context';

const LoginView = () => {
  const { loginform, onLoginAccount, handleGoogleLogin } =
    useContext(CreateAccountContext);
  return (
    <Flex justify="between" alignItems="center" margin="m-auto" direction="col">
      <Button
        className="font-medium mt-2 flex items-center justify-center"
        block
        size="large"
        onClick={handleGoogleLogin}
      >
        <Text
          variant="psm"
          position="center"
          color="text-textPrimary"
          className=" flex items-center justify-center"
        >
          <Icon name="GoogleLogo" className=" mr-2" />
          Continue with Google
        </Text>
      </Button>
      <Divider className=" border-silver my-6">
        <Text variant="span" color="text-secondary">
          OR
        </Text>
      </Divider>
      <Form
        name="createAccount"
        layout="vertical"
        className="w-full"
        form={loginform}
        onFinish={onLoginAccount}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { message: AuthLabels.EMAIL_PLACEHOLDER, required: true },
            {
              type: 'email',
              message: AuthLabels.EMAIL_VALIDATION_MESSAGE,
            },
          ]}
          // validateStatus={isLoginError ? 'error' : ''}
          // help={isLoginError ? isLoginErrorMsg.email || '' : null}
        >
          <Input placeholder={AuthLabels.EMAIL_PLACEHOLDER} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              message: AuthLabels.PASSWORD_PLACEHOLDER,
              required: true,
            },
            // {
            //   message: AuthLabels.PASSWORD_VALIDATION,
            //   pattern: new RegExp('^(?=.*[A-Za-z0-9]).{8,}$'),
            // },
          ]}
          className="mb-0"
          // validateStatus={isLoginError ? 'error' : ''}
          // hasFeedback
          // help={isLoginError ? isLoginErrorMsg.password || '' : null}
        >
          <InputPassword placeholder={AuthLabels.PASSWORD_PLACEHOLDER} />
        </Form.Item>
        <Flex justify="end">
          <Text
            margin="my-4"
            position="right"
            weight={500}
            color="text-textPrimary"
            variant="psm"
            className=" cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEB_APP_URL}forgot-password`,
              );
            }}
          >
            {AuthLabels.FORGOT_PASSWORD}
          </Text>
        </Flex>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="w-full font-medium"
        >
          {CommonLabels.LOGIN}
        </Button>
      </Form>
      {/* <Divider className=" border-silver">
        <Text variant="span" color="text-secondary">
          Or
        </Text>
      </Divider>

      <Button
        className="font-medium mt-2 flex items-center justify-center"
        block
        size="large"
        onClick={handleGoogleLogin}
      >
        <Text variant="psm" position="center" color="text-textPrimary">
          <Icon name="GoogleLogo" className="relative top-1 mr-2" />
          Continue with google
        </Text>
      </Button> */}
    </Flex>
  );
};

export default LoginView;
