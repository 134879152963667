// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { ReactNode } from 'react';

type ChildrenProps = {
  children: ReactNode;
};

export const axiosProspectInstance = axios.create({
  baseURL: process.env.REACT_APP_PROSPECT_API, // Replace with your API base URL
  // timeout: 10000, // Set a timeout
});

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API, // Replace with your API base URL
  timeout: 10000, // Set a timeout
});

export const axiosAiApiInstance = axios.create({
  baseURL: process.env.REACT_APP_AI_API, // Replace with your API base URL
});

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API, // Replace with your API base URL
  timeout: 10000, // Set a timeout
});

export const refreshToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    axiosProspectInstance.defaults.headers.common.Authorization = `${token}`;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiInstance.defaults.headers.common.Authorization = `${token}`;
  }
};
refreshToken();
axiosProspectInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (resError) => {
    try {
      if (!navigator.onLine) {
        return Promise.reject(resError);
      }
      return Promise.reject(resError);
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

// all useQuery will go through this fetcher fn unless explicitly written
const defaultQueryFn = async ({ queryKey }: { queryKey: any }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(`${queryKey[0]}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  } catch {
    return { error: 'Something went wrong' };
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const ReactQueryClientProvider = ({ children }: ChildrenProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryClientProvider;
