import React from 'react';
import { ChildrenProps } from 'types/custom';
import CreateAccountService from './createAccount.service';

export const CreateAccountContext = React.createContext<any>('');

export const CreateAccountProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const stateService = CreateAccountService();

  return (
    <CreateAccountContext.Provider value={stateService}>
      {children}
    </CreateAccountContext.Provider>
  );
};
