import { AboutViewProvider } from './about.context';
import AboutViewView from './about.view';

const AboutViewApp = ({ isSettingsPage = false, showCompetitors = false }) => {
  return (
    <AboutViewProvider>
      <AboutViewView
        isSettingsPage={isSettingsPage}
        showCompetitors={showCompetitors}
      />
    </AboutViewProvider>
  );
};

export default AboutViewApp;
