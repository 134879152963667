// eslint-disable-next-line import/no-namespace
import * as Icons from './Icons';

const components = {
  Dashboard: Icons.Dashboard,
  Logo: Icons.Logo,
  CloseIcon: Icons.CloseIcon,
  GoogleLogo: Icons.GoogleLogo,
  BackIcon: Icons.BackIcon,
  SettingsIcon: Icons.SettingsIcon,
  OnboardingIllustration: Icons.OnboardingIllustration,
  CreditIcon: Icons.CreditIcon,
  ArrowIcon: Icons.ArrowIcon,
  CompanyIcon: Icons.CompanyIcon,
  DesignationIcon: Icons.DesignationIcon,
  EmailIcon: Icons.EmailIcon,
  EnrichIcon: Icons.EnrichIcon,
  InsightIcon: Icons.InsightIcon,
  ActionsIcon: Icons.ActionsIcon,
  TechStackIcon: Icons.TechStackIcon,
  AmazonLogo: Icons.AmazonLogo,
  LinkedIn: Icons.LinkedIn,
  WebLinkLogo: Icons.WebLinkLogo,
  PhoneLogo: Icons.PhoneLogo,
  TickIcon: Icons.TickIcon,
  OutreachSequenceIcon: Icons.OutreachSequenceIcon,
  SalesloftIcon: Icons.SalesloftIcon,
  SearchOutlinedIcon: Icons.SearchOutlinedIcon,
  FilterIcon: Icons.FilterIcon,
  CopyIcon: Icons.CopyIcon,
  MoreIcon: Icons.MoreIcon,
  LogoutIcon: Icons.LogoutIcon,
  ChevronIcon: Icons.ChevronIcon,
  PersonIcon: Icons.PersonIcon,
  PitchIcon: Icons.PitchIcon,
  IceBreakers: Icons.IceBreakers,
  PasswordTickIcon: Icons.PasswordTickIcon,
  CloseIconRed: Icons.CloseIconRed,
  WebsiteIcon: Icons.WebsiteIcon,
  CompanyUserInfoIcon: Icons.CompanyUserInfoIcon,
  LinkedInFollowersIcon: Icons.LinkedInFollowersIcon,
  LocationIcon: Icons.LocationIcon,
  RevenueIcon: Icons.RevenueIcon,
  SassIcon: Icons.SassIcon,
  LockIcon: Icons.LockIcon,
  PersonInsightIcon: Icons.PersonInsightIcon,
  ViewInsightIcon: Icons.ViewInsightIcon,
  SuggestionIcon: Icons.SuggestionIcon,
  DiscIcon: Icons.DiscIcon,
  SequenceIcon: Icons.SequenceIcon,
  PitchArrowIcon: Icons.PitchArrowIcon,
  CheckOutlined: Icons.CheckOutlined,
  CloseOutlined: Icons.CloseOutlined,
  ErrorImage: Icons.ErrorImage,
  BlockErrorImage: Icons.BlockErrorImage,
  HelpIcon: Icons.HelpIcon,
  RefreshIcon: Icons.RefreshIcon,
  IcebreaksActionIcon: Icons.IcebreaksActionIcon,
  SpeakerIcon: Icons.SpeakerIcon,
  SendMailIcon: Icons.SendMailIcon,
  SmileIcon: Icons.SmileIcon,
  SmileNeutralIcon: Icons.SmileNeutralIcon,
  LinkIcon: Icons.LinkIcon,
  SmileSadIcon: Icons.SmileSadIcon,
  newsIcon: Icons.newsIcon,
  WarningImage: Icons.WarningImage,
  YoutubeIcon: Icons.YoutubeIcon,
  EmailWritingStyleIcon: Icons.EmailWritingStyleIcon,
};
export type IconNameType = keyof typeof components;

type Props = {
  name: IconNameType;
  className?: string;
};

export const Icon = ({ name, className = '' }: Props) => {
  const IconComponent = components[name];
  return <IconComponent className={className} />;
};
