import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CreditPointsContext from 'context/CreditContext';
import { Input, Select } from 'antd';
import { ActionsLabels, InsightsLabels } from 'consts/label';
import { InsightLoading } from 'components/Page/Insights';
import Text from 'components/Text';
import SummarizeCard from 'components/SummarizeCard';
import CompanyEmptyState from 'components/CompanyEmptyState';
import Flex from 'components/Flex';
import {
  Checkbox,
  Form,
  Modal,
  Tag,
  Textarea,
  Tooltip,
  Typography,
  Button,
  Skeleton,
} from 'components/Ant';
import { Icon } from 'components/Icon';
import Copy from 'components/Icon/Copy';
import Loading from 'components/Loading';
import Accordion from 'components/Accordion';
import Grid from 'components/Grid';
import FailsEmptyState from 'components/FailsEmptyState';
// eslint-disable-next-line import/no-relative-parent-imports
import { ContactContext } from '../contact.context';
import { ActionsContext } from './actions.context';

const EmailDescriptionLoading = () => {
  return (
    <Flex margin="mt-3" direction="col">
      <Flex justify="between" className="group/email cursor-pointer">
        <Skeleton.Input size="small" active />
      </Flex>
      <Flex margin="mt-3">
        <Skeleton.Input size="small" className="w-full" active />
      </Flex>
    </Flex>
  );
};

const EmailContainerLoading = () => {
  return (
    <Flex width="w-full" direction="col">
      <Flex justify="between" width="w-full">
        <Skeleton.Input size="small" active />
        <Skeleton.Avatar size="small" active />
      </Flex>
      <Flex
        margin="mt-4"
        padding="p-4"
        border="border border-solid"
        rounded="rounded-md"
        borderColor=" border-[#EBEBEB]"
        direction="col"
      >
        <Flex margin=" -mt-3" />
        <EmailDescriptionLoading />
        <EmailDescriptionLoading />
      </Flex>
    </Flex>
  );
};

// const EmailLengthButton = ({ isActive, label, onClick }) => {
//   return (
//     <Flex
//       width="w-full"
//       alignItems="center"
//       justify="center"
//       padding="py-1"
//       border="border border-solid"
//       rounded="rounded-md"
//       backgroundColor={isActive ? 'bg-primary' : 'bg-white'}
//       borderColor={isActive ? 'border-primary' : 'border-tertiary'}
//       onClick={onClick}
//       className=" cursor-pointer"
//     >
//       <Text
//         className="text-center"
//         variant="psm"
//         weight={500}
//         color={isActive ? 'text-white' : 'text-textPrimary'}
//       >
//         {label}
//       </Text>
//     </Flex>
//   );
// };

const AddContext = ({
  open,
  onCancel,
  type,
  insightData,
  onGenerate,
  isLoading,
  addContextForm,
  isEdit,
}) => {
  const {
    userInfoData,
    accordionList,
    isShowSuggestions,
    setIsShowSuggestions,
    insightsData,
  } = useContext(ActionsContext);
  const [accordionOpen, setAccordionOpen] = useState<boolean[]>(accordionList);
  // const emailLength = Form.useWatch('emailLength', addContextForm);
  const selectedEmail = Form.useWatch('selectedEmail', addContextForm);
  const insightsInfoFormData = Form.useWatch('insights', addContextForm);
  const insightsInfo = isEdit ? insightsData : insightsInfoFormData;
  useEffect(() => {
    setAccordionOpen(accordionList);
  }, [accordionList]);
  const ContextData = useMemo(() => {
    const personInsights = userInfoData?.user_prospect?.used_prospect_insight
      ? (userInfoData?.prospect_insight || []).map((person) => {
          return {
            tag: person.category,
            description: person.insight || person.title,
            data: person,
          };
        })
      : [];
    const companyInsights = userInfoData?.user_prospect
      ?.used_organization_insight
      ? (userInfoData?.orignization_data?.organization_insight || []).map(
          (organization) => {
            return {
              tag: organization.category,
              description: organization.insight || organization.title,
              data: organization,
            };
          },
        )
      : [];
    // insightsData?.insights?.organization
    return [
      {
        title: 'Person Insights • ',
        dataIndex: 'prospect_insight',
        data: personInsights,
      },
      {
        title: 'Company Insights • ',
        dataIndex: 'organization_insight',
        data: companyInsights,
      },
    ];
  }, [userInfoData]);
  useEffect(() => {
    if (!accordionList?.length && insightData?.emails?.status !== 'done') {
      setAccordionOpen(ContextData.map(() => false));
    }
  }, [ContextData, accordionList]);
  return (
    <Modal
      width="100%"
      open={open}
      closeIcon={<></>}
      onCancel={onCancel}
      maskClosable={!isLoading}
      footer={null}
    >
      <Text color="text-textPrimary" className="mb-4 mt-0" weight={500}>
        {isEdit
          ? `${ActionsLabels.EDIT_CONTEXT_SELECT} • ${selectedEmail || ''}`
          : ActionsLabels.ADD_CONTEXT_SELECT}
      </Text>
      <Form
        name="createAccount"
        layout="vertical"
        className="w-full"
        form={addContextForm}
        initialValues={{
          insights: {
            prospect_insight: [],
            organization_insight: [],
          },
        }}
        autoComplete="off"
        onFinish={(values) => {
          const modifiedVal = values;
          modifiedVal.form = addContextForm;
          const insights = values.insights;
          const personInsights: any = [];
          const companyInsights: any = [];
          (userInfoData?.prospect_insight || []).map(
            // eslint-disable-next-line array-callback-return
            (perons_insights, insightsIndex) => {
              if (
                (insights?.prospect_insight || []).includes(`${insightsIndex}`)
              ) {
                personInsights.push(perons_insights);
              }
            },
          );
          (userInfoData?.orignization_data?.organization_insight || []).map(
            // eslint-disable-next-line array-callback-return
            (org_insights, insightsIndex) => {
              if (
                (insights?.organization_insight || []).includes(
                  `${insightsIndex}`,
                )
              ) {
                companyInsights.push(org_insights);
              }
            },
          );
          modifiedVal.insights = {
            prospect_insight: personInsights,
            organization_insight: companyInsights,
          };
          onGenerate(modifiedVal);
        }}
        disabled={isLoading}
      >
        <Form.Item name="selectedEmail" hidden>
          <Input />
        </Form.Item>
        {(userInfoData?.user_prospect?.used_prospect_insight ||
          userInfoData?.user_prospect?.used_organization_insight) && (
          <>
            <Form.Item
              name="insights"
              // rules={[
              //   {
              //     message: ActionsLabels.INSIGHTS_REQUIRED,
              //     validator: (_, value) => {
              //       if (
              //         value.prospect_insight.length > 0 ||
              //         value.organization_insight.length > 0
              //       ) {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(ActionsLabels.INSIGHTS_REQUIRED);
              //     },
              //   },
              // ]}
              className="!mb-2"
              hidden={
                isEdit &&
                insightsInfo?.prospect_insight?.length === 0 &&
                insightsInfo?.organization_insight?.length === 0
              }
            >
              {/* eslint-disable-next-line consistent-return, array-callback-return */}
              {ContextData.map((data, dataIndex) => {
                const insightUserData =
                  data.dataIndex === 'prospect_insight'
                    ? insightsInfo?.prospect_insight
                    : insightsInfo?.organization_insight;
                if (data?.data?.length)
                  return (
                    <Flex
                      width="w-full"
                      key={`addContext-${dataIndex}`}
                      direction="col"
                      margin={`mt-0 ${
                        dataIndex + 1 !== ContextData.length ? `mb-3` : `mb-0`
                      }`}
                    >
                      {((isEdit && insightUserData?.length) || !isEdit) && (
                        <Accordion
                          title={`${data.title} ${
                            insightUserData?.length && !isEdit
                              ? `${insightUserData?.length} of ${data.data.length}`
                              : isEdit
                              ? insightUserData?.length
                              : `${data.data.length}`
                          } `}
                          isOpen={accordionOpen[dataIndex]}
                          setIsOpen={() => {
                            const modifiedIsOpen = [...accordionOpen];
                            modifiedIsOpen[dataIndex] =
                              !accordionOpen[dataIndex];
                            setAccordionOpen(modifiedIsOpen);
                          }}
                          titleClassName=" !text-gray-900"
                        />
                      )}
                      {isEdit && accordionOpen[dataIndex] && (
                        <Flex margin="mt-2.5" direction="col">
                          {data.data.map((insight, insightIndex) => {
                            if (insightUserData?.includes(`${insightIndex}`))
                              return (
                                <Flex
                                  key={`Insights-data-${dataIndex}-${insightIndex}`}
                                  direction="col"
                                >
                                  <Flex>
                                    <Tag
                                      className=" text-green-800 bg-green-100 rounded-3xl px-2.5 py-0.5 inline-block w-auto"
                                      bordered={false}
                                    >
                                      <Text variant="pxs">{insight.tag}</Text>
                                    </Tag>
                                  </Flex>
                                  <Text
                                    margin="mt-1"
                                    variant="psm"
                                    color="text-secondary"
                                  >
                                    {insight.description}
                                  </Text>
                                </Flex>
                              );
                            return <></>;
                          })}
                        </Flex>
                      )}
                      {accordionOpen[dataIndex] && !isEdit && (
                        <Flex margin="mt-2.5" direction="col">
                          <Form.Item
                            name={data.dataIndex}
                            // valuePropName="checked"
                            className="mb-0"
                          >
                            <Checkbox.Group
                              onChange={(checkedValue) => {
                                const formVals =
                                  addContextForm.getFieldsValue();
                                const modifiedInsightData = {
                                  ...formVals.insights,
                                };
                                if (data.dataIndex === 'prospect_insight') {
                                  modifiedInsightData.prospect_insight =
                                    checkedValue;
                                } else {
                                  modifiedInsightData.organization_insight =
                                    checkedValue;
                                }
                                addContextForm.setFieldsValue({
                                  insights: modifiedInsightData,
                                });
                              }}
                            >
                              {data.data.map((insight, insightIndex) => {
                                return (
                                  <Flex
                                    margin="mt-2.5"
                                    key={`insight-${dataIndex}-${insightIndex}`}
                                  >
                                    <Checkbox
                                      value={`${insightIndex}`}
                                      className=" self-start"
                                      disabled={isEdit}
                                    >
                                      <Tag
                                        className=" text-green-800 bg-green-100 rounded-3xl px-2.5 py-0.5"
                                        bordered={false}
                                      >
                                        <Text variant="pxs">{insight.tag}</Text>
                                      </Tag>
                                      <Text
                                        margin="mt-1"
                                        variant="psm"
                                        color="text-secondary"
                                      >
                                        {insight.description}
                                      </Text>
                                    </Checkbox>
                                  </Flex>
                                );
                              })}
                            </Checkbox.Group>
                          </Form.Item>
                        </Flex>
                      )}
                    </Flex>
                  );
              })}
            </Form.Item>

            <Flex
              border="border-0 border-solid border-t"
              borderColor="border-gray-100"
              margin="mb-6"
              className={`${
                isEdit &&
                insightsInfo?.prospect_insight?.length === 0 &&
                insightsInfo?.organization_insight?.length === 0
                  ? 'hidden'
                  : ''
              }`}
            />
          </>
        )}
        {/* <Form.Item
          name="addContext"
          valuePropName="checked"
          className={`${addMoreContext ? 'mb-2.5' : 'mb-5'}`}
        >
          <Checkbox className=" self-start">
            <Tag
              className=" text-gray-800 bg-gray-100 rounded-3xl px-2.5 py-0.5"
              bordered={false}
            >
              <Text variant="pxs">{ActionsLabels.ADD_CONTEXT}</Text>
            </Tag>
            <Text variant="psm" color=" text-tertiary">
              {ActionsLabels.ADD_CONTEXT_DESCRIPTION}
            </Text>
          </Checkbox>
        </Form.Item> */}
        <Flex justify="between" margin="mb-2.5">
          <Text weight={500} color=" text-gray-900">
            {ActionsLabels.ADD_CONTEXT}
          </Text>
          {/* <Flex
            onClick={() => {
              setIsShowSuggestions(true);
            }}
            gap="gap-1.5"
            alignItems="center"
            className=" cursor-pointer"
          >
            <Icon name="SuggestionIcon" className=" text-primary" />
            <Text variant="psm" color="text-primary">
              {ActionsLabels.SUGGESTIONS}
            </Text>
          </Flex> */}
        </Flex>
        <Form.Item
          name="addMoreContext"
          // label={ActionsLabels.ADD_CONTEXT}
          rules={[
            {
              required:
                !insightsInfo?.prospect_insight?.length &&
                !insightsInfo?.organization_insight?.length,
              message: ActionsLabels.ADD_CONTEXT_REQUIRED,
            },
          ]}
        >
          <Textarea
            placeholder={ActionsLabels.CONTEXT_TEXTAREA_PLACEHOLDER}
            disabled={isEdit}
          />
        </Form.Item>
        {/* {type === 'email' && (
          <>
            <Text margin="mb-2.5 mt-0" variant="psm" color=" text-textPrimary">
              {ActionsLabels.EMAIL_LENGTH}
            </Text>
            <Form.Item
              name="emailLength"
              rules={[
                {
                  required: true,
                  message: ActionsLabels.EMAIL_LENGTH_REQUIRED,
                },
              ]}
            >
              <Flex gap="gap-2.5">
                <EmailLengthButton
                  label={ActionsLabels.EMAIL_LENGTH_SHORT}
                  isActive={emailLength === 'short'}
                  onClick={() => {
                    addContextForm.setFieldValue('emailLength', 'short');
                  }}
                />

                <EmailLengthButton
                  label={ActionsLabels.EMAIL_LENGTH_MEDIUM}
                  isActive={emailLength === 'medium'}
                  onClick={() => {
                    addContextForm.setFieldValue('emailLength', 'medium');
                  }}
                />

                <EmailLengthButton
                  label={ActionsLabels.EMAIL_LENGTH_LARGE}
                  isActive={emailLength === 'long'}
                  onClick={() => {
                    addContextForm.setFieldValue('emailLength', 'long');
                  }}
                />
              </Flex>
            </Form.Item>
          </>
        )} */}
        {isEdit ? (
          <Button
            block
            ghost
            type="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </Button>
        ) : (
          <Button block ghost type="primary" htmlType="submit">
            {isLoading && (
              <Flex justify="center" alignItems="center">
                <Loading />
              </Flex>
            )}
            {!isLoading && (
              <>
                {`${
                  type === 'email'
                    ? ActionsLabels.GENERATE_EMAIL
                    : ActionsLabels.GENERATE_MESSAGE
                }`}
              </>
            )}
          </Button>
        )}
      </Form>
      <Modal
        width="100%"
        open={isShowSuggestions}
        closeIcon={<></>}
        onCancel={() => {
          setIsShowSuggestions(false);
        }}
        footer={null}
      >
        <Text color="text-textPrimary" className="mb-4 mt-0" weight={500}>
          {ActionsLabels.SUGGESTIONS}
        </Text>
        {[
          { title: 'Write an email in spanish' },
          { title: 'Write an apology email for being late' },
          { title: 'Write a professional email to a client' },
        ].map((suggestion, suggestionIndex) => {
          return (
            <Flex
              gap="gap-1"
              direction="col"
              key={`suggestion-${suggestionIndex}`}
              className=" mb-4"
            >
              <Flex justify="between" className="group/suggestions">
                <Tag
                  className=" text-green-800 bg-green-100 rounded-3xl px-2.5 py-0.5"
                  bordered={false}
                >
                  Suggestion {suggestionIndex + 1}
                </Tag>
                <Text
                  color=" text-primary"
                  variant="psm"
                  className=" invisible group-hover/suggestions:visible cursor-pointer"
                  onClick={() => {
                    addContextForm.setFieldValue(
                      'addMoreContext',
                      suggestion.title,
                    );
                    setIsShowSuggestions(false);
                  }}
                >
                  Apply
                </Text>
              </Flex>
              <Text
                color=" text-gray-500"
                variant="psm"
                weight={400}
                className=" leading-5"
              >
                {suggestion.title}
              </Text>
            </Flex>
          );
        })}
        <Button
          block
          ghost
          type="primary"
          onClick={() => {
            setIsShowSuggestions(false);
          }}
        >
          Close
        </Button>
      </Modal>
    </Modal>
  );
};

const EmailDescription = ({
  title,
  description,
  isCopyable = false,
  copyableText = '',
}) => {
  return (
    <Flex margin="mt-3" direction="col">
      <Flex justify="between" className="group/email">
        <Text
          variant="psm"
          color="!text-tertiary"
          weight={500}
          className=" flex-shrink-0"
        >
          {title}
        </Text>
        <Flex
          gap="gap-3"
          className=" invisible group-hover/email:visible"
          alignItems="center"
        >
          {/* <LikeOutlined className="text-base text-secondary hover:text-primary cursor-pointer" />
          <DislikeOutlined className=" text-base text-secondary hover:text-primary cursor-pointer" /> */}
          {isCopyable && (
            <Typography.Paragraph
              className="mb-0 flex"
              copyable={{
                format: 'text/plain',
                text: copyableText,
                icon: [
                  <Copy iconClassName="w-4" />,
                  <Copy iconClassName=" text-primary w-4" />,
                ],
              }}
            />
          )}
        </Flex>
      </Flex>
      <Text
        margin="mt-3 mb-0"
        variant="psm"
        color="text-textPrimary leading-5"
        className=" whitespace-pre-line"
        weight={500}
      >
        {description}
      </Text>
    </Flex>
  );
};

const EmailContainer = ({
  index,
  subject,
  body,
  wordCount,
  isLast,
  onGenerateEmail,
  onEditContext,
  isUserGenerated,
  onViewContext,
  writingStyle,
}: // onDeleteEmail,
{
  index: number;
  subject?: string;
  body?: ReactNode | string;
  wordCount: number;
  isLast: boolean;
  onGenerateEmail?: any;
  onEditContext?: any;
  isUserGenerated?: boolean;
  onViewContext?: any;
  writingStyle?: any;
  // onDeleteEmail?: any;
}) => {
  const { isGenerateEmailLoading } = useContext(ContactContext);
  return (
    <Flex width="w-full" direction="col">
      <Flex justify="between" width="w-full" className=" group/emailsub">
        <Tag
          className={` bg-purple-100 rounded-3xl whitespace-nowrap py-0.5 font-medium cursor-pointer text-purple-800  ${
            isUserGenerated ? 'px-2' : 'px-4'
          }`}
          bordered={false}
          onClick={() => {
            if (isUserGenerated) {
              onEditContext();
            }
          }}
        >
          {ActionsLabels.EMAIL} {index}
          {isUserGenerated && ` • Edit custom context`}
        </Tag>
        <Flex gap="gap-2" alignItems="center" justify="center">
          {writingStyle?.template_name && (
            <Tooltip
              placement="bottomRight"
              title={
                <Flex direction="col" padding="px-3 py-2">
                  <Text weight={500} color="text-textPrimary">
                    Email instruction
                  </Text>
                  <Text
                    variant="psm"
                    color="!text-tertiary"
                    weight={500}
                    margin="mt-2.5 mb-0"
                    className=" flex-shrink-0"
                  >
                    {writingStyle?.template_name}
                  </Text>
                </Flex>
              }
              color="#fff"
              rootClassName="w-[260px]"
            >
              <span className=" flex justify-center items-center">
                <Icon
                  name="EmailWritingStyleIcon"
                  className=" text-secondary hover:text-primary cursor-pointer w-5"
                />
              </span>
            </Tooltip>
          )}
          {isUserGenerated && (
            <Text
              onClick={() => {
                if (onViewContext) {
                  onViewContext();
                }
              }}
            >
              <Tooltip placement="top" title={ActionsLabels.INSIGHTS}>
                <span className=" flex justify-center items-center">
                  <Icon
                    name="ViewInsightIcon"
                    className=" text-secondary hover:text-primary cursor-pointer w-5"
                  />
                </span>
              </Tooltip>
            </Text>
          )}
          <Typography.Paragraph
            className="mb-0 flex cursor-pointer"
            copyable={{
              format: 'text/plain',
              tooltips: ActionsLabels.COPY_EMAIL,
              text: `${subject}

${body}`,
              icon: [
                <Copy iconClassName="w-5" isShowLabel={false} />,
                <Copy iconClassName=" text-primary w-5" isShowLabel={false} />,
              ],
            }}
          />
          <Tooltip placement="top" title={ActionsLabels.SEND_MAIL}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
              onClick={() => {
                window.open(
                  `mailto:?subject=${encodeURIComponent(
                    subject as string,
                  )}&body=${encodeURIComponent(body as string)}`,
                  '_blank',
                );
              }}
              className=" flex justify-center items-center cursor-pointer"
            >
              <Icon name="SendMailIcon" className="text-primary" />
            </span>
          </Tooltip>
        </Flex>
        {/* <Flex
          alignItems="center"
          justify="center"
          gap="gap-2"
          className="cursor-pointer"
          onClick={onDeleteEmail}
        >
          <Icon name="CloseIcon" className="w-3 h-3 text-secondary" />
          <Text weight={500} variant="psm" color="text-secondary">
            {CommonLabels.DELETE}
          </Text>
        </Flex> */}
      </Flex>
      <Flex
        margin="mt-4"
        padding="p-4"
        border="border border-solid"
        rounded="rounded-md"
        borderColor=" border-[#EBEBEB]"
        direction="col"
      >
        {/* <Flex
          padding="px-2.5 py-3"
          rounded="rounded-md"
          backgroundColor="bg-white1"
          width="w-full"
          alignItems="center"
          justify="between"
        >
          <Flex width="w-full" alignItems="center" justify="start">
            <Text variant="pxs" color="text-textPrimary" weight={500}>
              To:
            </Text>
            <Tag
              className=" bg-yellow-100 rounded-3xl px-2.5 py-0.5"
              bordered={false}
            >
              <Text variant="psm" color="text-yellow-800 ">
                James Creech • Amazon
              </Text>
            </Tag>
          </Flex>
          <Text
            className=" flex-shrink-0"
            variant="pxs"
            color="text-secondary"
            weight={500}
          >
            Cc / Bcc
          </Text>
        </Flex> */}
        <Flex margin=" -mt-3" />
        <EmailDescription title="Subject" description={subject} />
        <EmailDescription title="Body" description={body} />
        {/* <Flex
          border="border-0 border-solid border-t"
          borderColor="border-gray-100"
          margin="my-3"
        />
        <Flex justify="end">
          <Flex gap="gap-2" className=" cursor-pointer">
            <SendOutlined className="text-primary" />
            <Text variant="psm" weight={500} color="text-primary">
              {CommonLabels.SEND}
            </Text>
          </Flex>
        </Flex> */}
      </Flex>
      {isLast && false && (
        <Flex
          justify="between"
          width="w-full"
          margin="mt-4"
          alignItems="center"
        >
          <Text variant="psm" color="text-secondary" weight={500}>
            {wordCount && <>{wordCount || 0} words</>}
          </Text>
          <Button
            type="primary"
            ghost
            onClick={onGenerateEmail}
            className=" min-w-[140px]"
          >
            {isGenerateEmailLoading ? (
              <Flex justify="center" alignItems="center">
                <Loading />
              </Flex>
            ) : (
              `${ActionsLabels.REGENERATE} • 1 Credit`
            )}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const DraftEmail = ({
  onAddContext,
  data,
  onGenerateEmail,
  onEditContext,
  onViewContext,
}) => {
  const { isLoadingMoreEmails } = useContext(ContactContext);
  const { isGenerateEmailContextLoading } = useContext(ActionsContext);
  return (
    <>
      {data.length > 0 && (
        <Flex
          padding="px-4"
          backgroundColor="bg-white"
          rounded="rounded-md"
          width="w-full"
          direction="col"
          className=" shadow-card"
        >
          <>
            {data.map((emailData, mailIndex: number) => {
              return (
                <Flex direction="col" margin="mt-4" key={`emails-${mailIndex}`}>
                  <EmailContainer
                    index={data?.length - mailIndex}
                    writingStyle={emailData?.writing_style}
                    subject={emailData.subject}
                    body={
                      emailData?.salutation
                        ? `${emailData?.salutation || ''}

${emailData?.personalized_opener || ''}

${emailData?.unique_offer || ''}

${emailData?.call_to_action || ''}

${emailData?.signature || ''}`
                        : emailData?.email
                    }
                    wordCount={(emailData.email || '').split(/\s+/).length}
                    isLast={data.length === mailIndex + 1}
                    isUserGenerated={
                      emailData?.email_content ||
                      !!emailData?.organization_insight?.length ||
                      !!emailData?.prospect_insight?.length
                    }
                    onGenerateEmail={() => {
                      onGenerateEmail({
                        additional_context: emailData.email_content,
                        insights: {
                          prospect_insight: emailData.prospect_insight,
                          organization_insight: emailData.organization_insight,
                        },
                      });
                    }}
                    onEditContext={() => {
                      onEditContext({
                        additional_context: emailData.email_content,
                        insights: {
                          prospect_insight: emailData.prospect_insight,
                          organization_insight: emailData.organization_insight,
                        },
                        selectedEmail: mailIndex + 1,
                      });
                    }}
                    onViewContext={() => {
                      onViewContext({
                        additional_context: emailData.email_content,
                        insights: {
                          prospect_insight: emailData.prospect_insight,
                          organization_insight: emailData.organization_insight,
                        },
                        selectedEmail: mailIndex + 1,
                      });
                    }}
                    // onDeleteEmail={() => {
                    //   onDeleteEmail(emailData.id);
                    // }}
                  />
                  <Flex
                    border=" border border-solid border-b-0 border-gray-100"
                    margin="mt-4"
                  />
                </Flex>
              );
            })}

            {isLoadingMoreEmails && (
              <InsightLoading title={InsightsLabels.LOADING_EMAILS} />
            )}
          </>
        </Flex>
      )}
      <Flex padding="pt-5" />
      {data.length > 0 && (
        <Flex
          padding="px-5 py-2.5"
          position="fixed"
          className=" bottom-0 left-0 z-[10]"
          backgroundColor="bg-white"
          width="w-full"
        >
          {/* <Skeleton.Button size="default" className="w-full" active /> */}
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (!isGenerateEmailContextLoading) {
                onAddContext();
              }
            }}
            className={`w-full flex justify-center items-center gap-3 ${
              isGenerateEmailContextLoading ? 'opacity-80' : ''
            }`}
            // loading={isGenerateEmailContextLoading}
            // disabled={isGenerateEmailContextLoading}
          >
            {isGenerateEmailContextLoading && <Loading />}

            {`${ActionsLabels.GENERATE_NEW_EMAIL}`}
          </Button>
        </Flex>
      )}
    </>
  );
};

const EmailsView = () => {
  const [actionType, setActionType] = useState('');

  const { userInfo } = useContext(CreditPointsContext);
  const {
    userInfoData,
    isShowContext,
    setIsShowContext,
    onGenerateMessages,
    isGenerateEmailContextLoading,
    onEditEmailContext,
    setAccordionList,
    userProspectData,
    getAllEmailWritingStyles,
  } = useContext(ActionsContext);
  const [addContextForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [writingStyle, setWritingStyle] = useState('');
  const [isRegenerate, setIsRegenerate] = useState(false);
  useEffect(() => {
    setWritingStyle(
      getAllEmailWritingStyles?.find((emailData) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return emailData.is_default;
      })?.id,
    );
  }, [getAllEmailWritingStyles]);

  const emailsData = useMemo(() => {
    const emailsList = [...userInfoData?.user_prospect?.emails];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return emailsList.reverse();
  }, [userInfoData?.user_prospect?.emails]);
  return (
    <>
      <div>
        {!userInfo?.company_details?.about_company && <CompanyEmptyState />}
        {userInfo?.company_details?.about_company && (
          <>
            {(!userInfoData?.user_prospect?.used_email || isRegenerate) &&
              !isGenerateEmailContextLoading && (
                <SummarizeCard
                  icon={isRegenerate ? null : 'EmailIcon'}
                  cardData={[]}
                  cardType="tag"
                  description={
                    <>
                      {!isRegenerate && (
                        <>
                          Elevate your email game with our{' '}
                          <Text
                            variant="span"
                            color="text-textPrimary"
                            weight={400}
                          >
                            hyper personalized emails
                          </Text>
                        </>
                      )}
                      <Text
                        color="text-textPrimary"
                        weight={500}
                        variant="psm"
                        margin="mt-4 mb-2"
                      >
                        AI Instruction for email{' '}
                      </Text>
                      <Select
                        // defaultValue="New"
                        className="w-full text-left"
                        rootClassName=""
                        size="large"
                        options={getAllEmailWritingStyles.map((emailOpt) => {
                          return {
                            value: emailOpt.id,
                            label: emailOpt.template_name,
                          };
                        })}
                        value={writingStyle}
                        onChange={setWritingStyle}
                        // suffixIcon={
                        //   <Icon name="ChevronIcon" className="text-[#92400E]" />
                        // }
                      />
                    </>
                  }
                  buttonText="Generate Emails"
                  onButtonClick={() => {
                    setIsRegenerate(false);
                    onGenerateMessages({
                      ...{ type: 'email', writing_style: writingStyle },
                    });
                  }}
                  loading={isGenerateEmailContextLoading}
                />
              )}
            {(userInfoData?.user_prospect?.emails).length === 0 &&
              userInfoData?.user_prospect?.used_email && <FailsEmptyState />}
            {isGenerateEmailContextLoading && (
              <Grid
                cols="grid-cols-1"
                gap="gap-5"
                className=" bg-white p-4 shadow-card"
                margin="mb-5"
              >
                {[{}, {}, {}].map((email, emailIndex) => {
                  return (
                    <EmailContainerLoading
                      key={`Container-email-loading-${emailIndex}`}
                    />
                  );
                })}
              </Grid>
            )}
            {!!userInfoData?.user_prospect?.emails && !isRegenerate && (
              <DraftEmail
                key="draft-email"
                onAddContext={() => {
                  const targetElement = document.getElementById('tabContainer');
                  targetElement?.scrollIntoView({ behavior: 'smooth' });
                  // onGenerateMessages({
                  //   ...{ type: 'email' },
                  // });
                  setIsRegenerate(true);
                  setWritingStyle(
                    userInfoData?.user_prospect?.emails[
                      userInfoData?.user_prospect?.emails?.length - 1
                    ]?.writing_style?.id,
                  );
                  // setActionType('email');
                  // setIsShowContext(true);
                }}
                data={emailsData}
                onGenerateEmail={() => {}}
                onEditContext={(values) => {
                  setActionType('email');
                  setIsEdit(false);
                  const modifiedValues = values;
                  modifiedValues.addContextForm = addContextForm;
                  onEditEmailContext(modifiedValues);
                }}
                onViewContext={(values) => {
                  setActionType('email');
                  const modifiedValues = values;
                  modifiedValues.addContextForm = addContextForm;
                  onEditEmailContext(modifiedValues);
                  setIsEdit(true);
                }}
              />
            )}
          </>
        )}
      </div>

      <AddContext
        type={actionType}
        open={isShowContext}
        onCancel={() => {
          setIsEdit(false);
          setIsShowContext(false);
          setAccordionList([]);
          addContextForm.resetFields();
        }}
        insightData={userProspectData}
        onGenerate={(values) => {
          setIsEdit(false);
          onGenerateMessages({
            ...values,
            additional_context: values.addMoreContext,
            email_length: values.emailLength,
            ...{ type: actionType },
          });
        }}
        isLoading={isGenerateEmailContextLoading}
        addContextForm={addContextForm}
        isEdit={isEdit}
      />
    </>
  );
};
export default EmailsView;
