// eslint-disable-next-line import/no-extraneous-dependencies
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiInstance } from 'settings/AxiosSetting';

export const useSignUp = () =>
  useMutation(
    (data: {
      email: string;
      name: string;
      password: string;
      language: string;
      company_name: string;
      company_website: string;
    }) => {
      return apiInstance.post('auth/signup', data);
    },
  );

export const useLogin = () =>
  useMutation((data: { email: string; password: string }) => {
    return apiInstance.post('auth/login', data);
  });

export const useGetEmail = () => {
  return useQuery({
    queryKey: ['getEmail'],
    queryFn: () => {
      return apiInstance.get('auth/userEmails');
    },
  });
};

export const useValidateEmail = () =>
  useMutation((data: { email: string }) => {
    return apiInstance.post('auth/validateEmail', data);
  });
