import { ReactNode, useContext } from 'react';
import { EmailConversationLabels } from 'consts/label';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { Typography, Button, Skeleton, Tag } from 'components/Ant';
import Grid from 'components/Grid';
// import SummarizeCard from 'components/SummarizeCard';
import Copy from 'components/Icon/Copy';
import MessageCard from 'components/MessageCard';
import { EmailContext } from './email.context';

const EmailDescriptionLoading = () => {
  return (
    <Flex margin="mt-3" direction="col">
      <Flex justify="between" className="group/email cursor-pointer">
        <Skeleton.Input size="small" active />
      </Flex>
      <Flex margin="mt-3">
        <Skeleton.Input size="small" className="w-full" active />
      </Flex>
    </Flex>
  );
};

const EmailContainerLoading = () => {
  return (
    <Flex width="w-full" direction="col">
      <Flex justify="between" width="w-full">
        <Skeleton.Input size="small" active />
        <Skeleton.Avatar size="small" active />
      </Flex>
      <Flex
        margin="mt-4"
        padding="p-4"
        border="border border-solid"
        rounded="rounded-md"
        borderColor=" border-[#EBEBEB]"
        direction="col"
      >
        <Flex margin=" -mt-3" />
        <EmailDescriptionLoading />
        <EmailDescriptionLoading />
      </Flex>
    </Flex>
  );
};

const EmailDescription = ({ title, description }) => {
  return (
    <Flex margin="mt-3" direction="col">
      <Flex justify="between" className="group/email">
        <Text
          variant="psm"
          color="!text-tertiary"
          weight={500}
          className=" flex-shrink-0"
        >
          {title}
        </Text>
      </Flex>
      <Text
        margin="mt-3 mb-0"
        variant="psm"
        color="text-textPrimary leading-5"
        className=" whitespace-pre-line"
        weight={500}
      >
        {description}
      </Text>
    </Flex>
  );
};

const EmailContainer = ({
  body,
}: // onDeleteEmail,
{
  body?: ReactNode | string;
  // onDeleteEmail?: any;
}) => {
  return (
    <Flex width="w-full" direction="col">
      <Flex justify="between" width="w-full" className=" group/emailsub">
        <Flex gap="gap-2" width="w-full" alignItems="center" justify="center">
          <Tag className="text-purple-800 bg-purple-100 font-medium text-sm leading-4 rounded-3xl px-3 py-1 border-0">
            {EmailConversationLabels.EMAIL_CREDIT_USED}
          </Tag>

          <Typography.Paragraph
            className="mb-0 ml-auto flex cursor-pointer"
            copyable={{
              format: 'text/plain',
              text: `${body}`,
              icon: [
                <Copy iconClassName="w-5" isShowLabel={false} />,
                <Copy iconClassName=" text-primary w-5" isShowLabel={false} />,
              ],
            }}
          />
        </Flex>
        {/* <Flex
          alignItems="center"
          justify="center"
          gap="gap-2"
          className="cursor-pointer"
          onClick={onDeleteEmail}
        >
          <Icon name="CloseIcon" className="w-3 h-3 text-secondary" />
          <Text weight={500} variant="psm" color="text-secondary">
            {CommonLabels.DELETE}
          </Text>
        </Flex> */}
      </Flex>
      <Flex
        margin="mt-4"
        padding="p-4"
        border="border border-solid"
        rounded="rounded-md"
        borderColor=" border-[#EBEBEB]"
        direction="col"
      >
        {/* <Flex
          padding="px-2.5 py-3"
          rounded="rounded-md"
          backgroundColor="bg-white1"
          width="w-full"
          alignItems="center"
          justify="between"
        >
          <Flex width="w-full" alignItems="center" justify="start">
            <Text variant="pxs" color="text-textPrimary" weight={500}>
              To:
            </Text>
            <Tag
              className=" bg-yellow-100 rounded-3xl px-2.5 py-0.5"
              bordered={false}
            >
              <Text variant="psm" color="text-yellow-800 ">
                James Creech • Amazon
              </Text>
            </Tag>
          </Flex>
          <Text
            className=" flex-shrink-0"
            variant="pxs"
            color="text-secondary"
            weight={500}
          >
            Cc / Bcc
          </Text>
        </Flex> */}
        <Flex margin=" -mt-3" />
        {/* <EmailDescription title="Subject" description={subject} /> */}
        <EmailDescription title="Email" description={body} />
        {/* <Flex
          border="border-0 border-solid border-t"
          borderColor="border-gray-100"
          margin="my-3"
        />
        <Flex justify="end">
          <Flex gap="gap-2" className=" cursor-pointer">
            <SendOutlined className="text-primary" />
            <Text variant="psm" weight={500} color="text-primary">
              {CommonLabels.SEND}
            </Text>
          </Flex>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

const DraftEmail = ({
  data,
  generateEmailConversation,
  isEmailConversationApiLoading,
}) => {
  return (
    <>
      {data !== '' && (
        <Flex
          padding="px-4"
          backgroundColor="bg-white"
          rounded="rounded-md"
          width="w-full"
          direction="col"
          className=" shadow-card"
        >
          <Flex direction="col" margin="mt-4" key={`emails-${1}`}>
            <EmailContainer body={data} />
            <Flex
              border=" border border-solid border-b-0 border-gray-100"
              margin="mt-4"
            />
          </Flex>
        </Flex>
      )}
      <Flex padding="pt-5" />
      {data !== '' && (
        <Flex
          padding="px-5 py-2.5"
          position="fixed"
          className=" bottom-0 left-0 z-[10] justify-end"
          backgroundColor="bg-white"
          width="w-full"
          direction="row"
          gap="gap-3"
        >
          {/* <Skeleton.Button size="default" className="w-full" active /> */}
          <Button
            type="default"
            size="large"
            onClick={() => {
              generateEmailConversation();
            }}
            className="w-2/5 flex justify-center items-center gap-3"
            loading={isEmailConversationApiLoading}
            // disabled={isGenerateEmailContextLoading}
          >
            {EmailConversationLabels.EMAIL_RESPONSE_REGENERATE}
          </Button>

          <Button
            type="primary"
            size="large"
            onClick={() => {
              // generateEmailConversation();
              // eslint-disable-next-line
              window.parent.postMessage({ type: 'emailResponse',email:data }, '*');
            }}
            className="w-1/6 flex justify-center items-center gap-3"
            loading={isEmailConversationApiLoading}
            // disabled={isGenerateEmailContextLoading}
          >
            Use
          </Button>
        </Flex>
      )}
    </>
  );
};

const EmailView = () => {
  const {
    GenerateEmailConversation,
    isEmailConversationApiLoading,
    emailResponse,
    isPermissionGiven,
    setIsPermissionGiven,
    isCreditMessageSkipped,
  } = useContext(EmailContext);

  return (
    <div className="p-5">
      {!isPermissionGiven && !isCreditMessageSkipped ? (
        <MessageCard
          icon="EmailIcon"
          messageTitle={EmailConversationLabels.EMAIL_CREDIT_TITLE}
          messageDescription="1 credit will be used to generate this email."
          confirmationMessage="Don't show this message again"
          buttonTitle="Suggest Response • 1 credit"
          onButtonClick={() => {
            setIsPermissionGiven(true);
            GenerateEmailConversation();
          }}
          onCheckBoxChange={(event: any) => {
            localStorage.setItem('creditMessageSkipped', event.target.checked);
          }}
        />
      ) : (
        <>
          {isEmailConversationApiLoading && (
            <Grid
              cols="grid-cols-1"
              gap="gap-5"
              className=" bg-white p-4 shadow-card"
              margin="mb-5"
            >
              {[{}].map((email, emailIndex) => {
                return (
                  <EmailContainerLoading
                    key={`Container-email-loading-${emailIndex}`}
                  />
                );
              })}
            </Grid>
          )}

          {!isEmailConversationApiLoading && emailResponse !== '' && (
            <DraftEmail
              data={emailResponse}
              generateEmailConversation={GenerateEmailConversation}
              isEmailConversationApiLoading={isEmailConversationApiLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EmailView;
