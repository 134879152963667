import React, { ReactNode } from 'react';
import Flex from 'components/Flex';
import { Icon, IconNameType } from 'components/Icon';
import { Button, Skeleton, Tag, Typography } from 'components/Ant';
import Text from 'components/Text';
import Loading from 'components/Loading';

const SummarizeCard = ({
  icon,
  cardType,
  cardData,
  description,
  buttonText,
  onButtonClick,
  loading = false,
  disabled = false,
  cardLoading,
}: {
  icon?: IconNameType | null;
  cardType: 'button' | 'tag';
  cardData: string[];
  description: string | ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  cardLoading?: boolean;
}) => {
  return (
    <Flex
      backgroundColor="bg-white"
      padding="py-5 px-4"
      rounded="rounded-md"
      className="shadow-card"
      gap="gap-4"
      direction="col"
      alignItems="center"
    >
      {icon && (
        <>
          {cardLoading ? (
            <Skeleton.Avatar size={48} active />
          ) : (
            <Flex
              padding="p-3"
              rounded=" rounded-full"
              backgroundColor="bg-purple-100"
            >
              <Icon name={icon} className=" text-purple-500 w-6 h-6" />
            </Flex>
          )}
        </>
      )}
      {cardData?.length !== 0 && (
        <Flex
          gap="gap-y-2.5"
          flexWrap="wrap"
          alignItems="center"
          justify="center"
        >
          {(cardData || []).map((data, dataIndex) => {
            return (
              <div key={`card-data-${dataIndex}`}>
                {cardType === 'button' && (
                  <Button type="primary" ghost className="">
                    {data}
                  </Button>
                )}
                {cardType === 'tag' && (
                  <>
                    {cardLoading ? (
                      <Skeleton.Input
                        size="small"
                        active
                        className="rounded-3xl mr-2"
                      />
                    ) : (
                      <Tag className="text-green-800 bg-green-100 rounded-3xl px-2.5 py-0.5 border-0">
                        {data}
                      </Tag>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </Flex>
      )}
      {description && !cardLoading && (
        <Text
          variant="psm"
          color="text-gray-500"
          position="center"
          className=" leading-5"
          width="w-full"
        >
          {description}
        </Text>
      )}
      {buttonText && (
        <Button
          type="primary"
          size="large"
          block
          onClick={onButtonClick}
          disabled={disabled}
        >
          {loading && (
            <Flex justify="center" alignItems="center">
              <Loading />
            </Flex>
          )}
          {!loading && (
            <Typography.Paragraph
              ellipsis
              className={`${disabled ? '' : 'text-white'} mb-0`}
            >
              {buttonText}
            </Typography.Paragraph>
          )}
        </Button>
      )}
    </Flex>
  );
};
export default SummarizeCard;
