export const ContactLabels = {
  GET_MORE: 'Get more',
  ENRICH_DETAILS: 'Enrich details',
  CREDIT_USED: 'credit used',
  DATA_ENRICHED: 'Data Enriched',
  HAPPYSALES_HELPS: 'Happysales helps you to',
  ADD_TO_OUTREACH_SEQUENCE: 'Add to Outreach Sequence',
  SEARCH_FOR_A_OUTREACH_SEQUENCE: 'Search for a outreach sequence',
  VIEW_DETAILS: 'View details',
  ASSIGN: 'Assign',
  ASSIGNED: 'Assigned',
  VIEW_STATUS: 'View status',
  EMAIL_PLACEHOLDER: 'No email found',
  ORGANISATION_PLACEHOLDER: 'No organisation found',
  DESIGNATION_PLACEHOLDER: 'No designation found',
  UNABLE_TO_FETCH: 'Unable to fetch data',
  ONBOARDING_COMPLETION: 'Onboarding progress:',
  IMPROVE: 'Improve',
  CREDIT_TOOLTIP: 'You will receive 5 credits every week on the free plan',
  PRACTICE: 'AI Coach',
};

export const InsightsLabels = {
  LOADING_INSIGHTS_USER: 'Loading insights for user',
  LOADING_INSIGHTS_ORG: 'Loading insights for company',
  LOADING_KEY_CHALLENGES: 'Loading key challenges for the role',
  LOADING_NEWS_ORG: 'Loading news for company',
  ANALYSE_TALKING_POINTS: 'Analysing pitch',
  INSIGHTS_FOR: 'Insights for',
  TALKING_POINTS_FOR: 'Pitch for',
  KEYCHALLENGES_FOR_ROLE: 'Key challenges for the role',
  LOADING_TECH_STACK: 'Loading tech stack',
  SINCE: 'Since',
  INSIGHTS_EMPTY_PLACEHOLDER: ' No insights found',
  TECH_STACK_EMPTY_PLACEHOLDER: ' No tech stack found',
  PITCH_EMPTY_PLACEHOLDER: 'No pitch found',
  LOADING_ICEBREAKERS: 'Loading icebreakers',
  LOADING_EMAILS: 'Loading emails',
  PERSON: 'Person',
  DISC: 'DISC',
  COMPANY: 'Company',
  CHALLENGES: 'Challenges',
  PITCH: 'Pitch',
  COMPANY_VALUE_EMPTY_TITLE: 'Company value proposition is empty',
  COMPANY_VALUE_EMPTY_DESC:
    'To obtain high-quality, customized output, enter the company value proposition',
  COMPANY_VALUE_EMPTY_ADD_NOW: '• Add Now',
  PRACTICE_PITCH: 'Practice pitch',
  VIEW_LINK: 'View link',
  SENTIMENT: 'sentiment',
};

export const ActionsLabels = {
  ICE_BREAKERS: 'Icebreakers',
  WRITE_EMAIL: 'Write an email',
  ADD_CONTEXT: 'Custom context added',
  WRITE_AN_EMAIL: 'Emails',
  WRITE_A_MESSAGE: 'Write a message',
  EMAIL: 'Email',
  CONTEXT_TEXTAREA_PLACEHOLDER:
    'Write an email to introduce a product to a client',
  EMAIL_LENGTH: 'Email Length',
  EMAIL_LENGTH_SHORT: 'Short',
  EMAIL_LENGTH_MEDIUM: 'Medium',
  EMAIL_LENGTH_LARGE: 'Large',
  GENERATE_EMAIL: 'Generate email ',
  GENERATE_EMAILS: 'Generate emails ',
  GENERATE_NEW_EMAIL: 'Regenerate',
  GENERATE_MESSAGE: 'Generate message ',
  REGENERATE: 'Regenerate',
  ADD_CONTEXT_SELECT: 'Select context or add your own',
  EDIT_CONTEXT_SELECT: 'Insights used in email ',
  ADD_CONTEXT_DESCRIPTION: 'Select to add more context for the email',
  COPY_MESSAGE: 'Copy message',
  EMAIL_LENGTH_REQUIRED: 'Please provide the email length',
  ADD_CONTEXT_REQUIRED: 'Please provide additional context',
  INSIGHTS_REQUIRED: 'Please provide atleast one insight',
  INSIGHTS: 'Insights',
  COPY_EMAIL: 'Copy email',
  SEND_MAIL: 'Send mail',
  SUGGESTIONS: 'Suggestions',
  SEQUENCE: 'Sequence',
};

export const DISCLabels = {
  ACCORDION_TITLE: 'DISC Analysis',
  ACCORDION_PERSONALITY: 'Personality traits',
  ACCORDION_DOS_COMMUNICATING: 'Do’s for communicating',
  ACCORDION_DONT_COMMUNICATING: 'Dont’s for communicating',
  DISC_SUMMARY_DESC:
    'Understand personality traits to better communication strategies',
  DISC_SUMMARY_BUTTON: 'Generate DISC Profile',
  PERSONALITY_PARAMTER_1: 'Risk Appetite',
  PERSONALITY_PARAMTER_2: 'Fast paced',
  PERSONALITY_PARAMTER_3: 'Independent',
  PERSONALITY_PARAMTER_4: 'Optimistic',
  PERSONALITY_PARAMTER_5: 'Trusting',
};

export const UpgradeLabels = {
  REFRESH_TITLE: 'Refresh Profile Information',
  REFRESH_DESCRIPTION:
    'We will fetch the latest profile information and regenerate Insights & Actions based on the changed role. This might take a few seconds.',
  REFRESH_BUTTON: 'Refresh',
  DISMISS_BUTTON: 'Dismiss',
  TOUR_STEP_2_TITLE: 'Insights',
  TOUR_STEP_2_DESCRIPTION:
    'Track career moves, honors, and company milestones for deeper engagement insights.',
  TOUR_STEP_1_TITLE: 'Actions',
  TOUR_STEP_1_DESCRIPTION:
    'Initiate contact,break the ice, or set up a communication sequence with actionable outreach steps.',
  TOUR_STEP_3_TITLE: 'AI coach',
  TOUR_STEP_3_DESCRIPTION:
    'Enhance your pitch with the AI coach, designed to simulate real-life prospect interactions for effective practice',
  TOUR_STEP_4_TITLE: 'Getting Insights for a role from the past?',
  TOUR_STEP_4_DESCRIPTION:
    'There are slim chances where your prospect might be matched against their past roles. Your Insights & Actions are recommended based on this.',
  TOUR_STEP_5_TITLE: 'The solution is right here',
  TOUR_STEP_5_DESCRIPTION:
    'Click on Refresh and we will attempt to match them to their recent and relevant work experience.',
  REFRESH_TITLE_LOADING:
    'Please wait while we are matching the prospect to their relevant role',
  REFRESH_TITLE_SOON:
    'This is taking longer than usual. We should be done soon.',
};
