import React from 'react';
import { ChildrenProps } from 'types/custom';
import ContactService from './contact.service';

export const ContactContext = React.createContext<any>('');

export const ContactProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = ContactService();

  return (
    <ContactContext.Provider value={stateService}>
      {children}
    </ContactContext.Provider>
  );
};
