import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { InsightsLabels } from 'consts/label';
import Flex from 'components/Flex';
import Text from 'components/Text';

const CompanyEmptyState = () => {
  return (
    <Flex
      backgroundColor="bg-red-50"
      padding="p-4"
      rounded=" rounded-md"
      alignItems="start"
      gap="gap-3"
      margin="mb-5"
    >
      <InfoCircleOutlined className=" text-red-500 mt-1 flex-shrink-0 text-base" />
      <Flex direction="col" padding="pr-3">
        <Text variant="psm" weight={500} color="text-red-800">
          {InsightsLabels.COMPANY_VALUE_EMPTY_TITLE}
        </Text>
        <Text variant="psm" color="text-red-700" margin="mt-2 mb-0">
          {InsightsLabels.COMPANY_VALUE_EMPTY_DESC}
          <Text
            variant="span"
            weight={600}
            margin="ml-1"
            onClick={() => {
              window.open(`${process.env.REACT_APP_WEB_APP_URL}settings/list`);
            }}
          >
            {InsightsLabels.COMPANY_VALUE_EMPTY_ADD_NOW}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
export default CompanyEmptyState;
