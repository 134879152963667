/**
 * Import all the ant components
 * Export the ant compponents
 */
/* eslint-disable react/jsx-props-no-spreading */

import {
  Button as AntButton,
  Input as AntInput,
  Badge,
  ButtonProps,
  InputProps,
} from 'antd';
import type { Moment } from 'moment';
import './tabs.css';
import './ant.override.css';
import './dropdown.css';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { FunctionComponent, ReactNode } from 'react';
import type { Rule, FormInstance } from 'antd/es/form';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { TextAreaProps } from 'antd/es/input';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { EllipsisConfig } from 'antd/es/typography/Base';

import Text from 'components/Text';
import { Icon, IconNameType } from 'components/Icon';
import Flex from 'components/Flex';

const DatePicker = generatePicker<Moment>(momentGenerateConfig);
const { Search } = AntInput;

const Button: FunctionComponent<ButtonProps> = (props) => {
  const { className } = props;
  return (
    <AntButton {...props} className={` ${className || ''} shadow-default`} />
  );
};

const Input: FunctionComponent<InputProps> = (props) => {
  const { size, className } = props;
  return (
    <AntInput
      {...props}
      size={size || 'large'}
      className={` ${
        className || ''
      } rounded-md border-[#C7CBD1] shadow-default bg-white`}
    />
  );
};
const InputPassword: FunctionComponent<InputProps> = (props) => {
  const { size, className } = props;
  return (
    <AntInput.Password
      {...props}
      size={size || 'large'}
      className={` ${
        className || ''
      } rounded-md border-[#C7CBD1] shadow-default bg-white`}
    />
  );
};
const Textarea: FunctionComponent<TextAreaProps> = (props) => {
  const { size, className } = props;
  return (
    <AntInput.TextArea
      {...props}
      size={size || 'large'}
      className={` ${
        className || ''
      } rounded-md border-[#C7CBD1] shadow-default`}
    />
  );
};

export type BadgeColorProps =
  | 'green'
  | 'yellow'
  | 'red'
  | 'blue'
  | 'purple'
  | 'gray'
  | 'gray-900';

export const TableBadge = ({
  label,
  color,
  className = 'rounded',
  status,
}: {
  className?: string;
  label: string | ReactNode;
  color: BadgeColorProps;
  status?: boolean;
}) => {
  return (
    <Badge
      count={
        <Text
          variant="psm"
          weight={500}
          padding="px-0.5 py-0.5"
          className={className}
          color={`${color === 'green' ? 'text-green-800 bg-green-100' : ''} ${
            color === 'yellow' ? ' text-yellow-800 bg-yellow-100 ' : ''
          } ${color === 'red' ? ' text-[#991B1B] bg-[#FEE2E2] ' : ''} ${
            color === 'blue' ? ' text-blue-800 bg-blue-100 ' : ''
          }${color === 'gray' ? ' text-gray-800 bg-gray-100 ' : ''}
           ${color === 'purple' ? ' text-purple-800 bg-purple-100' : ''}
           ${color === 'gray-900' ? ' text-gray-900 bg-[#EBEBEB]' : ''}
          `}
        >
          {status && (
            <Badge
              className={` rounded-full mr-2 w-1.5 h-1.5 ${
                color === 'green' ? 'bg-green-800 ' : ''
              } ${color === 'yellow' ? ' bg-yellow-800  ' : ''} ${
                color === 'red' ? ' bg-red-800  ' : ''
              } ${color === 'blue' ? ' bg-blue-800' : ''}${
                color === 'gray' ? ' bg-gray-800' : ''
              }
           ${color === 'purple' ? ' bg-purple-800' : ''}
           ${color === 'gray-900' ? ' bg-gray-900' : ''}
          `}
            />
          )}

          {label}
        </Text>
      }
      className="tag"
    />
  );
};

const ButtonIcon = ({
  icon,
  label,
  onClick,
  isEditable,
}: {
  icon: IconNameType;
  label: string;
  onClick?: () => void;
  isEditable?: boolean;
}) => (
  <Button
    size="large"
    className="shadow-default border-silver"
    onClick={onClick}
    disabled={isEditable}
  >
    <Flex alignItems="center" justify="center">
      <Icon name={icon} className="text-secondary" />
      <Text weight={500} color="text-primaryText" margin="ml-3 m-0">
        {label}
      </Text>
    </Flex>
  </Button>
);

export {
  DatePicker,
  Input,
  Textarea,
  InputPassword,
  Search,
  Button,
  Rule,
  CustomTagProps,
  FormInstance,
  ButtonIcon,
  CheckboxChangeEvent,
  EllipsisConfig,
};
export {
  Form,
  notification,
  Anchor,
  Spin,
  Result,
  Image,
  Select,
  Modal,
  Layout,
  Upload,
  Table,
  AutoComplete,
  Typography,
  Breadcrumb,
  Collapse,
  Checkbox,
  Card,
  Radio,
  Cascader,
  Divider,
  InputNumber,
  Drawer,
  Space,
  Tag,
  Tree,
  Row,
  Col,
  Tabs,
  Timeline,
  Steps,
  Affix,
  Tooltip,
  Progress,
  Dropdown,
  TimePicker,
  Slider,
  Badge,
  Alert,
  Switch,
  Menu,
  message,
  Avatar,
  Segmented,
  Popconfirm,
  List,
  Skeleton,
  type TabsProps,
  type MenuProps,
  type TableColumnsType,
  type ListProps,
  Popover,
  type CollapseProps,
  Tour,
  type TourProps,
} from 'antd';
