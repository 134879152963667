import { useNavigate } from 'react-router-dom';

const OutReachService = () => {
  const navigate = useNavigate();
  const onCancel = () => {
    navigate('/');
  };

  const handleCloseTag = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  return {
    onCancel,
    handleCloseTag,
  };
};
export default OutReachService;
