/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { useContext, useMemo } from 'react';
import { InsightsLabels } from 'consts/label';
import { InsightLoading } from 'components/Page/Insights';
import SummarizeCard from 'components/SummarizeCard';
import FailsEmptyState from 'components/FailsEmptyState';
// eslint-disable-next-line import/no-relative-parent-imports
import Text from 'components/Text';
import { NewsComponent } from 'components/Page/News';
import { InsightsContext } from './insights.context';

const PersonInsightsView = () => {
  const {
    isLoadingInsights,
    userInfoData,
    fetchPersonInsights,
    isGetYoutubeLoading,
  } = useContext(InsightsContext);
  const prospectsData = useMemo(
    () => [
      ...(userInfoData?.youtube_data || []).map((youtubeData) => {
        const localYoutubeData = youtubeData;
        localYoutubeData.category = `${youtubeData?.channel.name}`;
        localYoutubeData.isYouTube = true;
        return localYoutubeData;
      }),
      ...(userInfoData?.prospect_insight || []).map((insight) => {
        const localInsights = insight;
        localInsights.isInsights = true;
        return localInsights;
      }),
    ],
    [userInfoData?.youtube_data, userInfoData?.prospect_insight],
  );
  return (
    <>
      {!userInfoData?.user_prospect?.used_prospect_insight &&
        !userInfoData?.user_prospect?.used_youtube &&
        !isLoadingInsights &&
        !isGetYoutubeLoading && (
          <SummarizeCard
            icon="PersonInsightIcon"
            cardType="tag"
            cardData={[]}
            description={
              <>
                Gain{' '}
                <Text variant="span" color="text-textPrimary" weight={400}>
                  insights
                </Text>{' '}
                to approach your connections with confidence and relevance.
              </>
            }
            buttonText="Generate Person Insights"
            // loading={isLoadingInsights}
            onButtonClick={() => {
              fetchPersonInsights();
            }}
          />
        )}
      {(isLoadingInsights || isGetYoutubeLoading) && (
        <InsightLoading title={InsightsLabels.LOADING_INSIGHTS_USER} />
      )}
      {!isLoadingInsights &&
        !isGetYoutubeLoading &&
        (prospectsData || []).length > 0 &&
        (userInfoData?.user_prospect?.used_prospect_insight ||
          userInfoData?.user_prospect?.used_youtube) && (
          <>
            {/* <InsightComponent
            title={`${InsightsLabels.INSIGHTS_FOR} ${
              userInfoData?.first_name || ''
            }`}
            data={userInfoData?.prospect_insight}
          /> */}
            <NewsComponent
              title={`${InsightsLabels.INSIGHTS_FOR} ${
                userInfoData?.first_name || ''
              }`}
              data={prospectsData}
            />
          </>
        )}
      {(prospectsData || []).length === 0 &&
        (userInfoData?.user_prospect?.used_prospect_insight ||
          userInfoData?.user_prospect?.used_youtube) &&
        !isLoadingInsights && <FailsEmptyState />}
    </>
  );
};
export default PersonInsightsView;
