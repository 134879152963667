import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Text from 'components/Text';

const Accordion = ({
  title,
  color = 'text-secondary',
  isOpen,
  setIsOpen,
  titleClassName = '',
  showWarning = false,
}) => {
  return (
    <Flex
      width="w-full"
      justify="between"
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      className=" cursor-pointer"
    >
      <Flex alignItems="center" gap="gap-1">
        <Text
          color={color}
          variant="psm"
          weight={500}
          className={titleClassName}
        >
          {title}
        </Text>
        {showWarning && <div className="bg-red-500 rounded-lg w-1 h-1" />}
      </Flex>
      <Icon
        name="ArrowIcon"
        className={`text-secondary ml-1 scale-100 skew-x-0 skew-y-0 translate-x-0 translate-y-0 ${
          isOpen ? 'rotate-180' : ''
        }`}
      />
    </Flex>
  );
};

export default Accordion;
