import { useContext } from 'react';
import { AuthLabels, CommonLabels, SettingsLabel } from 'consts/label';
import { Button, Form, Input, Popover, Tag } from 'components/Ant';
import Flex from 'components/Flex';
import Text from 'components/Text';
import BackContainer from 'components/BackContainer';
import { Icon } from 'components/Icon';
import { AboutViewContext } from './about.context';

const Integrations = ({ title, desc, isConnect }) => {
  return (
    <Flex width="w-full" justify="between" alignItems="center">
      <Flex direction="col">
        <Text color="text-textPrimary" weight={500} variant="psm">
          {title}
          {isConnect && (
            <Tag
              className=" ml-2 text-green-800 bg-green-100 rounded-3xl px-2.5 py-0.5"
              bordered={false}
            >
              Connected
            </Tag>
          )}
        </Text>
        <Text
          color="text-gray-500"
          margin="mt-2 mb-0"
          weight={400}
          variant="pxs"
        >
          {desc}
        </Text>
      </Flex>
      <Button
        type="default"
        danger={isConnect}
        className=" min-w-[90px] text-xs bg-white border-silver"
      >
        {' '}
        {isConnect ? 'Disconnect' : 'Connect'}
      </Button>
    </Flex>
  );
};

const AboutView = ({ isSettingsPage = false, showCompetitors = false }) => {
  const {
    aboutCompanyform,
    onSettingsBack,
    onCreateAccountStep2,
    activeKey,
    onCustomerFormCancel,
    onCompanyFormCancel,
    logout,
    userInfo,
  } = useContext(AboutViewContext);
  return (
    <Flex direction="col" padding={isSettingsPage ? 'p-5' : ``}>
      {isSettingsPage ? (
        <>
          {' '}
          <Flex width="w-full" justify="between" margin="mb-4">
            <BackContainer
              title={SettingsLabel.SETTINGS}
              onBack={() => {
                onSettingsBack();
              }}
            />{' '}
            <Popover
              content={
                <Flex
                  width="w-56"
                  gap="gap-2"
                  alignItems="center"
                  className=" cursor-pointer"
                  onClick={() => {
                    logout();
                  }}
                >
                  <Icon name="LogoutIcon" className="text-red-500" />
                  <Text color="text-red-500">{AuthLabels.LOGOUT}</Text>
                </Flex>
              }
              arrow={false}
            >
              <Button type="link" className="pr-0">
                <Icon name="MoreIcon" className=" text-textPrimary" />
              </Button>
            </Popover>
          </Flex>
          {/* <Tabs
            className="my-0"
            defaultActiveKey="happysales"
            items={[
              {
                label: 'Happysales',
                key: 'happysales',
              },
              {
                label: 'Integration',
                key: 'integrations',
                disabled: true,
              },
            ]}
            activeKey={activeKey}
            onChange={handleTabChange}
          /> */}
        </>
      ) : (
        <Flex direction="col" padding="py-5">
          <Text color="text-textPrimary" variant="p">
            {showCompetitors
              ? AuthLabels.TELL_US_ABOUT_CUSTOMERS
              : AuthLabels.TELL_US_ABOUT_YOUR_COMPANY}
          </Text>
          {showCompetitors && (
            <Text color="text-secondary" weight={400} variant="psm">
              {AuthLabels.TELL_US_ABOUT_CUSTOMERS_DESCRIPTION}
            </Text>
          )}
        </Flex>
      )}
      {activeKey === 'happysales' && (
        <Form
          name="createAccount"
          layout="vertical"
          className="w-full"
          form={aboutCompanyform}
          onFinish={onCreateAccountStep2}
          initialValues={{
            select_lang: 'en',
          }}
          autoComplete="off"
        >
          {isSettingsPage && (
            <>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    message: AuthLabels.FIRST_NAME_PLACEHOLDER,
                    required: true,
                  },
                  {
                    // /^[A-Za-zÀ-ÖØ-öø-ÿー－ 'ぁ-ゟ゠-ヿ一-鿿]+$/u
                    pattern: /^(?=\p{L})[\p{L}\p{M}\p{N}\p{P}\p{Z}\p{Sm}'-]+$/u,
                    message: AuthLabels.LABEL_ERROR_MESSAGE,
                  },
                ]}
              >
                <Input placeholder={AuthLabels.FIRST_NAME_PLACEHOLDER} />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { message: AuthLabels.LAST_NAME_PLACEHOLDER, required: true },
                  {
                    pattern: /^(?=\p{L})[\p{L}\p{M}\p{N}\p{P}\p{Z}\p{Sm}'-]+$/u,
                    message: AuthLabels.LABEL_ERROR_MESSAGE,
                  },
                ]}
              >
                <Input placeholder={AuthLabels.LAST_NAME_PLACEHOLDER} />
              </Form.Item>
            </>
          )}
          {!showCompetitors && (
            <>
              <Form.Item
                name="companyName"
                label={AuthLabels.COMPANY_NAME}
                rules={[
                  {
                    message: AuthLabels.COMPANY_NAME_PLACEHOLDER,
                    required: true,
                  },
                  {
                    pattern: /^(?=.*[A-Za-z])[A-Za-z0-9\s\-,&.'()]+$/u,
                    message: AuthLabels.COMPANY_NAME_ERROR_MESSAGE,
                  },
                ]}
              >
                <Input
                  placeholder={AuthLabels.COMPANY_NAME_PLACEHOLDER}
                  disabled={userInfo?.workspace_role_name !== 'Admin'}
                />
              </Form.Item>
              {/* <Form.Item
                name="companyWebsite"
                label={AuthLabels.COMPANY_WEBSITE}
                rules={[
                  {
                    message: AuthLabels.COMPANY_WEBSITE_PLACEHOLDER,
                    required: true,
                  },
                  () => ({
                    validator(_, value) {
                      // eslint-disable-next-line unicorn/better-regex
                      const urlPattern =
                        /((https?):\/\/)?(www.)?[\da-z]+(\.[a-z]{2,}){1,3}(#?\/?[\d#A-Za-z]+)*\/?(\?[\w-]+=[\d%A-Za-z-]+&?)?$/;
                      if (value && !urlPattern.test(value)) {
                        return Promise.reject(
                          new Error('Please enter a valid URL'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder={AuthLabels.COMPANY_WEBSITE_PLACEHOLDER} />
              </Form.Item> */}
            </>
          )}
          {isSettingsPage && (
            <>
              {/* <Form.Item
                name="select_lang"
                label={AuthLabels.COMPANY_AI_LANG}
                rules={[
                  {
                    message: AuthLabels.ENTER_SELECT_LANGUAGE,
                    required: true,
                  },
                ]}
                className="mb-2.5"
              >
                <Select
                  placeholder="English"
                  size="large"
                  defaultValue="en"
                  options={[
                    { label: 'English', value: 'en-US' },
                    // { label: 'German', value: 'de' },
                    // { label: 'French', value: 'fr' },
                    // { label: 'Portuguese BR', value: 'pt-BR' },
                    // { label: 'Spanish', value: 'es' },
                    // { label: 'LATAM Spanish', value: 'es-LA' },
                  ]}
                />
              </Form.Item> */}
              {/* <Flex justify="between" alignItems="center">
                <Text variant="psm" color=" text-textPrimary" weight={400}>
                  {AuthLabels.STREAM_AI_RESPONSE}
                  <Tooltip placement="top" title={AuthLabels.STERAM_AI_TOOLTIP}>
                    <InfoCircleOutlined className="ml-2 mt-1" />
                  </Tooltip>
                </Text>
                <Form.Item
                  name="steam_ai"
                  valuePropName="checked"
                  className=" mb-0"
                >
                  <Switch />
                </Form.Item>
              </Flex> */}
              <Flex
                alignItems="center"
                justify="between"
                margin="my-6"
                padding="pb-10"
                className=" cursor-pointer"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_WEB_APP_URL}settings/company/company-details`,
                  );
                }}
              >
                <Text variant="psm" color=" text-textPrimary" weight={400}>
                  {AuthLabels.ADVANCED_SETTINGS}
                </Text>
                <Text
                  variant="psm"
                  position="center"
                  color="text-textPrimary"
                  padding="pl-1.5"
                >
                  {/* {AuthLabels.GO_TO_WEB_APP} */}
                  <Icon
                    name="ChevronIcon"
                    className="w-3 h-3 text-textPrimary"
                  />
                  {/*
                  <Icon
                    name="ArrowIcon"
                    className={` ml-1 scale-100 skew-x-0 skew-y-0 translate-x-0 translate-y-0 -rotate-90 text-textPrimary`}
                  /> */}
                  {/* <ArrowRightOutlined rotate={-45} /> */}
                </Text>
                {/* <Button
                  className="font-medium"
                  size="large"

                >
                </Button> */}
              </Flex>
            </>
          )}

          {isSettingsPage ? (
            <Flex
              position="fixed"
              className="left-0 bottom-0"
              backgroundColor="bg-white"
              width="w-full"
              padding="p-5 pt-0"
            >
              <Button type="primary" block size="large" htmlType="submit">
                {CommonLabels.SAVE}
              </Button>
            </Flex>
          ) : (
            <>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-full font-medium"
              >
                {!showCompetitors
                  ? CommonLabels.SAVE
                  : CommonLabels.GET_STARTED}
              </Button>
              <Button
                type="link"
                size="large"
                className="w-full font-medium mt-1"
                onClick={() => {
                  if (showCompetitors) {
                    onCustomerFormCancel();
                  } else {
                    onCompanyFormCancel();
                  }
                }}
              >
                {showCompetitors ? CommonLabels.SKIP : CommonLabels.CANCEL}
              </Button>
            </>
          )}
        </Form>
      )}
      {activeKey === 'integrations' && (
        <Flex direction="col" gap="gap-5">
          <Integrations
            title="Outreach"
            desc="Not connected"
            isConnect={false}
          />
          <Integrations title="Salesloft" desc="Not connected" isConnect />
        </Flex>
      )}
    </Flex>
  );
};

export default AboutView;
