import React from 'react';
import { ConfigProvider } from 'antd';
import { ChildrenProps } from 'types/custom';

const AntSetting = ({ children }: ChildrenProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4F46E5',
          fontFamily: "'Inter', sans-serif",
          colorLink: '#4F46E5',
        },
        components: {
          Button: {
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          },
          Input: {
            colorBgContainer: 'transparent',
          },
          Tabs: {
            colorText: '#111827',
          },
          Select: {
            fontSize: 14,
          },
          Segmented: {
            colorText: '#4F46E5',
            colorBgElevated: '#EBEBF7',
          },
          Table: {
            colorText: '#111827',
            colorBorderSecondary: '#C7CBD1',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
export default AntSetting;
