import { MemoryRouter } from 'react-router-dom';
import type { ChildrenProps } from 'types/custom';

const RouterSetting = ({ children }: ChildrenProps) => (
  <MemoryRouter initialEntries={['/']} initialIndex={0}>
    {children}
  </MemoryRouter>
);

export default RouterSetting;
