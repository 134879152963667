import { ContactProvider } from './contact.context';
import ContactView from './contact.view';

const ContactApp = () => {
  return (
    <ContactProvider>
      <ContactView />
    </ContactProvider>
  );
};

export default ContactApp;
