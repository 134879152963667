import React from 'react';
import { ChildrenProps } from 'types/custom';
import OutReachService from './outReach.service';

export const OutReachContext = React.createContext<any>('');

export const OutReachProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = OutReachService();

  return (
    <OutReachContext.Provider value={stateService}>
      {children}
    </OutReachContext.Provider>
  );
};
