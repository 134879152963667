import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { EllipsisConfig, Typography } from 'components/Ant';
import styles from './text.module.css';
import {
  TAG_TYPE_VARIANTS,
  POSITION_VARIANTS,
  FONT_WEIGHT_VARIANTS,
  LETTER_SPACING_VARIENTS,
  TEXT_WRAP_VARIANTS,
} from './variants';

export type TextVariant =
  | 'p'
  | 'pxs'
  | 'psm'
  | 'plg'
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span'
  | 'sub-heading'
  | 'navlink'
  | 'title'
  | 'button'
  | 'ellipsis';

type Props = {
  className?: string;
  position?: 'left' | 'center' | 'right';
  variant?: TextVariant;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'normal';
  children?: any;
  onClick?: () => void;
  color?: string | null;
  textCase?: 'uppercase' | 'lowercase' | 'capitalize' | null;
  padding?: string | null;
  margin?: string | null;
  letterSpacing?: 'tighter' | 'tight' | 'normal' | 'wide' | 'wider' | 'widest';
  width?: string;
  wrapText?: 'pre' | 'nowrap' | 'normal' | 'pre-line' | 'pre-wrap';
  customStyles?: CSSProperties;
  ellipsis?: EllipsisConfig;
};

type TagType = keyof JSX.IntrinsicElements;
const Text = ({
  className = '',
  children,
  variant = 'p',
  onClick,
  position = 'left',
  color = null,
  weight = 'normal',
  textCase = null,
  padding = 'p-0',
  margin = 'm-0',
  letterSpacing = 'normal',
  width = 'w-auto',
  wrapText = 'normal',
  customStyles,
  ellipsis,
}: Props) => {
  const Tag = TAG_TYPE_VARIANTS[variant] as TagType;
  const LETTER_SPACING = LETTER_SPACING_VARIENTS[letterSpacing];
  const TEXT_POSITION = POSITION_VARIANTS[position];
  const FONT_WEIGHT = weight === 'normal' ? '' : FONT_WEIGHT_VARIANTS[weight];
  const TEXT_WRAP = TEXT_WRAP_VARIANTS[wrapText];
  return (
    <>
      {variant === 'ellipsis' && (
        <Typography.Paragraph
          className={classNames(
            styles[`type-${variant}`],
            className,
            textCase,
            TEXT_POSITION,
            FONT_WEIGHT,
            color,
            padding,
            margin,
            LETTER_SPACING,
            width,
            TEXT_WRAP,
          )}
          onClick={onClick}
          style={customStyles}
          ellipsis={ellipsis}
        >
          {children}
        </Typography.Paragraph>
      )}
      {variant !== 'ellipsis' && (
        <Tag
          className={classNames(
            styles[`type-${variant}`],
            className,
            textCase,
            TEXT_POSITION,
            FONT_WEIGHT,
            color,
            padding,
            margin,
            LETTER_SPACING,
            width,
            TEXT_WRAP,
          )}
          onClick={onClick}
          style={customStyles}
        >
          {children}
        </Tag>
      )}
    </>
  );
};
export default Text;
