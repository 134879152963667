export const ContactLabels = {
  GET_MORE: 'Get more',
  ENRICH_DETAILS: 'Enrich details',
  CREDIT_USED: 'credit used',
  DATA_ENRICHED: 'Data Enriched',
  HAPPYSALES_HELPS: 'Happysales helps you to',
  ADD_TO_OUTREACH_SEQUENCE: 'Add to Outreach Sequence',
  SEARCH_FOR_A_OUTREACH_SEQUENCE: 'Search for a outreach sequence',
  VIEW_DETAILS: 'View details',
  ASSIGN: 'Assign',
  ASSIGNED: 'Assigned',
  VIEW_STATUS: 'View status',
  EMAIL_PLACEHOLDER: 'No email found',
  ORGANISATION_PLACEHOLDER: 'No organisation found',
  DESIGNATION_PLACEHOLDER: 'No designation found',
  UNABLE_TO_FETCH: 'Unable to fetch data',
  ONBOARDING_COMPLETION: 'Onboarding progress:',
  IMPROVE: 'Improve',
};

export const InsightsLabels = {
  LOADING_INSIGHTS_USER: 'Loading insights for user',
  LOADING_INSIGHTS_ORG: 'Loading insights for company',
  LOADING_NEWS_ORG: 'Loading news for company',
  LOADING_KEY_CHALLENGES: 'Loading key challenges for the role',
  ANALYSE_TALKING_POINTS: 'Analysing pitch',
  INSIGHTS_FOR: 'Insights for',
  KEYCHALLENGES_FOR_ROLE: 'Key challenges for the role',
  TALKING_POINTS_FOR: 'Pitch for',
  LOADING_TECH_STACK: 'Loading tech stack',
  SINCE: 'Since',
  INSIGHTS_EMPTY_PLACEHOLDER: ' No insights found',
  TECH_STACK_EMPTY_PLACEHOLDER: ' No tech stack found',
  LOADING_ICEBREAKERS: 'Loading icebreakers',
  LOADING_EMAILS: 'Loading emails',
  PERSON: 'Person',
  COMPANY: 'Company',
  CHALLENGES: 'Challenges',
  PITCH: 'Pitch',
};

export const ActionsLabels = {
  ICE_BREAKERS: 'Icebreakers',
  WRITE_EMAIL: 'Write an email',
  ADD_CONTEXT: 'Add context',
  WRITE_AN_EMAIL: 'Emails',
  WRITE_A_MESSAGE: 'Write a message',
  EMAIL: 'Email',
  CONTEXT_TEXTAREA_PLACEHOLDER: 'Add your context for the email',
  EMAIL_LENGTH: 'Email Length',
  EMAIL_LENGTH_SHORT: 'Short',
  EMAIL_LENGTH_MEDIUM: 'Medium',
  EMAIL_LENGTH_LARGE: 'Large',
  GENERATE_EMAIL: 'Generate email ',
  GENERATE_EMAILS: 'Generate emails ',
  GENERATE_NEW_EMAIL: 'Generate new email ',
  GENERATE_MESSAGE: 'Generate message ',
  REGENERATE: 'Regenerate',
  ADD_CONTEXT_SELECT: 'Select context or add your own',
  ADD_CONTEXT_DESCRIPTION: 'Select to add more context for the email',
  COPY_MESSAGE: 'Copy message',
  EMAIL_LENGTH_REQUIRED: 'Please provide the email length',
  ADD_CONTEXT_REQUIRED: 'Please provide additional context',
  INSIGHTS_REQUIRED: 'Please provide atleast one insight',
};
