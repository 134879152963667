/* eslint-disable unicorn/better-regex */
import { useContext } from 'react';

import { CommonLabels, AuthLabels } from 'consts/label';
import { Button, Divider, Form, Input, InputPassword } from 'components/Ant';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { Icon } from 'components/Icon';
import PasswordCriteriaComponent from 'components/passwordCriteria';
import { CreateAccountContext } from './createAccount.context';

const SignupView = () => {
  const {
    createAccountform,
    onCreateAccount,
    handleGoogleLogin,
    EmailValid,
    newPassword,
    contextHolder,
  } = useContext(CreateAccountContext);
  return (
    <Flex justify="between" alignItems="center" margin="m-auto" direction="col">
      {contextHolder}
      <Button
        className="font-medium mt-2 flex items-center justify-center"
        block
        size="large"
        onClick={handleGoogleLogin}
      >
        <Text
          variant="psm"
          position="center"
          color="text-textPrimary"
          className=" flex items-center justify-center"
        >
          <Icon name="GoogleLogo" className=" mr-2" />
          Continue with Google
        </Text>
      </Button>
      <Divider className=" border-silver my-6">
        <Text variant="span" color="text-secondary">
          OR
        </Text>
      </Divider>
      <Form
        name="createAccount"
        layout="vertical"
        className="w-full"
        form={createAccountform}
        onFinish={onCreateAccount}
        autoComplete="off"
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              message: AuthLabels.FIRST_NAME_PLACEHOLDER,
              required: true,
            },
            {
              // /^[A-Za-zÀ-ÖØ-öø-ÿー－ 'ぁ-ゟ゠-ヿ一-鿿]+$/u
              pattern: /^(?=\p{L})[\p{L}\p{M}\p{N}\p{P}\p{Z}\p{Sm}'-]+$/u,
              message: AuthLabels.LABEL_ERROR_MESSAGE,
            },
          ]}
        >
          <Input placeholder={AuthLabels.FIRST_NAME_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { message: AuthLabels.LAST_NAME_PLACEHOLDER, required: true },
            {
              pattern: /^(?=\p{L})[\p{L}\p{M}\p{N}\p{P}\p{Z}\p{Sm}'-]+$/u,
              message: AuthLabels.LABEL_ERROR_MESSAGE,
            },
          ]}
        >
          <Input placeholder={AuthLabels.LAST_NAME_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="companyName"
          label={AuthLabels.COMPANY_NAME}
          rules={[
            {
              message: AuthLabels.COMPANY_NAME_PLACEHOLDER,
              required: true,
            },
            {
              pattern: /^(?=.*[A-Za-z])[A-Za-z0-9\s\-,&.'()]+$/u,
              message: AuthLabels.COMPANY_NAME_ERROR_MESSAGE,
            },
          ]}
        >
          <Input placeholder={AuthLabels.COMPANY_NAME_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="companyWebsite"
          label={AuthLabels.COMPANY_WEBSITE}
          rules={[
            {
              message: AuthLabels.COMPANY_WEBSITE_PLACEHOLDER,
              required: true,
            },
            () => ({
              validator(_, value) {
                // eslint-disable-next-line unicorn/better-regex
                const urlPattern =
                  /((https?):\/\/)?(www.)?[\da-z]+(\.[a-z]{2,}){1,3}(#?\/?[\d#A-Za-z]+)*\/?(\?[\w-]+=[\d%A-Za-z-]+&?)?$/;
                if (value && !urlPattern.test(value)) {
                  return Promise.reject(new Error('Please enter a valid URL'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder={AuthLabels.COMPANY_WEBSITE_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          validateFirst
          rules={[
            { message: AuthLabels.EMAIL_PLACEHOLDER, required: true },
            {
              type: 'email',
              message: AuthLabels.EMAIL_VALIDATION_MESSAGE,
            },
            () => ({
              validator(_, value) {
                if (!EmailValid.check(value)?.valid) {
                  return Promise.reject(new Error('Invalid Email address'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          // help={isLoginError ? isLoginErrorMsg.email ?? '' : null}
        >
          <Input placeholder={AuthLabels.EMAIL_PLACEHOLDER} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              message: AuthLabels.PASSWORD_PLACEHOLDER,
              required: true,
            },
            () => ({
              validator(_, value) {
                const stringLength = /^.{8,}$/;
                const containNumber = /^(?=.*\d).*$/;
                const containAlphabet = /[a-zA-Z]/;
                const containSpecialCharacter =
                  /^(?=.*[!"#$%&'()*+,./:;<=>?@[\\\]^_{|}]).*$/;

                if (value && !stringLength.test(value)) {
                  return Promise.reject();
                }
                if (value && !containNumber.test(value)) {
                  return Promise.reject();
                }
                if (value && !containSpecialCharacter.test(value)) {
                  return Promise.reject();
                }
                if (value && !containAlphabet.test(value)) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputPassword placeholder={AuthLabels.PASSWORD_PLACEHOLDER} />
        </Form.Item>
        <Flex margin="mb-5" direction="col">
          <PasswordCriteriaComponent newpassword={newPassword} />
        </Flex>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="w-full font-medium"
          // onClick={onCreateAccount}
        >
          {CommonLabels.CREATE_ACCOUNT}
        </Button>
      </Form>
    </Flex>
  );
};

export default SignupView;
