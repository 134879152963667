import React from 'react';
import { ChildrenProps } from 'types/custom';
import EmailService from './email.service';

export const EmailContext = React.createContext<any>('');

export const EmailProvider = ({ children }: ChildrenProps): JSX.Element => {
  const stateService = EmailService();

  return (
    <EmailContext.Provider value={stateService}>
      {children}
    </EmailContext.Provider>
  );
};
