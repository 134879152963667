import { EmailProvider } from './email.context';
import EmailView from './email.view';

const EmailApp = () => {
  return (
    <EmailProvider>
      <EmailView />
    </EmailProvider>
  );
};

export default EmailApp;
