import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserVerifyInfo } from 'pages/contact/contact.query';
import NavContext from 'context/NavContext';
import { Form } from 'components/Ant';
import {
  useCompanyDetails,
  useCustomerDetail,
  useGetCompanyDetails,
  useEditCompanyDetails,
  useUpdateProfileInfo,
} from './about.query';

const AboutService = () => {
  const [createAccountform] = Form.useForm();
  const [loginform] = Form.useForm();
  const [aboutCompanyform] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('happysales');
  const customerNav = useContext(NavContext);
  const { data: companyDetails, refetch: companyDetailsRefetch } =
    useGetCompanyDetails(localStorage.getItem('userid') || '');
  const { mutate: saveCompanyDetails, isLoading: isCompanyDetailsLoading } =
    useCompanyDetails();
  const { mutate: createAcccount, isLoading: isCreateAccountLoading } =
    useCustomerDetail();
  const {
    data: { data: { data: userInfo = {} } = {} } = {},
    refetch: refetchVerifyInfo,
  } = useGetUserVerifyInfo();
  const {
    mutate: editCompanyDetails,
    isLoading: isEditCComapnyDetailsLoading,
  } = useEditCompanyDetails();
  const { mutate: updateUserProfile, isLoading: isUpdateProfileInfo } =
    useUpdateProfileInfo();

  useEffect(() => {
    if (companyDetails && userInfo) {
      const values = companyDetails?.data?.data;
      const name = userInfo?.name.split(' ');
      aboutCompanyform.setFieldsValue({
        firstName: name?.[0] || '',
        lastName: name?.[1] || '',
        select_lang: values?.language,
        companyName: values?.company_name,
        companyWebsite: values?.company_website,
        about: values?.company_details?.about_company,
        email_template: values?.company_details?.example_email,
        top_customer: values?.customer_details?.top_customers,
        customer_wins: values?.customer_details?.customer_wins,
        top_competitors: values?.customer_details?.top_competitors,
      });
    }
  }, [companyDetails, userInfo]);

  useEffect(() => {
    if (location.pathname === '/settings') {
      companyDetailsRefetch();
    }
  }, [location]);

  useEffect(() => {
    refetchVerifyInfo();
  }, [customerNav]);

  const handleTabChange = useCallback((value: string) => {
    setActiveKey(value);
  }, []);

  const handleCloseExtension = () => {
    window.close();
  };
  const onCreateAccount = () => {
    navigate('/get-started');
  };
  const logout = () => {
    // localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    window.parent.postMessage({ type: 'logout' }, '*');
    if (localStorage.getItem('language')) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  };
  const onCreateAccountStep2 = (values) => {
    const lang = localStorage.getItem('language');
    if (location.pathname === '/create-account') {
      if (
        values.customer_wins ||
        values.top_customer ||
        values.top_competitors
      ) {
        createAcccount(
          {
            customer_wins: values.customer_wins,
            top_customers: values.top_customer,
            top_competitors: values.top_competitors,
          },
          {
            onSuccess: (data: any) => {
              if (data.data.code === 200) {
                aboutCompanyform.resetFields();
                navigate('/contact', { replace: true });
              }
            },
          },
        );
      } else {
        aboutCompanyform.resetFields();
        navigate('/contact', { replace: true });
      }
    } else if (location.pathname === '/settings') {
      editCompanyDetails(
        {
          company_name: values.companyName,
          about_company: values.about,
          example_email: values.email_template,
          company_website: values.companyWebsite,
          language: values.select_lang,
          customer_wins: values.customer_wins,
          top_customers: values.top_customer,
          top_competitors: values.top_competitors,
        },
        {
          onSuccess: (data: any) => {
            if (data.data.code === 200) {
              updateUserProfile(
                {
                  name: `${values.firstName} ${values.lastName}`,
                  id: userInfo.user_id,
                },
                {
                  onSuccess: () => {
                    refetchVerifyInfo();
                    setTimeout(() => {
                      aboutCompanyform.resetFields();
                      navigate('/contact', { replace: true });
                    }, 1000);
                  },
                },
              );
            }
          },
        },
      );
    } else {
      saveCompanyDetails(
        {
          company_name: values.companyName,
          about_company: values.about,
          example_email: values.email_template,
          company_website: values.companyWebsite,
          language: lang,
        },
        {
          onSuccess: (data: any) => {
            if (data.data.code === 200) {
              aboutCompanyform.resetFields();
              navigate('/create-account', { replace: true });
            }
          },
        },
      );
    }
  };

  const onSettingsBack = () => {
    navigate(-1);
  };

  const onCustomerFormCancel = () => {
    navigate('/contact');
  };

  const onCompanyFormCancel = () => {
    localStorage.removeItem('token');
    if (localStorage.getItem('language')) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  };
  return {
    createAccountform,
    handleCloseExtension,
    onCreateAccount,
    onCustomerFormCancel,
    onCompanyFormCancel,
    isCompanyDetailsLoading,
    isCreateAccountLoading,
    isEditCComapnyDetailsLoading:
      isUpdateProfileInfo || isEditCComapnyDetailsLoading,
    loginform,
    aboutCompanyform,
    onSettingsBack,
    onCreateAccountStep2,
    activeKey,
    handleTabChange,
    logout,
    userInfo,
  };
};
export default AboutService;
