import { OutReachProvider } from './outReach.context';
import OutReachView from './outReach.view';

const OutReachApp = () => {
  return (
    <OutReachProvider>
      <OutReachView />
    </OutReachProvider>
  );
};

export default OutReachApp;
