import React, { useContext, useMemo } from 'react';
import { ContactContext } from 'pages/contact/contact.context';
import { InsightsLabels } from 'consts/label';
import Flex from 'components/Flex';
import TabLabel from 'components/TabLabel';

import PersonInsightsView from './person.view';
import DiscInsights from './disc.view';
// import CompanyInsights from './organisation.view';
import PitchView from './pitch.view';
import CompanyNewsInsights from './news.view';

// eslint-disable-next-line import/no-relative-parent-imports

const InsightsView = () => {
  const { activeKey, handleTabChange } = useContext(ContactContext);
  // {
  //   key: 'pitch',
  //   label: (
  //     <TabLabel
  //       icon="PitchIcon"
  //       small
  //       title={InsightsLabels.PITCH}
  //       iconClassName="w-5 h-5"
  //     />
  //   ),
  // },
  //
  const tabList = useMemo(() => {
    return [
      {
        key: 'company',
        label: (
          <TabLabel
            icon="CompanyIcon"
            small
            title={InsightsLabels.COMPANY}
            iconClassName="w-5 h-5"
          />
        ),
      },
      {
        key: 'disc',
        label: (
          <TabLabel
            icon="DiscIcon"
            small
            title={InsightsLabels.DISC}
            iconClassName="w-5 h-5"
          />
        ),
      },
      {
        key: 'person',
        label: (
          <TabLabel
            icon="PersonIcon"
            small
            title={InsightsLabels.PERSON}
            iconClassName="w-5 h-5"
          />
        ),
      },
    ];
  }, []);

  return (
    <Flex direction="col">
      <Flex
        alignItems="center"
        justify="between"
        width="w-full"
        className="sticky top-11 z-[2] bg-white2 insights-container"
        margin="mb-4"
      >
        {tabList.map((tab) => {
          return (
            <Flex
              key={tab.key}
              padding="py-2"
              onClick={() => {
                handleTabChange(tab.key);
              }}
              className={` cursor-pointer font-medium ${
                activeKey === tab.key ? 'text-primary' : ' text-secondary'
              }`}
            >
              {tab.label}
            </Flex>
          );
        })}
      </Flex>

      <Flex gap="gap-5" direction="col" margin="mx-1">
        {/* Insights for user */}
        {activeKey === 'person' && <PersonInsightsView />}
        {/* Insights for DISC */}
        {activeKey === 'disc' && <DiscInsights />}
        {/* Insights for company */}
        {activeKey === 'company' && <CompanyNewsInsights />}
        {/* Key challenges */}
        {activeKey === 'pitch' && <PitchView />}
      </Flex>
    </Flex>
  );
};

export default InsightsView;
